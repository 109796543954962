import { createSlice } from '@reduxjs/toolkit';
// types
import { AmiTrainingState } from 'types/amiTraining';

// ----------------------------------------------------------------------

const initialState: AmiTrainingState = {
  isLoading: false,
  error: { statusCode: 0, message: '' },
  amiTrainingList: [],
  amiTrainingListCertificationPath: [],
  amiTrainingDetail: null,
  registrantAmiTrainingList: [],
  registrantDetailOfTraining: null
};

export const amiTrainingSlice = createSlice({
  name: 'amiTraining',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET ALL AMI TRAINING SUCCESS
    getAllAmiTrainingSuccess(state, action) {
      state.isLoading = false;
      state.amiTrainingList = action.payload;
    },

    getlAmiTrainingCertificationPathSuccess(state, action) {
      state.isLoading = false;
      state.amiTrainingListCertificationPath = action.payload;
    },

    // GET ONE AMI TRAINING SUCCESS
    getAmiTrainingSuccess(state, action) {
      state.isLoading = false;
      state.amiTrainingDetail = action.payload;
    },

    // GET ALL REGISTRANTS AMI TRAINING SUCCESS
    getRegistrantAmiTrainingSuccess(state, action) {
      state.isLoading = false;
      state.registrantAmiTrainingList = action.payload;
    },

    // GET ONE REGISTRANTS AMI TRAINING SUCCESS
    getRegistrantDetailsOfAmiTrainingSuccess(state, action) {
      state.isLoading = false;
      state.registrantDetailOfTraining = action.payload;
    }
  }
});

// Reducer
export default amiTrainingSlice.reducer;
