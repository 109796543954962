import { createSlice } from '@reduxjs/toolkit';
import { LabelingState } from 'types/labeling';
// types

// ----------------------------------------------------------------------

const initialState: LabelingState = {
  isLoading: false,
  error: { statusCode: 0, message: '' },
  labelingApplications: [],
  labelingApplication: null,
  isShownForm: false
};

export const labelingSlice = createSlice({
  name: 'labeling',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET LABELING APPLICATIONS
    getLabelingApplicationsSuccess(state, action) {
      state.isLoading = false;
      state.labelingApplications = action.payload;
    },

    // GET LABELING APPLICATION DETAILS SUCCESS
    getLabelingApplicationDetailsSuccess(state, action) {
      state.isLoading = false;
      state.labelingApplication = action.payload;
    },

    // SHOW LABELING FORM
    showLabelingForm(state) {
      state.isShownForm = !state.isShownForm;
    }
  }
});

// Reducer
export default labelingSlice.reducer;
// Actions
export const { showLabelingForm } = labelingSlice.actions;
