import { createSlice } from '@reduxjs/toolkit';
// utils
import {
  Status,
  Region,
  Sectors,
  Countries,
  LegalStatus,
  StructureType,
  ProposedServices,
  MeetingCategories
} from 'types/metadata';

// ----------------------------------------------------------------------

type MetadataState = {
  isLoading: boolean;
  error: boolean;
  regions: Region[];
  status: Status[];
  sectors: Sectors[];
  countries: Countries[];
  legalStatus: LegalStatus[];
  structureType: StructureType[];
  proposedServices: ProposedServices[];
  meetingCategories: MeetingCategories[];
  currencies: any;
};

const initialState: MetadataState = {
  isLoading: false,
  error: false,
  regions: [],
  status: [],
  sectors: [],
  countries: [],
  legalStatus: [],
  structureType: [],
  proposedServices: [],
  meetingCategories: [],
  currencies: []
};

export const metadataSlice = createSlice({
  name: 'metadata',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET PROFILE
    getRegionSuccess(state, action) {
      state.isLoading = false;
      state.regions = action.payload;
    },

    // GET STATUS
    getSatusSuccess(state, action) {
      state.isLoading = false;
      state.status = action.payload;
    },

    // GET SECTORS
    getSectorsSuccess(state, action) {
      state.isLoading = false;
      state.sectors = action.payload;
    },

    // GET COUNTRIES
    getCountriesSuccess(state, action) {
      state.isLoading = false;
      state.countries = action.payload;
    },

    // GET LEGAL STATUS
    getLegalStatusSuccess(state, action) {
      state.isLoading = false;
      state.legalStatus = action.payload;
    },

    // GET STRUCTURE TYPE
    getStructureTypeSuccess(state, action) {
      state.isLoading = false;
      state.structureType = action.payload;
    },

    // GET PROPOSED SERVICES
    getProposedServicesSuccess(state, action) {
      state.isLoading = false;
      state.proposedServices = action.payload;
    },

    // GET MEETING CATEGORIES
    getMeetingCategoriesSuccess(state, action) {
      state.isLoading = false;
      state.meetingCategories = action.payload;
    },

    // GET CURRENCIES CATEGORIES
    getCurrenciesSuccess(state, action) {
      state.isLoading = false;
      state.currencies = action.payload;
    }
  }
});

// Reducer
export default metadataSlice.reducer;
