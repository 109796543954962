// material
import { alpha } from '@mui/material/styles';
import { APP_COLOR_BY_USER_ROLES, USER_ROLES } from 'common/constants';
import { isExternalSupportOfficer } from 'utils/userRole';

export const DefaultRootStyle = {
  width: '85%',
  marginLeft: '20px',
  borderRadius: '8px',
  display: 'flex',
  justifyContent: 'center'
};

const getActiveSubTitleColor = ({ userRoleName, supportOfficer, profileType }) => {
  return isExternalSupportOfficer({ userRoleName, supportOfficer, profileType })
    ? APP_COLOR_BY_USER_ROLES.SUPPORT_OFFICER_PRIMARY
    : userRoleName === USER_ROLES.CONTRACTOR
    ? APP_COLOR_BY_USER_ROLES.CONTRACTORS_PRIMARY
    : 'secondary.main';
};

const getNavItemBgColor = ({ userRoleName, supportOfficer, profileType, theme }) => {
  return isExternalSupportOfficer({ userRoleName, supportOfficer, profileType })
    ? alpha(APP_COLOR_BY_USER_ROLES.SUPPORT_OFFICER_PRIMARY, theme.palette.action.selectedOpacity)
    : userRoleName === USER_ROLES.CONTRACTOR
    ? alpha(APP_COLOR_BY_USER_ROLES.CONTRACTORS_PRIMARY, theme.palette.action.selectedOpacity)
    : alpha(theme.palette.secondary.main, theme.palette.action.selectedOpacity);
};

export function ActiveSubStyle({ userRoleName, supportOfficer, profileType }) {
  return {
    color: getActiveSubTitleColor({ userRoleName, supportOfficer, profileType }),
    fontWeight: 'fontWeightMedium',
    alignItems: 'center'
  };
}

export const CollapseStyle = ({ theme, userRoleName, supportOfficer, profileType }) => {
  return {
    width: '85%',
    marginLeft: '20px',
    display: 'flex',
    justifyContent: 'center',
    borderBottomLeftRadius: '8px',
    borderBottomRightRadius: '8px',
    bgcolor: getNavItemBgColor({ userRoleName, supportOfficer, profileType, theme })
  };
};

export const activeRootStyle = ({ theme, userRoleName, supportOfficer, profileType }) => {
  return {
    color: getActiveSubTitleColor({ userRoleName, supportOfficer, profileType }),
    fontWeight: 'fontWeightMedium',
    width: '85%',
    marginLeft: '20px',
    borderRadius: '8px',
    display: 'flex',
    justifyContent: 'center',
    bgcolor: getNavItemBgColor({ userRoleName, supportOfficer, profileType, theme }),
    '&:before': {
      display: 'block',
      left: 0,
      transform: `rotate(180deg)`,
      bgcolor: getActiveSubTitleColor({ userRoleName, supportOfficer, profileType }),

      my: 1
    }
  };
};
