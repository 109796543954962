import { createSlice } from '@reduxjs/toolkit';
// types
import { CallForApplicationsState } from 'types/callForApplications';

// ----------------------------------------------------------------------

const initialState: CallForApplicationsState = {
  isLoading: false,
  error: { statusCode: 0, message: '' },
  callForApplications: [],
  callForApplication: null,
  callForApplicationFormData: null,
  uploadFilePercent: null,
  fieldId: null,
  callForApplicationsResponses: [],
  callForApplicationsResponse: null
};

export const callForApplicationsSlice = createSlice({
  name: 'callForApplications',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET ALL CALL FOR APPLICATIONS
    getAllCallForApplicationsSuccess(state, action) {
      state.isLoading = false;
      state.callForApplications = action.payload;
    },

    // GET CALL FOR APPLICATION DETAILS
    getCallForApplicationDetailsSuccess(state, action) {
      state.isLoading = false;
      state.callForApplication = action.payload;
    },

    // GET CALL FOR APPLICATION FORM DATA
    getCallForApplicationFormDataSuccess(state, action) {
      state.isLoading = false;
      state.callForApplicationFormData = action.payload;
    },

    // GET UPLOAD FILE PERCENT
    getUploadFilePercent(state, action) {
      state.isLoading = false;
      state.uploadFilePercent = action.payload;
    },

    // GET FIELD ID
    getFieldId(state, action) {
      state.isLoading = false;
      state.fieldId = action.payload;
    },

    // GET CALL FOR APPLICATIONS RESPONSES
    getCallForApplicationsResponsesSuccess(state, action) {
      state.isLoading = false;
      state.callForApplicationsResponses = action.payload;
    },

    // GET CALL FOR APPLICATIONS RESPONSE
    getCallForApplicationsResponseSuccess(state, action) {
      state.isLoading = false;
      state.callForApplicationsResponse = action.payload;
    }
  }
});

// Reducer
export default callForApplicationsSlice.reducer;

// actions
export const { getUploadFilePercent, getCallForApplicationsResponseSuccess } =
  callForApplicationsSlice.actions;
