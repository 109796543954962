/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
// material
import CloseIcon from '@mui/icons-material/Close';
import { Alert, Box, Container, IconButton, Typography } from '@mui/material';
// components
import { MHidden } from 'components/@material-extend';
import Logo from 'components/Logo';
import { LoginForm } from 'components/authentication/login';
// redux
import { useDispatch, useSelector } from 'state/store';
import { userEmailValidation } from 'state/thunks/user.thunk';
// hooks
import useAuth from 'hooks/useAuth';
import useLocales from 'hooks/useLocales';
// styles
import { IconButtonStyle, RootStyle, SectionStyle } from './Login.style';
// selectors
import toast from 'react-hot-toast';
import { selectUser } from 'state/selectors/user.selectors';
// ----------------------------------------------------------------------

const clientId = process.env.REACT_APP_GOOGLE_AUTH_CLIENT_ID as string;

export default function Login() {
  const dispatch = useDispatch();
  const { loginWithGoogle } = useAuth();
  const { token = '' } = useParams();
  const { translate } = useLocales();
  const divRef = useRef<HTMLDivElement>(null);

  const { user } = useSelector(selectUser);

  async function handleLoginWithGoogle(response) {
    try {
      await loginWithGoogle(response.credential);
    } catch (error: any) {
      toast.error(`${error.message}`);
    }
  }

  useEffect(() => {
    if (token !== '') {
      dispatch(userEmailValidation(token)).unwrap();
    }

    if (typeof window === 'undefined' || !window.google || !divRef.current) {
      return;
    }

    try {
      window.google.accounts.id.initialize({
        client_id: clientId,
        callback: handleLoginWithGoogle
      });
      window.google.accounts.id.renderButton(divRef.current, {
        type: 'standard',
        theme: 'filled_blue',
        size: 'large'
      });
      window.google.accounts.id.prompt();
    } catch (error) {
      // setState({ error });
    }
  }, [dispatch, token]);

  return (
    <RootStyle
      title={`${translate('loginPage.loginToYourAccount')} | ${translate('loginPage.app')}`}
    >
      <Container
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column'
        }}
      >
        <MHidden width="mdDown">
          <Logo />
        </MHidden>

        <SectionStyle>
          <Box width={{ xs: '95%', sm: '80%' }} height="10%">
            <Box position="absolute" right={{ xs: 0, md: '-12px' }} top={{ xs: 0, md: '-20px' }}>
              <IconButton sx={IconButtonStyle}>
                <CloseIcon fontSize="large" />
              </IconButton>
            </Box>
            <Box mb={2} display="flex" alignItems="center" flexDirection="column">
              {user?.isVerified && (
                <Alert severity="success" sx={{ mb: 3, textAlign: 'center' }}>
                  {translate('loginPage.yourEmail')} <strong>{user?.email}</strong>{' '}
                  {translate('loginPage.validated')} {translate('loginPage.loginToContinue')}
                </Alert>
              )}
              <Box flexGrow={1}>
                <Typography variant="h4" gutterBottom>
                  {translate('loginPage.login')}
                </Typography>
              </Box>
              {!token && (
                <>
                  <div ref={divRef} />

                  <Box
                    mt={3}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    width="100%"
                  >
                    <Box height={2} bgcolor="background.grey" width="100%" />
                    <Typography variant="body2" sx={{ color: 'text.secondary', mx: 2 }}>
                      {translate('registerPage.or')}
                    </Typography>
                    <Box height={2} bgcolor="background.grey" width="100%" />
                  </Box>
                </>
              )}
            </Box>
          </Box>

          <Box width={{ xs: '95%', sm: '80%' }}>
            <LoginForm />
          </Box>
        </SectionStyle>
      </Container>
    </RootStyle>
  );
}
