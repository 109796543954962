// @ts-nocheck
import { createContext, ReactNode, useEffect, useReducer, useCallback } from 'react';
// utils
import axios from 'utils/axios';
import { isValidToken, setSession } from 'utils/jwt';
// @types
import { ActionMap, AuthState, AuthUser, JWTContextType } from 'types/authentication';
import { CreateEntity } from 'types/entity';

// ----------------------------------------------------------------------

enum Types {
  Initial = 'INITIALIZE',
  Login = 'LOGIN',
  Logout = 'LOGOUT',
  Register = 'REGISTER'
}

type JWTAuthPayload = {
  [Types.Initial]: {
    isAuthenticated: boolean;
    profileId: number;
    entity: AuthUser;
    role: AuthUser;
    user: AuthUser;
    profileType: AuthUser;
    permissions: AuthUser;
  };
  [Types.Login]: {
    entity: AuthUser;
    profileId: number;
    role: AuthUser;
    user: AuthUser;
    profileType: AuthUser;
    permissions: AuthUser;
  };
  [Types.Logout]: undefined;
  [Types.Register]: {
    entity: AuthUser;
    profileId: number;
    role: AuthUser;
    user: AuthUser;
    profileType: AuthUser;
    permissions: AuthUser;
  };
};

export type JWTActions = ActionMap<JWTAuthPayload>[keyof ActionMap<JWTAuthPayload>];

export const initialState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  entity: null,
  role: null,
  profileId: null,
  user: null,
  profileType: null,
  permissions: null
};

export const JWTReducer = (state: AuthState, action: JWTActions) => {
  switch (action.type) {
    case 'INITIALIZE':
      return {
        isAuthenticated: action.payload.isAuthenticated,
        isInitialized: true,
        entity: action.payload.entity,
        role: action.payload.role,
        profileId: action.payload.profileId,
        user: action.payload.user,
        profileType: action.payload.profileType,
        permissions: action.payload.permissions
      };
    case 'LOGIN':
      return {
        ...state,
        isAuthenticated: true,
        entity: action.payload.entity,
        role: action.payload.role,
        profileId: action.payload.profileId,
        user: action.payload.user,
        profileType: action.payload.profileType,
        permissions: action.payload.permissions
      };
    case 'LOGOUT':
      return {
        ...state,
        isAuthenticated: false,
        user: null
      };

    case 'REGISTER':
      return {
        ...state,
        isAuthenticated: true,
        entity: action.payload.entity,
        role: action.payload.role,
        profileId: action.payload.profileId,
        user: action.payload.user,
        profileType: action.payload.profileType,
        permissions: action.payload.permissions
      };

    default:
      return state;
  }
};

const AuthContext = createContext<JWTContextType | null>(null);

function AuthProvider({ children }: { children: ReactNode }) {
  const [state, dispatch] = useReducer(JWTReducer, initialState);

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = window.localStorage.getItem('accessToken');

        if (accessToken && isValidToken(accessToken)) {
          setSession(accessToken);
          const userData = JSON.parse(localStorage.getItem('user') || '{}');

          dispatch({
            type: Types.Initial,
            payload: {
              isAuthenticated: true,
              entity: userData[0].entity,
              role: userData[0].role,
              profileId: userData[0].id,
              user: userData[0].user,
              permissions: userData[0].permissions?.[0]?.abilities?.permission,
              profileType: userData[0]?.profile_data?.type
            }
          });
        } else {
          dispatch({
            type: Types.Initial,
            payload: {
              isAuthenticated: false,
              entity: null,
              role: null,
              user: null
            }
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: Types.Initial,
          payload: {
            isAuthenticated: false,
            entity: null,
            role: null,
            user: null
          }
        });
      }
    };

    initialize();
  }, []);

  const login = async (email: string, password: string) => {
    const response = await axios.post('login', {
      email,
      password
    });
    const { accessToken, profile } = response.data;

    setSession(accessToken);
    localStorage.setItem('user', JSON.stringify(profile));

    dispatch({
      type: Types.Login,
      payload: {
        entity: profile[0].entity,
        role: profile[0].role,
        profileId: profile[0].id,
        user: profile[0].user,
        permissions: profile[0].permissions?.[0]?.abilities?.permission,
        profileType: profile[0]?.profile_data?.type
      }
    });
  };

  const loginWithGoogle = async (token: string) => {
    const response = await axios.post('login/google', {
      token
    });
    const { accessToken, profile } = response.data;

    setSession(accessToken);
    localStorage.setItem('user', JSON.stringify(profile));

    dispatch({
      type: Types.Login,
      payload: {
        entity: profile[0].entity,
        role: profile[0].role,
        profileId: profile[0].id,
        user: profile[0].user,
        permissions: profile[0].permissions?.[0]?.abilities?.permission,
        profileType: profile[0]?.profile_data?.type
      }
    });
  };

  const registerWithGoogle = async (data: CreateEntity) => {
    const response = await axios.post(`entities/saei`, data);
    const { accessToken, profile } = response.data;

    setSession(accessToken);
    window.localStorage.setItem('user', JSON.stringify(profile));

    dispatch({
      type: Types.Register,
      payload: {
        entity: profile[0].entity,
        role: profile[0].role,
        profileId: profile[0].id,
        user: profile[0].user,
        permissions: profile[0].permissions?.[0]?.abilities?.permission,
        profileType: profile[0]?.profile_data?.type
      }
    });
  };

  const googleLogout = useCallback(() => {
    if (window.gapi) {
      const auth2 = window.gapi.auth2.getAuthInstance();
      if (auth2 != null) {
        auth2.then(
          () => {
            auth2.signOut().then(() => {
              auth2.disconnect();
            });
          },
          (err) => {}
        );
      }
    }
  }, []);

  const logout = async () => {
    googleLogout();
    setSession(null);
    dispatch({ type: Types.Logout });
  };

  const resetPassword = (email: string) => console.log(email);

  const updateProfile = () => {};

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        login,
        logout,
        registerWithGoogle,
        loginWithGoogle,
        resetPassword,
        updateProfile
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
