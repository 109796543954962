import { createSlice } from '@reduxjs/toolkit';
import { ResourceCenterState } from 'types/resourceCenter';

const initialState: ResourceCenterState = {
  isLoading: false,
  error: false,
  resourceCenterList: [],
  files: [],
  resourceCenter: null,
  uploadFilePercent: null,
  voucher: null
};

export const resourceCenterSlice = createSlice({
  name: 'ResourcesCenter',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    //   HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET RESOURCES CENTER SUCCESS
    getResourcesCenterSuccess(state, action) {
      state.isLoading = false;
      state.resourceCenterList = action.payload;
    },

    // GET FILES UPLOAD
    getFileUploadSuccess(state, action) {
      state.isLoading = false;
      state.files = action.payload;
    },

    // GET RESOURCE CENTER SUCCESS
    getResourceCenterSuccess(state, action) {
      state.isLoading = false;
      state.resourceCenter = action.payload;
    },

    // UPLOAD FILE PERCENT
    uploadFilePercent(state, action) {
      state.isLoading = false;
      state.uploadFilePercent = action.payload;
    },

    // UPLOAD VOUCHER
    uploadVoucherSuccess(state, action) {
      state.isLoading = false;
      state.voucher = action.payload;
    }
  }
});

// Reducer
export default resourceCenterSlice.reducer;
