import { createSlice } from '@reduxjs/toolkit';
// utils
import { SaeisState } from 'types/saeis';

// ----------------------------------------------------------------------

const initialState: SaeisState = {
  isLoading: false,
  error: false,
  saeis: [],
  meetingReports: [],
  meetingReport: null
};

export const saeisSlice = createSlice({
  name: 'saeis',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET SAEIS
    getSaeisSuccess(state, action) {
      state.isLoading = false;
      state.saeis = action.payload;
    },

    // GET MEETING REPORTS
    getMeetingReportsSuccess(state, action) {
      state.isLoading = false;
      state.meetingReports = action.payload;
    },

    // GET MEETING REPORT DETAILS
    getMeetingReportDetailsSuccess(state, action) {
      state.isLoading = false;
      state.meetingReport = action.payload;
    },

    // RESET MEETING REPORT DETAILS STATE
    resetMeetingDetailsState(state) {
      state.meetingReport = null;
    }
  }
});

// Reducer
export default saeisSlice.reducer;

// Actions
export const { resetMeetingDetailsState } = saeisSlice.actions;
