import { createSlice } from '@reduxjs/toolkit';

const initialState: any = {
  isLoading: false,
  error: false,
  invoiceAndPayment: [],
  invoiceAndPaymentDetail: null
};

export const invoiceAndPaymentSlice = createSlice({
  name: 'invoiceAndPayment',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    //HAS ERROR
    hasError(state, action) {
      state.error = false;
      state.error = action.payload;
    },

    // GET INVOICE AND PAYMENT
    getInvoiceAndPaymentTrainingSuccess(state, action) {
      state.isLoading = false;
      state.invoiceAndPayment = action.payload;
    },

    // GET INVOICE AND PAYMENT
    getOneInvoiceAndPaymentTrainingSuccess(state, action) {
      state.isLoading = false;
      state.invoiceAndPaymentDetail = action.payload;
    }
  }
});

// Reducer
export default invoiceAndPaymentSlice.reducer;
