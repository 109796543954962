export const DRAWER_WIDTH = 270;
export const COLLAPSE_WIDTH = 102;
export const ADMIN_DRAWER_WIDTH = 270;
export const APP_BAR_MOBILE_HEIGHT = 64;
export const APP_BAR_DESKTOP_HEIGHT = 8;
export const SAEI_APP_BAR_DESKTOP_HEIGHT = 70;
export const USER_TYPES = {
  ADMIN: 'Africinnov'
};
export const ADMIN_SAEI = 'ADMIN';
export const EXPERT_SAEI = 'expert SAEI';
export const ENTREPRENEUR = 'entrepreneur';
