import { createSlice } from '@reduxjs/toolkit';

// ----------------------------------------------------------------------

type GoogleAuthState = {
  userGoogleData: {
    profileObj: Record<string, any>;
  };
  hasError: {
    details: string;
    error: string;
  };
};

const initialState: GoogleAuthState = {
  userGoogleData: { profileObj: {} },
  hasError: { details: '', error: '' }
};

const googleAuthSlice = createSlice({
  name: 'google',
  initialState,
  reducers: {
    // GET GOOGLE USER DATA SUCCESS
    onSuccess(state, action) {
      const userData = action.payload;
      state.userGoogleData = userData;
    },

    // GET GOOGLE USER DATA FAIL
    onFailure(state, action) {
      const failureData = action.payload;
      state.hasError = failureData;
    }
  }
});

// Reducer
export default googleAuthSlice.reducer;

// Actions
export const { onSuccess, onFailure } = googleAuthSlice.actions;
