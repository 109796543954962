import * as Yup from 'yup';
import { useState } from 'react';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import { Link as RouterLink } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
// material
import {
  Link,
  Stack,
  Alert,
  TextField,
  Typography,
  IconButton,
  InputAdornment
} from '@mui/material';
import { Box } from '@mui/system';
import { LoadingButton } from '@mui/lab';
// routes
import { PATH_AUTH } from 'routes/paths';
// hooks
import useAuth from 'hooks/useAuth';
import useLocales from 'hooks/useLocales';
import useIsMountedRef from 'hooks/useIsMountedRef';
// styles
import { StyledInputLabel } from 'styles/Label.style';
import { PASSWORD_VALIDATION } from 'common/constants';

// ----------------------------------------------------------------------

type InitialValues = {
  email: string;
  password: string;
  afterSubmit?: string;
};

export default function LoginForm() {
  const { login } = useAuth();
  const { translate } = useLocales();
  const isMountedRef = useIsMountedRef();
  const [showPassword, setShowPassword] = useState(false);

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email(`${translate('loginPage.emailValid')}`)
      .required(`${translate('loginPage.emailRequired')}`),
    password: Yup.string()
      .required(`${translate('registerPage.enterPassword')}`)
      .matches(PASSWORD_VALIDATION, `${translate('registerPage.emailValidation')}`)
  });

  const formik = useFormik<InitialValues>({
    initialValues: {
      email: '',
      password: ''
    },
    validationSchema: LoginSchema,
    onSubmit: async (values, { setErrors, setSubmitting }) => {
      try {
        await login(values.email, values.password);
        if (isMountedRef.current) {
          setSubmitting(false);
        }
      } catch (error: any) {
        if (isMountedRef.current) {
          setSubmitting(false);
          setErrors({ afterSubmit: error.message });
        }
      }
    }
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3} direction="column" justifyContent="center" alignItems="center">
          {errors.afterSubmit && <Alert severity="error">{errors.afterSubmit}</Alert>}

          <Box width="100%" alignItems="center">
            <Box display="flex" pb={1}>
              <StyledInputLabel htmlFor="email">{translate('loginPage.email')}</StyledInputLabel>
              <Box component="span" color="secondary.main">
                *
              </Box>
            </Box>
            <TextField
              fullWidth
              id="email"
              autoComplete="username"
              type="email"
              placeholder={translate('loginPage.email')}
              {...getFieldProps('email')}
              error={Boolean(touched.email && errors.email)}
              helperText={touched.email && errors.email}
            />
          </Box>

          <Box width="100%">
            <Box display="flex" justifyContent="space-between" pb={1}>
              <Box display="flex">
                <StyledInputLabel htmlFor="password">
                  {translate('loginPage.password')}
                </StyledInputLabel>
                <Box component="span" color="secondary.main">
                  *
                </Box>
              </Box>
              <Box>
                <Link component={RouterLink} variant="body1" to={PATH_AUTH.resetPassword}>
                  {translate('loginPage.passwordForgot')}
                </Link>
              </Box>
            </Box>

            <TextField
              fullWidth
              id="password"
              autoComplete="current-password"
              type={showPassword ? 'text' : 'password'}
              placeholder={translate('loginPage.password')}
              {...getFieldProps('password')}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleShowPassword} edge="end">
                      <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                    </IconButton>
                  </InputAdornment>
                )
              }}
              error={Boolean(touched.password && errors.password)}
              helperText={touched.password && errors.password}
            />
          </Box>
        </Stack>

        <Stack spacing={2} direction="column" justifyContent="center" alignItems="center" pt={3}>
          <Box display="flex" justifyContent="center" alignItems="center">
            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={isSubmitting}
              sx={{ width: { sm: '300px' }, textTransform: 'none' }}
            >
              {translate('loginPage.login')}
            </LoadingButton>
          </Box>

          <Box mb={5} display="flex" alignItems="center" flexDirection="column">
            <Typography color="text.secondary" textAlign="center" gutterBottom>
              {translate('loginPage.dontHaveAccount')}&nbsp;
              <Link
                component={RouterLink}
                color="primary.main"
                variant="body1"
                to={`${PATH_AUTH.register}/new-account`}
              >
                {translate('loginPage.createAccount')}
              </Link>
            </Typography>
          </Box>
        </Stack>
      </Form>
    </FormikProvider>
  );
}
