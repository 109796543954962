import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
// components
import LoadingScreen from '../components/LoadingScreen';

// ----------------------------------------------------------------------

const Loadable = (Component: React.ElementType) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  const isDashboard = pathname.includes('/dashboard');

  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            position: 'fixed',
            ...(!isDashboard && {
              top: 0,
              left: 0,
              zIndex: 9999,
              position: 'fixed'
            })
          }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: '/',
      element: (
        <GuestGuard>
          <Login />
        </GuestGuard>
      )
    },
    {
      path: 'event-registration/:eventId',
      element: <EventRegistration />
    },
    {
      path: 'registration-for-a-call-for-applications/:callForApplicationsId',
      element: <CallForApplicationForm />
    },
    {
      path: 'ami-label-application-form/:amiLabelId',
      element: <AmiLabelForm />
    },
    {
      path: 'auto-diagnostic-label-application',
      element: <AutoDiagnosticLabelForm />
    },
    {
      path: 'ami-training-application-form/:amiTrainingId',
      element: <AmiTrainingForm />
    },
    {
      path: 'event-satisfaction/:eventId/:attendeeEmail',
      element: <EventSatisfaction />
    },
    {
      path: 'event-registration/confirmation',
      element: <RegistrationConfirm />
    },
    {
      path: 'event-satisfaction/confirmation',
      element: <RegistrationConfirm />
    },
    {
      path: 'call-for-applications/confirmation',
      element: <RegistrationConfirm />
    },
    {
      path: 'ami-label-application-success',
      element: <RegistrationConfirm />
    },
    {
      path: 'ami-label-application-error',
      element: <RegistrationConfirm />
    },
    {
      path: 'ami-training-application-success',
      element: <RegistrationConfirmAmiTraining />
    },
    {
      path: 'auto-diagnostic-label-application/confirmation',
      element: <RegistrationConfirmAutoDiagnostic />
    },
    {
      path: 'auto-diagnostic-label-response/:responseId',
      element: <AutoDiagnosticResponseDetails />
    },
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          )
        },
        {
          path: 'validation-email/:token',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          )
        },
        {
          path: 'account-type',
          element: (
            <GuestGuard>
              <AccountType />
            </GuestGuard>
          )
        },
        {
          path: 'register/:accountType',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          )
        },
        {
          path: 'register/confirmation',
          element: <RegistrationConfirm />
        },
        {
          path: 'reset-password/confirm',
          element: <ResetPasswordConfirm />
        },
        { path: 'reset-password', element: <ResetPassword /> }
      ]
    },

    // Dashboard Routes
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { path: '', element: <Navigate to="/dashboard/index" replace /> },
        { path: 'index', element: <Dashboard /> },
        {
          path: 'registration-completed',
          element: <RegistrationCompleted />
        },
        {
          path: 'user-profile',
          element: <UserProfile />
        },
        {
          path: 'user-profile/edit',
          element: <EditUserProfile />
        },

        {
          path: 'renewals-label-form',
          element: <RenewalsLabelForm />
        },
        {
          path: 'saei',
          children: [
            { element: <Navigate to="/dashboard/saei/requests/list" replace /> },
            { path: 'requests/list', element: <MembershipRequest /> },
            { path: ':id/request-details', element: <MembershipRequestDetails /> },
            { path: 'association-members', element: <Saeis /> },
            { path: 'association-members/register-saei', element: <CreateSaei /> },
            { path: 'association-members/:id/details', element: <SaeiDetails /> },
            { path: 'association-members/manage-meetings', element: <ManageMeetings /> },
            { path: 'association-members/schedule-meeting', element: <ScheduleMeeting /> },
            { path: 'association-members/schedule-meeting/:id/edit', element: <ScheduleMeeting /> },
            {
              path: 'association-members/schedule-meeting/:saeiId/new',
              element: <ScheduleMeeting />
            },
            { path: 'association-members/meeting/:id/details', element: <MeetingDetails /> },
            { path: 'association-members/meetings-reports', element: <MeetingsReports /> },
            { path: 'association-members/save-report', element: <SaveReport /> },
            { path: 'association-members/save-report/:id/edit', element: <SaveReport /> },
            { path: 'association-members/save-report/:saeiId/new', element: <SaveReport /> },
            { path: 'association-members/save-report/:meetingId/meeting', element: <SaveReport /> }
          ]
        },
        {
          path: 'administration',
          children: [
            { element: <Navigate to="/dashboard/administration/profile" replace /> },
            { path: 'profile', element: <SaeiProfile /> },
            { path: 'profile/edit', element: <EditSaeiProfile /> },
            {
              path: 'profile/invoice-and-payment/:invoiceId/make-payment',
              element: <MakePaymentSaeiProfil />
            },
            {
              path: 'profile/invoice-and-payment/:invoiceId/details/:invoiceStatus',
              element: <DetailTeamMemberInvoice />
            },
            { path: 'team', element: <Team /> },
            { path: 'collaborator/new', element: <CreateCollaborator /> },
            { path: 'collaborator/:id/details', element: <CollaboratorDetails /> },
            { path: 'role-and-permission', element: <RoleList /> },
            { path: 'create-role', element: <CreateRole /> },
            { path: 'role-and-permission/:id/details', element: <RoleDetails /> },
            { path: 'meeting-management', element: <UserMeetingManagement /> },
            { path: 'association-ai', element: <AssociationAi /> }
          ]
        },
        {
          path: 'finance',
          children: [
            {
              element: <Navigate to="/dashboard/finance/invoice-and-payment-association" replace />
            },
            { path: 'invoice-and-payment-association', element: <AssociationInvoiceList /> },
            {
              path: 'invoice-and-payment-association/:id/details',
              element: <AssociationInvoiceDetails />
            },
            {
              path: 'invoice-and-payment-association/:id/payment',
              element: <MakePaymentAssociationInvoice />
            },
            {
              path: 'invoice-and-payment-saei',
              element: <SaeiInvoicesList />
            },
            {
              path: 'invoice-and-payment-saei/:id/details',
              element: <SaeiInvoicesListDetails />
            },
            {
              path: 'invoice-and-payment-saei/:id/payment',
              element: <MakePaymentSaei />
            },
            {
              path: 'invoice-and-payment-entrepreneur',
              element: <EntrepreneurInvoices />
            },
            {
              path: 'invoice-and-payment-entrepreneur/create',
              element: <CreateInvoiceAndPayment />
            },
            {
              path: 'invoice-and-payment-entrepreneur/:invoiceId/edite',
              element: <CreateInvoiceAndPayment />
            },
            {
              path: 'invoice-and-payment-entrepreneur/:invoiceId/details/:invoiceStatus',
              element: <DetailEntrepreneurInvoices />
            },
            {
              path: 'invoice-and-payment-entrepreneur/:invoiceId/register-payment',
              element: <RegisterPayment />
            },
            {
              path: 'invoice-and-payment-training',
              element: <InvoiceAndPaymentTrainingList />
            },
            {
              path: 'invoice-and-payment-training/payment-request',
              element: <InvoiceAndPaymentTrainingPaymentRequest />
            },
            {
              path: 'invoice-and-payment-training/:invoiceId/register-payment',
              element: <InvoiceAndPaymentTrainingRegisterPayment />
            },
            {
              path: 'invoice-and-payment-training/:invoiceId/details/:invoiceStatus',
              element: <DetailInvoiceAndPaymentTraining />
            }
          ]
        },
        { path: 'post/list', element: <PostList /> },
        { path: 'post/list/:id/details', element: <PostListDetails /> },
        { path: 'post/list/create-post', element: <CreateSaeiPost /> },
        { path: 'post/list/:id/edit', element: <CreateSaeiPost /> },
        {
          path: 'event',
          element: <EventsList />
        },
        {
          path: 'event/new',
          element: <CreateEvent />
        },
        {
          path: 'event/:id/edit',
          element: <CreateEvent />
        },
        {
          path: 'event/:id/details',
          element: <EventDetails />
        },
        {
          path: 'statistic',
          element: <StatisticAi />
        },
        {
          path: 'program',
          children: [
            {
              element: <Navigate to="/dashboard/program/list" replace />
            },
            { path: 'list', element: <ProgramsList /> },
            {
              path: ':id/details',
              element: <ProgramDetails />
            },
            {
              path: 'create-program',
              element: <CreateProgram />
            },
            {
              path: ':id/edit',
              element: <CreateProgram />
            },
            {
              path: 'call-for-applications',
              element: <CallForApplication />
            },
            {
              path: 'call-for-applications/new',
              element: <CreateCallForApplications />
            },
            {
              path: 'call-for-applications/:id/edit',
              element: <CreateCallForApplications />
            },
            {
              path: 'call-for-applications/:id/details',
              element: <CallForApplicationsDetails />
            },
            {
              path: 'call-for-applications/response/:id/scoring',
              element: <ScoringCallForApplication />
            },
            {
              path: 'call-for-applications/response/:id/details',
              element: <CallForApplicationsResponseDetails />
            },
            {
              path: 'our-contractors',
              element: <OurContractors />
            },
            {
              path: 'our-contractors/new',
              element: <CreateContractor />
            },
            {
              path: 'our-contractors/:id/edit',
              element: <CreateContractor />
            },
            {
              path: 'our-contractors/:id/details',
              element: <ContractorDetails />
            },
            {
              path: 'teaching-tools-and-resources',
              element: <FolderList />
            },
            {
              path: 'folder/:id/details',
              element: <FolderDetails />
            },
            {
              path: 'our-contractors/:contractorId/new-project',
              element: <AddProject />
            },
            {
              path: 'projects',
              element: <Projects />
            },
            {
              path: 'projects/:id/details',
              element: <ProjectDetails />
            },
            {
              path: 'projects/:id/roadmap/new',
              element: <AddRoadmap />
            },
            {
              path: 'projects/:id/roadmap/edit',
              element: <AddRoadmap />
            },
            {
              path: 'projects/:projectId/schedule-meeting',
              element: <ScheduleProjectMeeting />
            },
            {
              path: 'projects/:projectId/meeting/:meetingId/edit',
              element: <ScheduleProjectMeeting />
            },
            {
              path: 'projects/:projectId/meeting/:meetingId/details',
              element: <MeetingDetails />
            },
            {
              path: 'projects/:projectId/new-meeting-report',
              element: <AddProjectMeetingReport />
            },
            {
              path: 'projects/:projectId/meeting-report/:projectMeetingReportId/edit',
              element: <AddProjectMeetingReport />
            },
            {
              path: 'support-officers',
              element: <SupportOfficersList />
            },
            {
              path: 'support-officers/new',
              element: <CreateSupportOfficers />
            },
            {
              path: 'support-officers/new/:id/edit',
              element: <CreateSupportOfficers />
            },
            {
              path: 'support-officers/:id/details',
              element: <SupportOfficerDetails />
            },
            {
              path: `invoice-and-payment`,
              element: <InvoiceAndPaymentProgram />
            },
            {
              path: `invoice-and-payment/:invoiceId/details/:invoiceStatus`,
              element: <InvoiceAndPaymentProgramDetails />
            }
          ]
        },
        {
          path: 'training',
          children: [
            {
              path: 'resource',
              element: <ResourceCenterList />
            },
            {
              path: 'resource/:id/details',
              element: <ResourceCenterDetails />
            },
            {
              path: 'online-training',
              element: <OnlineTraining />
            },
            {
              path: 'online-training/news',
              element: <AddOnlineTraining />
            },
            {
              path: 'online-training/:id/edit',
              element: <AddOnlineTraining />
            },
            {
              path: 'online-training/:id/details',
              element: <OnlineTrainingDetail />
            },

            {
              path: 'online-training/:id/module',
              element: <CreateCourseModule />
            },
            {
              path: 'online-training/:id/module/:moduleId/edit',
              element: <CreateCourseModule />
            },
            {
              path: 'online-training/:id/module/:moduleId/sub-module',
              element: <OnlineTrainingSubModule />
            },
            {
              path: 'online-training/:id/module/:moduleId/sub-module/:subModuleId/edit/:stepId',
              element: <OnlineTrainingSubModule />
            },
            {
              path: 'online-course',
              element: <OnlineTrainingListSaei />
            },
            {
              path: 'online-course/:id/details',
              element: <OnlineTrainingDetails />
            },
            {
              path: 'online-course/:id/details/:moduleId/sub-module/:subModuleId',
              element: <OnlineTrainingSubModuleDetails />
            },
            {
              path: 'online-course/:id/details/:moduleId/sub-module/:subModuleId/quizz',
              element: <OnlineTrainingSubModuleDetails />
            },
            {
              path: 'ami-training',
              element: <AmiTraining />
            },
            {
              path: 'ami-training/new',
              element: <CreateAmiTraining />
            },
            {
              path: 'ami-training/:id/edit',
              element: <CreateAmiTraining />
            },
            {
              path: 'ami-training/:id/details',
              element: <AmiTrainingDetails />
            },
            {
              path: 'ami-training/:id/details/registrant/:registrantId/:status',
              element: <AmiTrainingRegistrantDetails />
            },
            {
              path: 'certification-path',
              element: <CertificationsPath />
            },
            {
              path: 'certification-path/create',
              element: <CreateCertificationPath />
            },
            {
              path: 'certification-path/:id/detail',
              element: <DetailsCertificationPath />
            },
            {
              path: 'certification-path/:id/edit',
              element: <CreateCertificationPath />
            },
            {
              path: 'certification-path/:idCertification/create-section',
              element: <CreateSectionsByCertificationPath />
            },
            {
              path: 'certification-path/:idCertification/section/:sectionId/edit',
              element: <CreateSectionsByCertificationPath />
            },
            {
              path: 'certification-path-saei',
              element: <SaeiCertificationPath />
            },
            {
              path: 'certification-path-saei/:id/detail',
              element: <SaeiCertificationPathDetail />
            }
          ]
        },
        {
          path: 'labelling',
          children: [
            {
              path: 'ami-label',
              element: <AmiLabel />
            },
            {
              path: 'ami-label/new',
              element: <CreateAmiLabel />
            },
            {
              path: 'ami-label/:id/edit',
              element: <CreateAmiLabel />
            },
            {
              path: 'ami-label/:amiLabelId/details',
              element: <AmiLabelDetails />
            },
            {
              path: 'ami-label/:amiLabelId/registrant/:amiLabelRegistrantId/details',
              element: <AmiLabelRegistrantDetails />
            },
            {
              path: 'requests',
              element: <LabelingRequests />
            },
            {
              path: 'requests/:id/details',
              element: <LabelingRequestDetails />
            },
            {
              path: 'requests/confirmation',
              element: <LabelingFormSuccessSubmission />
            },
            {
              path: 'self-diagnosis-label',
              element: <SelfDiagnosisLabel />
            },
            {
              path: 'edit-self-diagnosis-label',
              element: <EditSelfDiagnosisLabel />
            },
            {
              path: 'self-diagnosis-label/:responseId/details',
              element: <AutoDiagnosticLabelDetails />
            },
            {
              path: 'management-of-labels',
              element: <ManagementOfLabels />
            },
            {
              path: 'management-of-labels-renewals',
              element: <ManagementOfLabelRenewals />
            },
            {
              path: 'management-of-labels/:id/details',
              element: <ManagementOfLabelsDetails />
            },
            {
              path: 'management-of-labels-renewals/:id/details',
              element: <ManagementOfLabelRenewalsDetails />
            }
          ]
        },
        {
          path: 'labelling-saei',
          children: [
            {
              path: 'request-for-labeling',
              element: <Labeling />
            },
            {
              path: 'renewal-label',
              element: <RenewalsLabel />
            }
          ]
        },
        {
          path: 'statistics',
          element: <SaeiStatistics />
        },
        {
          path: 'settings-and-preferences',
          element: <SettingsAndPreferences />
        }
      ]
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> }
      ]
    },
    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}

// IMPORT COMPONENTS

// Authentication
const Login = Loadable(lazy(() => import('../pages/authentication/Login')));
const Register = Loadable(lazy(() => import('../pages/authentication/register/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/authentication/ResetPassword')));
const AccountType = Loadable(lazy(() => import('../pages/authentication/register/AccountType')));
const RegistrationConfirm = Loadable(
  lazy(() => import('../pages/authentication/register/RegistrationConfirm'))
);
const RegistrationConfirmAmiTraining = Loadable(
  lazy(() => import('../pages/authentication/register/RegistrationConfirmAmiTraining'))
);
const ResetPasswordConfirm = Loadable(
  lazy(() => import('../pages/authentication/ResetPasswordConfirm'))
);

// Dashboard
const Dashboard = Loadable(lazy(() => import('../pages/dashboard/Dashboard')));

// membership request
const MembershipRequest = Loadable(
  lazy(() => import('../pages/dashboard/admin/saei/membership-request/MembershipRequest'))
);
const RegistrationCompleted = Loadable(
  lazy(() => import('../pages/dashboard/RegistrationCompleted'))
);
const MembershipRequestDetails = Loadable(
  lazy(() => import('../pages/dashboard/admin/saei/membership-request/MembershipRequestDetails'))
);

// saeis
const Saeis = Loadable(lazy(() => import('../pages/dashboard/admin/saei/saeis/Saeis')));
const CreateSaei = Loadable(lazy(() => import('../pages/dashboard/admin/saei/saeis/CreateSaei')));
const SaeiDetails = Loadable(lazy(() => import('../pages/dashboard/admin/saei/saeis/SaeiDetails')));

// meeting
const ManageMeetings = Loadable(
  lazy(() => import('../pages/dashboard/admin/saei/saeis/ManageMeetings'))
);
const ScheduleMeeting = Loadable(
  lazy(() => import('../pages/dashboard/admin/saei/saeis/ScheduleMeeting'))
);
const MeetingDetails = Loadable(
  lazy(() => import('../pages/dashboard/admin/saei/saeis/MeetingDetails'))
);

// report
const MeetingsReports = Loadable(
  lazy(() => import('../pages/dashboard/admin/saei/saeis/MeetingsReports'))
);
const SaveReport = Loadable(lazy(() => import('../pages/dashboard/admin/saei/saeis/SaveReport')));

// saei profil
const SaeiProfile = Loadable(lazy(() => import('../pages/dashboard/saei/administration/Profile')));
const EditSaeiProfile = Loadable(
  lazy(() => import('../pages/dashboard/saei/administration/EditSaeiProfile'))
);

const MakePaymentSaeiProfil = Loadable(
  lazy(() => import('../pages/dashboard/saei/administration/MakePaymentSaeiProfil'))
);

const DetailTeamMemberInvoice = Loadable(
  lazy(() => import('../pages/dashboard/saei/administration/DetailTeamMemberInvoice'))
);

// user profiles
const UserProfile = Loadable(lazy(() => import('../pages/dashboard/saei/UserProfile')));
const EditUserProfile = Loadable(lazy(() => import('../pages/dashboard/saei/EditUserProfile')));

// team
const Team = Loadable(lazy(() => import('../pages/dashboard/team/Team')));
const CreateCollaborator = Loadable(
  lazy(() => import('../pages/dashboard/team/CreateCollaborator'))
);
const CollaboratorDetails = Loadable(
  lazy(() => import('../pages/dashboard/team/CollaboratorDetails'))
);

// association invoice
const AssociationInvoiceList = Loadable(
  lazy(() => import('../pages/dashboard/admin/finance/AssociationInvoice/AssociationInvoiceList'))
);
const AssociationInvoiceDetails = Loadable(
  lazy(
    () => import('../pages/dashboard/admin/finance/AssociationInvoice/AssociationInvoiceDetails')
  )
);
const MakePaymentAssociationInvoice = Loadable(
  lazy(
    () =>
      import('../pages/dashboard/admin/finance/AssociationInvoice/MakePaymentAssociationInvoice')
  )
);
const SaeiInvoicesList = Loadable(
  lazy(() => import('../pages/dashboard/saei/finance/SaeiInvoicesAndPayments/SaeiInvoicesList'))
);

const EntrepreneurInvoices = Loadable(
  lazy(
    () =>
      import('../pages/dashboard/saei/finance/entrepreneurInvoiceAndPayments/EntrepreneurInvoices')
  )
);

const DetailEntrepreneurInvoices = Loadable(
  lazy(
    () =>
      import(
        '../pages/dashboard/saei/finance/entrepreneurInvoiceAndPayments/DetailEntrepreneurInvoices'
      )
  )
);

const SaeiInvoicesListDetails = Loadable(
  lazy(() => import('../pages/dashboard/saei/finance/SaeiInvoicesAndPayments/SaeiInvoicesDetail'))
);
const MakePaymentSaei = Loadable(
  lazy(() => import('../pages/dashboard/saei/finance/SaeiInvoicesAndPayments/MakePaymentSaei'))
);

const PostList = Loadable(lazy(() => import('../pages/dashboard/saei/post/SaeiPost')));
const PostListDetails = Loadable(
  lazy(() => import('../pages/dashboard/saei/post/SaeiDetailsPost'))
);
const CreateSaeiPost = Loadable(lazy(() => import('../pages/dashboard/saei/post/CreateSaeiPost')));
// role
const RoleList = Loadable(
  lazy(() => import('../pages/dashboard/role-and-permission/RoleAndPermission'))
);
const CreateRole = Loadable(
  lazy(() => import('../pages/dashboard/role-and-permission/CreateRole'))
);
const RoleDetails = Loadable(
  lazy(() => import('../pages/dashboard/role-and-permission/RoleDetails'))
);
const ProgramsList = Loadable(
  lazy(() => import('../pages/dashboard/saei/accompanimentProgram/our-programs/ProgramsList'))
);
const ProgramDetails = Loadable(
  lazy(() => import('../pages/dashboard/saei/accompanimentProgram/our-programs/ProgramDetails'))
);
const CreateProgram = Loadable(
  lazy(() => import('../pages/dashboard/saei/accompanimentProgram/our-programs/CreateProgram'))
);

const InvoiceAndPaymentTrainingList = Loadable(
  lazy(
    () =>
      import(
        '../pages/dashboard/admin/finance/invoice-and-payment-training/InvoiceAndPaymentTrainingList'
      )
  )
);

const InvoiceAndPaymentTrainingPaymentRequest = Loadable(
  lazy(
    () =>
      import(
        '../pages/dashboard/admin/finance/invoice-and-payment-training/InvoiceAndPaymentTrainingPaymentRequest'
      )
  )
);

const InvoiceAndPaymentTrainingRegisterPayment = Loadable(
  lazy(
    () =>
      import(
        '../pages/dashboard/admin/finance/invoice-and-payment-training/InvoiceAndPaymentTrainingRegisterPayment'
      )
  )
);

const DetailInvoiceAndPaymentTraining = Loadable(
  lazy(
    () =>
      import(
        '../pages/dashboard/admin/finance/invoice-and-payment-training/DetailInvoiceAndPaymentTraining'
      )
  )
);

// user meeting
const UserMeetingManagement = Loadable(
  lazy(() => import('../pages/dashboard/saei/administration/UserMeetingManagement'))
);

// events
const EventsList = Loadable(lazy(() => import('../pages/dashboard/event/EventsList')));
const CreateEvent = Loadable(lazy(() => import('../pages/dashboard/event/CreateEvent')));
const EventDetails = Loadable(lazy(() => import('../pages/dashboard/event/EventDetails')));

// statistics
const StatisticAi = Loadable(lazy(() => import(`../pages/dashboard/statistic/StatisticAi`)));

// call for applications
const CallForApplication = Loadable(
  lazy(
    () =>
      import(
        '../pages/dashboard/saei/accompanimentProgram/call-for-applications/CallForApplication'
      )
  )
);
const CreateCallForApplications = Loadable(
  lazy(
    () =>
      import(
        '../pages/dashboard/saei/accompanimentProgram/call-for-applications/CreateCallForApplications'
      )
  )
);
const CallForApplicationForm = Loadable(lazy(() => import('../pages/CallForApplicationForm')));
const CallForApplicationsDetails = Loadable(
  lazy(
    () =>
      import(
        '../pages/dashboard/saei/accompanimentProgram/call-for-applications/CallForApplicationsDetails'
      )
  )
);
const ScoringCallForApplication = Loadable(
  lazy(
    () =>
      import(
        '../pages/dashboard/saei/accompanimentProgram/call-for-applications/ScoringCallForApplication'
      )
  )
);
const CallForApplicationsResponseDetails = Loadable(
  lazy(
    () =>
      import(
        '../pages/dashboard/saei/accompanimentProgram/call-for-applications/CallForApplicationsResponseDetails'
      )
  )
);

// Our Contractors
const OurContractors = Loadable(
  lazy(() => import('../pages/dashboard/saei/accompanimentProgram/our-contractors/OurContractors'))
);
const CreateContractor = Loadable(
  lazy(
    () => import('../pages/dashboard/saei/accompanimentProgram/our-contractors/CreateContractor')
  )
);
const ContractorDetails = Loadable(
  lazy(
    () => import('../pages/dashboard/saei/accompanimentProgram/our-contractors/ContractorDetails')
  )
);
const AddProject = Loadable(
  lazy(() => import('../pages/dashboard/saei/accompanimentProgram/our-contractors/AddProject'))
);

// teaching tools
const FolderList = Loadable(
  lazy(() => import('../pages/dashboard/saei/accompanimentProgram/tools-and-resources/FolderList'))
);
const FolderDetails = Loadable(
  lazy(
    () => import('../pages/dashboard/saei/accompanimentProgram/tools-and-resources/FolderDetails')
  )
);

// Contractors Projects
const Projects = Loadable(
  lazy(() => import('../pages/dashboard/saei/accompanimentProgram/project/Projects'))
);
const ProjectDetails = Loadable(
  lazy(() => import('../pages/dashboard/saei/accompanimentProgram/project/ProjectDetails'))
);
const AddRoadmap = Loadable(
  lazy(() => import('../pages/dashboard/saei/accompanimentProgram/project/AddRoadmap'))
);
const ScheduleProjectMeeting = Loadable(
  lazy(() => import('../pages/dashboard/saei/accompanimentProgram/project/ScheduleProjectMeeting'))
);
const AddProjectMeetingReport = Loadable(
  lazy(() => import('../pages/dashboard/saei/accompanimentProgram/project/AddProjectMeetingReport'))
);

// Contractors Support
const SupportOfficersList = Loadable(
  lazy(
    () => import('../pages/dashboard/saei/accompanimentProgram/support-officers/SupportOfficerList')
  )
);

const CreateSupportOfficers = Loadable(
  lazy(
    () =>
      import('../pages/dashboard/saei/accompanimentProgram/support-officers/CreateSupportOfficers')
  )
);
const SupportOfficerDetails = Loadable(
  lazy(
    () =>
      import('../pages/dashboard/saei/accompanimentProgram/support-officers/SupportOfficerDetails')
  )
);
const InvoiceAndPaymentProgram = Loadable(
  lazy(
    () =>
      import(
        '../pages/dashboard/saei/accompanimentProgram/invice-and-payment/InvoiceAndPaymentProgram'
      )
  )
);
const InvoiceAndPaymentProgramDetails = Loadable(
  lazy(
    () =>
      import(
        '../pages/dashboard/saei/accompanimentProgram/invice-and-payment/InvoiceAndPaymentProgramDetails'
      )
  )
);

const CreateInvoiceAndPayment = Loadable(
  lazy(
    () =>
      import(
        '../pages/dashboard/saei/accompanimentProgram/invice-and-payment/CreateInvoiceAndPayment'
      )
  )
);

const RegisterPayment = Loadable(
  lazy(
    () => import('../pages/dashboard/saei/accompanimentProgram/invice-and-payment/RegisterPayment')
  )
);

// RESOURCE CENTER
const ResourceCenterList = Loadable(lazy(() => import('../pages/resource-center/ResourceCenter')));
const ResourceCenterDetails = Loadable(
  lazy(() => import('../pages/resource-center/ResourceCenterDetails'))
);

// ONLINE COURSES
const OnlineTraining = Loadable(
  lazy(
    () => import('../pages/dashboard/admin/training-and-resources/online-training/OnlineTraining')
  )
);
const AddOnlineTraining = Loadable(
  lazy(
    () =>
      import('../pages/dashboard/admin/training-and-resources/online-training/AddOnlineTraining')
  )
);
const OnlineTrainingDetail = Loadable(
  lazy(
    () =>
      import('../pages/dashboard/admin/training-and-resources/online-training/OnlineTrainingDetail')
  )
);
const CreateCourseModule = Loadable(
  lazy(
    () =>
      import('../pages/dashboard/admin/training-and-resources/online-training/CreateCourseModule')
  )
);
const OnlineTrainingSubModule = Loadable(
  lazy(
    () =>
      import(
        '../pages/dashboard/admin/training-and-resources/online-training/OnlineTrainingSubModule'
      )
  )
);

// CERTIFICATION PATH
const CertificationsPath = Loadable(
  lazy(
    () =>
      import(
        '../pages/dashboard/admin/training-and-resources/certification-path/CertificationsPath'
      )
  )
);

const CreateCertificationPath = Loadable(
  lazy(
    () =>
      import(
        '../pages/dashboard/admin/training-and-resources/certification-path/CreateCertificationPath'
      )
  )
);

const DetailsCertificationPath = Loadable(
  lazy(
    () =>
      import(
        '../pages/dashboard/admin/training-and-resources/certification-path/DetailsCertificationPath'
      )
  )
);

const CreateSectionsByCertificationPath = Loadable(
  lazy(
    () =>
      import(
        '../pages/dashboard/admin/training-and-resources/certification-path/CreateSectionByCertificationPath'
      )
  )
);

// Association AI
const AssociationAi = Loadable(
  lazy(() => import('../pages/dashboard/association-ai/AssociationAi'))
);

// Request for labeling
const Labeling = Loadable(lazy(() => import('../pages/dashboard/saei/labeling/Labeling')));
const RenewalsLabel = Loadable(
  lazy(() => import('../pages/dashboard/saei/labeling/RenewalsLabelForm'))
);

const AmiTraining = Loadable(
  lazy(() => import('../pages/dashboard/admin/training-and-resources/ami-training/AmiTraining'))
);
const CreateAmiTraining = Loadable(
  lazy(
    () => import('../pages/dashboard/admin/training-and-resources/ami-training/CreateAmiTraining')
  )
);
const AmiTrainingDetails = Loadable(
  lazy(
    () => import('../pages/dashboard/admin/training-and-resources/ami-training/AmiTrainingDetails')
  )
);

const AmiTrainingRegistrantDetails = Loadable(
  lazy(
    () =>
      import(
        '../pages/dashboard/admin/training-and-resources/ami-training/AmiTrainingRegistrantDetails'
      )
  )
);

const AmiTrainingForm = Loadable(lazy(() => import('../pages/AmiTrainingForm')));

// AMI Label
const AmiLabel = Loadable(
  lazy(() => import('../pages/dashboard/admin/labeling/ami-label/AmiLabel'))
);
const CreateAmiLabel = Loadable(
  lazy(() => import('../pages/dashboard/admin/labeling/ami-label/CreateAmiLabel'))
);
const AmiLabelForm = Loadable(lazy(() => import('../pages/AmiLabelForm')));
const AmiLabelDetails = Loadable(
  lazy(() => import('../pages/dashboard/admin/labeling/ami-label/AmiLabelDetails'))
);
const AmiLabelRegistrantDetails = Loadable(
  lazy(() => import('../pages/dashboard/admin/labeling/ami-label/AmiLabelRegistrantDetails'))
);
const LabelingRequests = Loadable(
  lazy(() => import('../pages/dashboard/admin/labeling/labeling-requests/LabelingRequests'))
);
const LabelingRequestDetails = Loadable(
  lazy(() => import('../pages/dashboard/admin/labeling/labeling-requests/LabelingRequestDetails'))
);
const LabelingFormSuccessSubmission = Loadable(
  lazy(() => import('../pages/dashboard/saei/labeling/LabelingFormSuccessSubmission'))
);

// ONLINE COURSES SAEI
const OnlineTrainingListSaei = Loadable(
  lazy(
    () =>
      import(
        '../pages/dashboard/saei/training-and-resources/online-training-cote-saei/OnlineTrainingList'
      )
  )
);
const OnlineTrainingDetails = Loadable(
  lazy(
    () =>
      import(
        '../components/_dashboard/saei/training-and-resource/details-online-training/OnlineTrainingDetails'
      )
  )
);
const OnlineTrainingSubModuleDetails = Loadable(
  lazy(
    () =>
      import(
        '../components/_dashboard/saei/training-and-resource/details-online-training/sub-module/OnlineTrainingSaeiSubModule'
      )
  )
);

// CERTIFICATE PATH SAEI
const SaeiCertificationPath = Loadable(
  lazy(
    () =>
      import(
        '../pages/dashboard/saei/training-and-resources/certification-path/SaeiCertificationPath'
      )
  )
);

const SaeiCertificationPathDetail = Loadable(
  lazy(
    () =>
      import(
        '../components/_dashboard/admin/training-and-resources/certification-path/details-certification-path/SaeiCertificationPathDetail'
      )
  )
);

// Statistics
const SaeiStatistics = Loadable(lazy(() => import('../pages/dashboard/saei/SaeiStatistics')));

// SettingsAndPreferences
const SettingsAndPreferences = Loadable(
  lazy(() => import('../pages/dashboard/settings-and-preferences/SettingsAndPreferences'))
);

// Self Diagnosis Label
const SelfDiagnosisLabel = Loadable(
  lazy(() => import('../pages/dashboard/admin/labeling/self-diagnosis-label/SelfDiagnosisLabel'))
);
const EditSelfDiagnosisLabel = Loadable(
  lazy(
    () => import('../pages/dashboard/admin/labeling/self-diagnosis-label/EditSelfDiagnosisLabel')
  )
);
const AutoDiagnosticLabelDetails = Loadable(
  lazy(
    () =>
      import('../pages/dashboard/admin/labeling/self-diagnosis-label/AutoDiagnosticLabelDetails')
  )
);
const AutoDiagnosticLabelForm = Loadable(lazy(() => import('../pages/AutoDiagnosticLabelForm')));
const RegistrationConfirmAutoDiagnostic = Loadable(
  lazy(() => import('../pages/authentication/register/RegistrationConfirmAutoDiagnostic'))
);
const AutoDiagnosticResponseDetails = Loadable(
  lazy(() => import('../pages/AutoDiagnosticResponseDetails'))
);
const ManagementOfLabels = Loadable(
  lazy(() => import('../pages/dashboard/admin/labeling/renew-label/ManagementOfLabels'))
);
const ManagementOfLabelRenewals = Loadable(
  lazy(() => import('../pages/dashboard/admin/labeling/renew-label/ManagementOfLabelRenewals'))
);
const ManagementOfLabelsDetails = Loadable(
  lazy(() => import('../pages/dashboard/admin/labeling/renew-label/ManagementOfLabelsDetails'))
);
const ManagementOfLabelRenewalsDetails = Loadable(
  lazy(
    () => import('../pages/dashboard/admin/labeling/renew-label/ManagementOfLabelRenewalsDetails')
  )
);
const RenewalsLabelForm = Loadable(
  lazy(() => import('../pages/dashboard/saei/labeling/RenewalsLabelForm'))
);
// Main
const EventRegistration = Loadable(lazy(() => import('../pages/EventRegistration')));
const EventSatisfaction = Loadable(lazy(() => import('../pages/EventSatisfaction')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
