import { createSlice } from '@reduxjs/toolkit';

// ----------------------------------------------------------------------

type LayoutState = {
  bgColorPercent: string;
};

const initialState: LayoutState = {
  bgColorPercent: '15%'
};

const layoutSlice = createSlice({
  name: 'layout',
  initialState,
  reducers: {
    // ADD CUSTOM BG COLOR PERCENT
    bgColorPercent(state, action) {
      state.bgColorPercent = action.payload;
    }
  }
});

// Reducer
export default layoutSlice.reducer;

// Actions
export const { bgColorPercent } = layoutSlice.actions;
