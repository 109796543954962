import { createSlice } from '@reduxjs/toolkit';
// types
import { AmiLabelState } from 'types/amiLabel';

// ----------------------------------------------------------------------

const initialState: AmiLabelState = {
  isLoading: false,
  error: { statusCode: 0, message: '' },
  amiLabelList: [],
  amiLabel: null,
  amiLabelRegistrantsList: [],
  amiLabelRegistrant: null
};

export const amiLabelSlice = createSlice({
  name: 'amiLabel',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET ALL AMI LABEL SUCCESS
    getAllAmiLabelSuccess(state, action) {
      state.isLoading = false;
      state.amiLabelList = action.payload;
    },

    // GET AMI LABEL SUCCESS
    getAmiLabelSuccess(state, action) {
      state.isLoading = false;
      state.amiLabel = action.payload;
    },

    // GET AMI LABEL REGISTRANTS SUCCESS
    getAmiLabelRegistrantsSuccess(state, action) {
      state.isLoading = false;
      state.amiLabelRegistrantsList = action.payload;
    },

    // GET AMI LABEL REGISTRANT DETAILS SUCCESS
    getAmiLabelRegistrantSuccess(state, action) {
      state.isLoading = false;
      state.amiLabelRegistrant = action.payload;
    }
  }
});

// Reducer
export default amiLabelSlice.reducer;
