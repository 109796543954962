import axios from 'axios';
import { API_ROOT, UPLOAD_FILE_API_ROOT, PORTAL_API_ROOT } from './config';

// ----------------------------------------------------------------------

const axiosInstance = axios.create({
  baseURL: API_ROOT,
  headers: { 'x-custom-lang': localStorage.getItem('i18nextLng') || 'en' }
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

export default axiosInstance;

// ----------------------------------------------------------------------

const uploadFile = axios.create({
  baseURL: UPLOAD_FILE_API_ROOT
});

uploadFile.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

export { uploadFile };

// ----------------------------------------------------------------------

const portalApi = axios.create({
  baseURL: PORTAL_API_ROOT
});

portalApi.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

export { portalApi };
