import { styled } from '@mui/material/styles';
// material
import { Paper } from '@mui/material';
// components
import Page from 'components/Page';

// ----------------------------------------------------------------------

export const RootStyle = styled(Page)(({ theme }) => ({
  minHeight: '100vh',
  backgroundColor: theme.palette.background.blueWhite,
  [theme.breakpoints.up('md')]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(4)
  }
}));

export const SectionStyle = styled(Paper)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  minHeight: '95vh',
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2),
  padding: theme.spacing(1),
  [theme.breakpoints.up('md')]: {
    width: '65%',
    display: 'flex',
    minHeight: '80vh',
    alignItems: 'center',
    position: 'relative',
    flexDirection: 'column',
    justifyContent: 'center',
    margin: theme.spacing(2),
    padding: theme.spacing(3),
    backgroundColor: theme.palette.background.paper
  }
}));

export const IconButtonStyle = {
  bgcolor: 'primary.main',
  color: 'common.white',
  '&:hover': { bgcolor: 'primary.main' }
};
