import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

// ----------------------------------------------------------------------

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'fr',
    debug: false,
    detection: {
      order: ['queryString', 'cookies', 'localStorage'],
      caches: ['localStorage', 'cookie']
    },
    interpolation: {
      escapeValue: false
    },
    backend: {
      loadPath: 'https://lang.ubora.app/ui/{{lng}}.json',
      crossDomain: true,
      requestOptions: {
        mode: 'cors',
        credentials: 'same-origin',
        cache: 'default'
      }
    }
  });

export default i18n;
