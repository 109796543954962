// routes
import { DASHBOARD_PATH } from '../../routes/paths';
// components
import SvgIconStyle from '../../components/SvgIconStyle';
// hooks
import useAuth from 'hooks/useAuth';
import useLocales from 'hooks/useLocales';
import { STATUS } from 'utils/status';
import { EXTERNAL, USER_ROLES } from 'common/constants';

// ----------------------------------------------------------------------

const getIcon = (name: string) => (
  <SvgIconStyle src={`/static/icons/navbar/${name}.svg`} sx={{ width: '100%', height: '100%' }} />
);

const icons = {
  dashboard: getIcon('dashboard'),
  administration: getIcon('administration'),
  candidacy: getIcon('candidacy'),
  program: getIcon('program'),
  formation: getIcon('formation'),
  academy: getIcon('academie'),
  labeling: getIcon('labelisation'),
  financing: getIcon('fond'),
  saei: getIcon('saei'),
  agenda: getIcon('agenda'),
  news: getIcon('news'),
  event: getIcon('event'),
  finance: getIcon('finance'),
  AccompanyingPrograms: getIcon('AccompanyingPrograms'),
  settings: getIcon('settings'),
  statistic: getIcon('statistic'),
  folder: getIcon('folder'),
  project: getIcon('project')
};

export default function useSaeiSidebarConfig() {
  const { translate } = useLocales();
  const { entity, role, profileType } = useAuth();

  const UnactivatedSaeiSidebarConfig = [
    {
      subheader: `${translate('adminSidebarConfig.dashboard')}`,
      items: [
        {
          title: `${translate('adminSidebarConfig.dashboard')}`,
          path: DASHBOARD_PATH.general.dashboard,
          icon: icons.dashboard
        }
      ]
    }
  ];

  const ActivatedSaeiSidebarConfig = [
    {
      subheader: `${translate('adminSidebarConfig.dashboard')}`,
      moduleName: 'dashboard',
      items: [
        {
          title: `${translate('adminSidebarConfig.dashboard')}`,
          path: DASHBOARD_PATH.general.dashboard,
          icon: icons.dashboard
        }
      ]
    },
    {
      subheader: `${translate('adminSidebarConfig.administration')}`,
      moduleName: 'administration',
      items: [
        {
          title: `${translate('adminSidebarConfig.administration')}`,
          path: DASHBOARD_PATH.administration.root,
          icon: icons.administration,
          children: [
            {
              title: `${translate('adminSidebarConfig.profile')} ${entity?.name}`,
              path: DASHBOARD_PATH.administration.profile,
              name: 'profile'
            },
            {
              title: `${translate('adminSidebarConfig.rolesAndPermissions')}`,
              path: DASHBOARD_PATH.administration.roleAndPermission,
              name: 'roleAndPermission'
            },
            {
              title: `${translate('adminSidebarConfig.team')} ${entity?.name}`,
              path: DASHBOARD_PATH.administration.team,
              name: 'team'
            },
            {
              title: `${translate('saeisMembers.meetingManagement')}`,
              path: DASHBOARD_PATH.administration.meetingManagement,
              name: 'meeting'
            },
            {
              title: `${translate('adminSidebarConfig.association')}`,
              path: DASHBOARD_PATH.administration.associationAi,
              name: 'africinnovAssociation'
            }
          ]
        }
      ]
    },
    {
      subheader: `${translate('finance.finance')}`,
      moduleName: 'finance',
      items: [
        {
          title: `${translate('finance.finance')}`,
          path: DASHBOARD_PATH.finance.root,
          icon: icons.finance,
          children: [
            {
              title: `${translate('finance.invoicesPaymentsSaei')}`,
              path: DASHBOARD_PATH.finance.invoiceAndPaymentSaei,
              name: 'africinnovInvoiceAndPayment'
            },
            {
              title: `${translate('listSaeiInvoices.invoicesContractorPayments')}`,
              path: DASHBOARD_PATH.finance.invoiceAndPaymentEntrepreneur,
              name: 'invoicesPaymentsContractor'
            }
          ]
        }
      ]
    },
    {
      subheader: `${translate('adminSidebarConfig.accompanyingPrograms')}`,
      moduleName: 'coachingProgram',
      items: [
        {
          title: `${translate('adminSidebarConfig.accompanyingPrograms')}`,
          path: DASHBOARD_PATH.accompanyingPrograms.root,
          icon: icons.program,
          children: [
            {
              title: `${translate('programs.programs')}`,
              path: DASHBOARD_PATH.accompanyingPrograms.programList,
              name: 'programs'
            },
            {
              title: `${translate('adminSidebarConfig.callForApplications')}`,
              path: DASHBOARD_PATH.accompanyingPrograms.callForApplications,
              name: 'callForApplication'
            },

            {
              title: `${translate('adminSidebarConfig.contractors')}`,
              path: DASHBOARD_PATH.accompanyingPrograms.ourContractors,
              name: 'entrepreneur'
            },
            {
              title: `${translate('adminSidebarConfig.projects')}`,
              path: DASHBOARD_PATH.accompanyingPrograms.projects,
              name: 'entrepreneurProject'
            },

            {
              title: `${translate('supportWorker.supportOfficers')}`,
              path: DASHBOARD_PATH.accompanyingPrograms.supportOfficersList,
              name: 'supportManager'
            },
            {
              title: `${translate('adminSidebarConfig.educationalToolsAndResources')}`,
              path: DASHBOARD_PATH.accompanyingPrograms.teachingToolsList,
              name: 'didacticTool'
            }
          ]
        }
      ]
    },
    {
      subheader: `${translate('adminSidebarConfig.trainingsResources')}`,
      moduleName: 'formationAndResource',
      items: [
        {
          title: `${translate('adminSidebarConfig.trainingsResources')}`,
          path: DASHBOARD_PATH.trainingAndResources.root,
          icon: icons.formation,
          children: [
            {
              title: `${translate('resourceCenter.onlineTraining')}`,
              path: DASHBOARD_PATH.trainingAndResources.onlineTrainingListSaei,
              name: 'onlineCourse'
            },
            {
              title: `${translate('resourceCenter.resourceCenter')}`,
              path: DASHBOARD_PATH.trainingAndResources.resourceCenterList,
              name: 'resourceCenter'
            },
            {
              title: `${translate('rolesAndPermissions.certificationPath')}`,
              path: DASHBOARD_PATH.trainingAndResources.certificationPathSaei,
              name: 'certificationPath'
            }
          ]
        }
      ]
    },
    {
      subheader: `${translate('adminSidebarConfig.labeling')}`,
      moduleName: 'labeling-ai',
      items: [
        {
          title: `${translate('adminSidebarConfig.labeling')}`,
          path: DASHBOARD_PATH.labelingSaei.root,
          icon: icons.labeling,
          children: [
            {
              title: `${translate('requestForLabelling.requestForLabelling')}`,
              path: DASHBOARD_PATH.labelingSaei.requestForLabeling,
              name: 'labelingApplication'
            },
            {
              title: `${translate('managementOfLabels.renewalOfLabel')}`,
              path: DASHBOARD_PATH.labelingSaei.renewalLabel,
              name: 'renewalLabel'
            }
          ]
        }
      ]
    },
    {
      subheader: `${translate('adminSidebarConfig.event')}`,
      moduleName: 'event',
      items: [
        {
          title: `${translate('adminSidebarConfig.event')}`,
          path: DASHBOARD_PATH.event.root,
          icon: icons.event
        }
      ]
    },
    {
      subheader: `${translate('adminSidebarConfig.news')}`,
      moduleName: 'post',
      items: [
        {
          title: `${translate('adminSidebarConfig.news')}`,
          path: DASHBOARD_PATH.post.postList,
          icon: icons.news
        }
      ]
    },
    {
      subheader: `${translate('adminSidebarConfig.statisticsIndicators')}`,
      moduleName: 'statistics',
      items: [
        {
          title: `${translate('adminSidebarConfig.statisticsIndicators')}`,
          path: DASHBOARD_PATH.statistics.root,
          icon: icons.statistic
        }
      ]
    },
    {
      subheader: `${translate('adminSidebarConfig.settingsPreferences')}`,
      moduleName: 'settings',
      items: [
        {
          title: `${translate('adminSidebarConfig.settingsPreferences')}`,
          path: DASHBOARD_PATH.settingsAndPreferences.root,
          icon: icons.settings
        }
      ]
    }
  ];

  const DisabledSaeiSidebarConfig = [
    {
      subheader: `${translate('adminSidebarConfig.dashboard')}`,
      items: [
        {
          title: `${translate('adminSidebarConfig.dashboard')}`,
          path: DASHBOARD_PATH.general.dashboard,
          icon: icons.dashboard
        }
      ]
    },
    {
      subheader: `${translate('adminSidebarConfig.administration')}`,
      items: [
        {
          title: `${translate('adminSidebarConfig.administration')}`,
          path: DASHBOARD_PATH.administration.root,
          icon: icons.administration,
          children: [
            {
              title: `${translate('adminSidebarConfig.profile')} ${entity?.name}`,
              path: DASHBOARD_PATH.administration.profile
            },

            {
              title: `${translate('adminSidebarConfig.association')}`,
              path: 'association'
            }
          ]
        }
      ]
    },
    {
      subheader: `${translate('finance.finance')}`,
      items: [
        {
          title: `${translate('finance.finance')}`,
          path: DASHBOARD_PATH.finance.root,
          icon: icons.finance,
          children: [
            {
              title: `${translate('finance.invoicesPaymentsSaei')}`,
              path: DASHBOARD_PATH.finance.invoiceAndPaymentSaei
            },
            {
              title: `${translate('finance.invoicesPaymentsContractor')}`,
              path: 'payment-saei'
            }
          ]
        }
      ]
    }
  ];

  const SupportOfficerSidebarConfig = [
    {
      subheader: `${translate('adminSidebarConfig.dashboard')}`,
      moduleName: 'dashboard',
      items: [
        {
          title: `${translate('adminSidebarConfig.dashboard')}`,
          path: DASHBOARD_PATH.general.dashboard,
          icon: icons.dashboard
        }
      ]
    },
    {
      subheader: `${
        USER_ROLES.CONTRACTOR === role?.name
          ? translate('adminSidebarConfig.myProjects')
          : translate('adminSidebarConfig.contractorProjects')
      }`,
      moduleName: 'coachingProgram',
      items: [
        {
          title: `${
            USER_ROLES.CONTRACTOR === role?.name
              ? translate('adminSidebarConfig.myProjects')
              : translate('adminSidebarConfig.contractorProjects')
          }`,
          path: DASHBOARD_PATH.accompanyingPrograms.projects,
          icon: icons.project
        }
      ]
    },
    {
      subheader: `${translate('adminSidebarConfig.educationalToolsAndResources')}`,
      moduleName: 'coachingProgram',
      items: [
        {
          title: `${translate('adminSidebarConfig.educationalToolsAndResources')}`,
          path: DASHBOARD_PATH.accompanyingPrograms.teachingToolsList,
          icon: icons.folder
        }
      ]
    },
    {
      subheader: `${translate('adminSidebarConfig.event')}`,
      moduleName: 'event',
      items: [
        {
          title: `${translate('adminSidebarConfig.event')}`,
          path: DASHBOARD_PATH.event.root,
          icon: icons.event
        }
      ]
    },
    {
      subheader: `${translate('finance.invoicesPayments')}`,
      moduleName: 'invoice',
      items: [
        {
          title: `${translate('finance.invoicesPayments')}`,
          path: DASHBOARD_PATH.accompanyingPrograms.invoiceAndPayment,
          icon: icons.finance
        }
      ]
    },
    {
      subheader: `${translate('adminSidebarConfig.settingsPreferences')}`,
      moduleName: 'settings',
      items: [
        {
          title: `${translate('adminSidebarConfig.settingsPreferences')}`,
          path: DASHBOARD_PATH.settingsAndPreferences.root,
          icon: icons.settings
        }
      ]
    }
  ];

  let SaeiSidebarConfig;

  if (
    USER_ROLES.CONTRACTOR === role?.name ||
    (USER_ROLES.SUPPORT_OFFICER === role?.name && profileType === EXTERNAL)
  ) {
    SaeiSidebarConfig = SupportOfficerSidebarConfig;
  } else if (entity?.entityData?.data?.accountStatus === STATUS.DISABLED) {
    SaeiSidebarConfig = DisabledSaeiSidebarConfig;
  } else if (entity?.status?.translation_token === STATUS.VAL && entity?.adhesionDate) {
    SaeiSidebarConfig = ActivatedSaeiSidebarConfig;
  } else {
    SaeiSidebarConfig = UnactivatedSaeiSidebarConfig;
  }

  return SaeiSidebarConfig;
}
