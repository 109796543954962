export const getFileExtension = (fileName: string) => {
  return fileName?.split('.').pop();
};

export enum FILE_EXTENSION {
  PDF = 'pdf',
  DOCX = 'docx',
  PNG = 'png'
}

export enum FILE_TYPES {
  PDF = 'application/pdf',
  DOCX = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  PNG = 'image/png',
  JPEG = 'image/jpeg'
}

export const isAPdfDocument = (fileName: string) => {
  if (getFileExtension(fileName) === FILE_EXTENSION.PDF) {
    return true;
  }

  return false;
};

export const isAWordDocument = (fileName: string) => {
  if (getFileExtension(fileName) === FILE_EXTENSION.DOCX) {
    return true;
  }

  return false;
};

export const isAPngImage = (fileName: string) => {
  if (getFileExtension(fileName) === FILE_EXTENSION.PNG) {
    return true;
  }

  return false;
};
