// redux
import { createSlice } from '@reduxjs/toolkit';
// types
import { UserState } from 'types/user';
// thunk
import { userEmailValidation, updateUserProfile, updateUserPassword } from '../thunks/user.thunk';

// ----------------------------------------------------------------------

const initialState: UserState = {
  isLoading: false,
  error: { statusCode: 0, message: '' },
  user: null,
  profile: null,
  images: {
    uploadProfileImage: null,
    userProfileImageUrl: null
  }
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET USER INFORMATION
    getUserInformationSuccess(state, action) {
      state.isLoading = false;
      state.profile = action.payload;
    },

    // UPLOAD PROFILE IMAGE
    uploadProfileImageSuccess(state, action) {
      state.isLoading = false;
      state.images.uploadProfileImage = action.payload;
    },

    // GET PROFILE IMAGE
    getProfileImageSuccess(state, action) {
      state.isLoading = false;
      state.images.userProfileImageUrl = action.payload;
    },
    // RESET STATE
    resetUserState: () => initialState
  },

  extraReducers: (builder) => {
    //USER EMAIL VALIDATION
    builder.addCase(userEmailValidation.fulfilled, (state, action) => {
      state.isLoading = false;
      state.user = action.payload;
    });

    // UPDATE USER INFO IS FULFILLED
    builder.addCase(updateUserProfile.fulfilled, (state, action) => {
      state.isLoading = false;
      state.profile = action.payload;
    });

    // UPDATE USER PASSWORD IS PENDING
    builder.addCase(updateUserPassword.pending, (state, action) => {
      state.isLoading = true;
    });

    // UPDATE USER PASSWORD IS FULFILLED
    builder.addCase(updateUserPassword.fulfilled, (state, action) => {
      state.isLoading = false;
    });
  }
});

// Reducer
export default userSlice.reducer;

// Actions
export const { resetUserState, uploadProfileImageSuccess } = userSlice.actions;
