import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
// slices
import teamReducer from './slices/team';
import userReducer from './slices/user';
import postReducer from './slices/post';
import saeisReducer from './slices/saeis';
import entityReducer from './slices/entity';
import layoutReducer from './slices/layout';
import financeReducer from './slices/finance';
import projectReducer from './slices/project';
import meetingReducer from './slices/meeting';
import metadataReducer from './slices/metadata';
import amiLabelReducer from './slices/amiLabel';
import labelingReducer from './slices/labeling';
import currentTableReducer from './slices/currentTable';
import contractorReducer from './slices/contractor';
import googleAuthReducer from './slices/googleAuth';
import invoiceSaeiReducer from './slices/invoiceSaei';
import associationAiReducer from './slices/associationAi';
import saeiStatisticsReducer from './slices/saeiStatistics';
import roleAndPermissionReducer from './slices/roleAndPermission';
import membershipRequestReducer from './slices/membershipRequest';
import programReducer from './slices/programCoaching';
import toolsReducer from './slices/toolsAndRessource';
import callForApplicationsReducer from './slices/callForApplications';
import supportOfficersReducer from './slices/supportOfficers';
import resourceCenterReducer from './slices/resourceCenter';
import onlineTrainingReducer from './slices/onlineTraining';
import amiTrainingReducer from './slices/amiTraining';
import dashboardReducer from './slices/dashboard';
import statisticsindicatorReducer from './slices/statisticsIndicators';
import selfDiagnosisReducer from './slices/selfDiagnosis';
import entrepreneurInvicesReducer from './slices/entrepreneurInvoices';
import certificationPathReducer from './slices/certificationPath';
import renewalsLabelReducer from './slices/renewalsLabel';
import invoiceAndPaymentReducer from './slices/invoiceAndPaymentTraining';
// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: []
};

const rootReducer = combineReducers({
  news: postReducer,
  user: userReducer,
  team: teamReducer,
  saeis: saeisReducer,
  entity: entityReducer,
  layout: layoutReducer,
  finance: financeReducer,
  project: projectReducer,
  meeting: meetingReducer,
  google: googleAuthReducer,
  amiLabel: amiLabelReducer,
  labeling: labelingReducer,
  contractor: contractorReducer,
  invoiceSaei: invoiceSaeiReducer,
  currentTable: currentTableReducer,
  associationAi: associationAiReducer,
  membershipRequest: membershipRequestReducer,
  roleAndPermission: roleAndPermissionReducer,
  metadata: metadataReducer,
  programs: programReducer,
  tools: toolsReducer,
  callForApplications: callForApplicationsReducer,
  supportOfficers: supportOfficersReducer,
  resourceCenter: resourceCenterReducer,
  onlineTraining: onlineTrainingReducer,
  amiTraining: amiTrainingReducer,
  dashboard: dashboardReducer,
  saeiStatistics: saeiStatisticsReducer,
  statisticsindicator: statisticsindicatorReducer,
  selfDiagnosis: selfDiagnosisReducer,
  entrepreneurInvices: entrepreneurInvicesReducer,
  certificationPath: certificationPathReducer,
  renewalsLabel: renewalsLabelReducer,
  invoiceAndPayment: invoiceAndPaymentReducer
});

export { rootPersistConfig, rootReducer };
