import { createSlice } from '@reduxjs/toolkit';
import { renewalsLabelState } from 'types/renewalsLabel';

const initialState: renewalsLabelState = {
  isLoading: false,
  error: false,
  labeledEntities: [],
  labeledEntity: null,
  labelingRenewals: [],
  labelingRenewal: null
};

export const renewalsLabelSlice = createSlice({
  name: 'renewalsLabel',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    //   HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET LABELED ENTITIES SUCCESS
    getLabeledEntitiesSuccess(state, action) {
      state.isLoading = false;
      state.labeledEntities = action.payload;
    },

    // GET LABELED ENTITY SUCCESS
    getLabeledEntitySuccess(state, action) {
      state.isLoading = false;
      state.labeledEntity = action.payload;
    },

    // GET LABELING RENEWALS SUCCESS
    getLabelingRenewalsSuccess(state, action) {
      state.isLoading = false;
      state.labelingRenewals = action.payload;
    },

    // GET LABELING RENEWAL SUCCESS
    getLabelingRenewalSuccess(state, action) {
      state.isLoading = false;
      state.labelingRenewal = action.payload;
    }
  }
});

// Reducer
export default renewalsLabelSlice.reducer;
