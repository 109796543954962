import { createSlice } from '@reduxjs/toolkit';
// types
import { ProjectState } from 'types/project';

// ----------------------------------------------------------------------

const initialState: ProjectState = {
  isLoading: false,
  error: { statusCode: 0, message: '' },
  projects: [],
  project: null,
  healthHistory: [],
  projectMeetingAttendees: [],
  projectMeeting: null,
  projectMeetingReport: null,
  projectsMeeting: [],
  roadmap: []
};

export const projectSlice = createSlice({
  name: 'project',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET PROJECTS SUCCESS
    getProjectsSuccess(state, action) {
      state.isLoading = false;
      state.projects = action.payload;
    },

    // GET PROJECT SUCCESS
    getProjectSuccess(state, action) {
      state.isLoading = false;
      state.project = action.payload;
    },

    // GET PROJECT SUCCESS
    getProjectHealthHistorySuccess(state, action) {
      state.isLoading = false;
      state.healthHistory = action.payload;
    },

    // GET PROJECT MEETING ATTENDEES SUCCESS
    getProjectMeetingAttendeesSuccess(state, action) {
      state.isLoading = false;
      state.projectMeetingAttendees = action.payload;
    },

    // GET PROJECT MEETING
    getProjectMeetingSuccess(state, action) {
      state.isLoading = false;
      state.projectMeeting = action.payload;
    },

    // GET PROJECTS MEETING
    getProjectsMeetingSuccess(state, action) {
      state.isLoading = false;
      state.projectsMeeting = action.payload;
    },

    // GET PROJECTS ROADMAP
    getProjectsRoadmapSuccess(state, action) {
      state.isLoading = false;
      state.roadmap = action.payload;
    },

    // GET PROJECTS MEETING REPORT
    getProjectMeetingReportSuccess(state, action) {
      state.isLoading = false;
      state.projectMeetingReport = action.payload;
    }
  }
});

// Reducer
export default projectSlice.reducer;
