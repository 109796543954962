import { Icon } from '@iconify/react';
import menu2Fill from '@iconify/icons-eva/menu-2-fill';
// material
import { Box, Stack, IconButton } from '@mui/material';
// hooks
import useCollapseDrawer from '../../hooks/useCollapseDrawer';
// components
import { MHidden } from '../../components/@material-extend';
import AccountPopover from './AccountPopover';
import LanguagePopover from './LanguagePopover';
import SettingMode from '../../components/settings/SettingMode';
// types
import { DashboardNavbarProps } from 'types/dashbord';
// styles
import { StyledAdminDashbordAppBar, StyledAdminDashboardToolbar } from './Dashboard.style';
// constants
import { COLLAPSE_WIDTH } from './Dashboard.constants';

// ----------------------------------------------------------------------

export default function AdminDashboardNavbar({ onOpenSidebar }: DashboardNavbarProps) {
  const { isCollapse } = useCollapseDrawer();

  return (
    <StyledAdminDashbordAppBar
      sx={{
        ...(isCollapse && {
          width: { lg: `calc(100% - ${COLLAPSE_WIDTH}px)` }
        })
      }}
    >
      <StyledAdminDashboardToolbar>
        <MHidden width="lgUp">
          <IconButton onClick={onOpenSidebar} sx={{ mr: 1, color: 'text.primary' }}>
            <Icon icon={menu2Fill} />
          </IconButton>
        </MHidden>

        <Box sx={{ flexGrow: 1 }} />

        <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>
          <LanguagePopover />
          <SettingMode />
          <AccountPopover />
        </Stack>
      </StyledAdminDashboardToolbar>
    </StyledAdminDashbordAppBar>
  );
}
