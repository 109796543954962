import { EXTERNAL, USER_ROLES } from 'common/constants';

export function isExternalSupportOfficer({
  userRoleName,
  supportOfficer,
  profileType
}: {
  userRoleName: string;
  supportOfficer: string;
  profileType: string;
}) {
  if (userRoleName === supportOfficer && profileType === EXTERNAL) return true;
  return false;
}

export const isContractor = (userRoleName: string) => {
  return userRoleName === USER_ROLES.CONTRACTOR ? true : false;
};
