// routes

import { DASHBOARD_PATH } from '../../routes/paths';
// components
import SvgIconStyle from 'components/SvgIconStyle';
// hooks
import useLocales from 'hooks/useLocales';

// ----------------------------------------------------------------------

const getIcon = (name: string) => (
  <SvgIconStyle src={`/static/icons/navbar/${name}.svg`} sx={{ width: '100%', height: '100%' }} />
);

const icons = {
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('dashboard'),
  administration: getIcon('admin'),
  event: getIcon('event'),
  saei: getIcon('saei'),
  finance: getIcon('finance'),
  formation: getIcon('formation'),
  academie: getIcon('academie'),
  labelisation: getIcon('labelisation'),
  fond: getIcon('fond'),
  partner: getIcon('partner'),
  statistic: getIcon('statistic'),
  settings: getIcon('settings')
};

export default function useAdminSidebarConfig() {
  const { translate } = useLocales();

  const AdminSidebarConfig = [
    {
      subheader: `${translate('adminSidebarConfig.dashboard')}`,
      moduleName: 'dashboard',
      items: [
        {
          title: `${translate('adminSidebarConfig.dashboard')}`,
          path: DASHBOARD_PATH.general.dashboard,
          icon: icons.dashboard
        }
      ]
    },
    {
      subheader: `${translate('adminSidebarConfig.administration')}`,
      moduleName: 'administrationAi',
      items: [
        {
          title: `${translate('adminSidebarConfig.administration')}`,
          path: DASHBOARD_PATH.administration.root,
          icon: icons.administration,
          children: [
            {
              title: `${translate('adminSidebarConfig.rolesAndPermissions')}`,
              path: DASHBOARD_PATH.administration.roleAndPermission,
              name: 'roleAndPermission'
            },
            {
              title: `${translate('adminSidebarConfig.team')}`,
              path: DASHBOARD_PATH.administration.team,
              name: 'team'
            },
            {
              title: `${translate('adminSidebarConfig.association')}`,
              path: DASHBOARD_PATH.administration.associationAi,
              name: 'africinnovAssociation'
            }
          ]
        }
      ]
    },
    {
      subheader: `${translate('finance.aiFinance')}`,
      moduleName: 'financeAi',
      items: [
        {
          title: `${translate('finance.aiFinance')}`,
          path: DASHBOARD_PATH.finance.root,
          icon: icons.finance,
          children: [
            {
              title: `${translate('adminSidebarConfig.invoicesPayments')}`,
              path: DASHBOARD_PATH.finance.invoiceAndPaymentAssociation,
              name: 'africinnovInvoiceAndPayment'
            },
            {
              title: `${translate('finance.invoicesPaymentsTraining')}`,
              path: DASHBOARD_PATH.finance.invoiceAndPaymentsTraining,
              name: 'africinnovInvoiceAndPaymentTraining'
            }
          ]
        }
      ]
    },
    {
      subheader: `${translate('adminSidebarConfig.saei')}`,
      moduleName: 'saeiAi',
      items: [
        {
          title: `${translate('adminSidebarConfig.saei')}`,
          path: DASHBOARD_PATH.saei.root,
          icon: icons.saei,
          children: [
            {
              title: `${translate('adminSidebarConfig.membershipApplications')}`,
              path: DASHBOARD_PATH.saei.requestsList,
              name: 'membershipApplication'
            },
            {
              title: `${translate('adminSidebarConfig.saeiManagement')}`,
              path: DASHBOARD_PATH.saei.saiesMembers,
              name: 'saeiManagement'
            }
          ]
        }
      ]
    },
    {
      subheader: `${translate('adminSidebarConfig.trainingsResources')}`,
      moduleName: 'formationAndResourceAI',
      items: [
        {
          title: `${translate('adminSidebarConfig.trainingsResources')}`,
          path: DASHBOARD_PATH.trainingAndResources.root,
          icon: icons.formation,
          children: [
            {
              title: `${translate('resourceCenter.resourceCenter')}`,
              path: DASHBOARD_PATH.trainingAndResources.resourceCenterList,
              name: 'resourceCenter'
            },
            {
              title: `${translate('resourceCenter.onlineTraining')}`,
              path: DASHBOARD_PATH.trainingAndResources.onlineTrainingList,
              name: 'onlineCourse'
            },
            {
              title: `${translate(`adminSidebarConfig.amiCertificationTraining`)}`,
              path: DASHBOARD_PATH.trainingAndResources.amiTraining,
              name: 'certifyingCourse'
            },
            {
              title: `${translate('rolesAndPermissions.certificationPath')}`,
              path: DASHBOARD_PATH.trainingAndResources.certificationPathAi,
              name: 'certificationPath'
            }
          ]
        }
      ]
    },
    {
      subheader: `${translate('adminSidebarConfig.labeling')}`,
      moduleName: 'labelingAi',
      items: [
        {
          title: `${translate('adminSidebarConfig.labeling')}`,
          path: DASHBOARD_PATH.labelling.root,
          icon: icons.labelisation,
          children: [
            {
              title: `${translate('requestForLabelling.labelAmi')}`,
              path: DASHBOARD_PATH.labelling.amiLabel,
              name: 'amiLabel'
            },
            {
              title: `${translate('requestForLabelling.requestForLabelling')}`,
              path: DASHBOARD_PATH.labelling.labelingRequests,
              name: 'labelingApplication'
            },
            {
              title: `${translate('selfDiagnosisLabel.selfDiagnosisLabel')}`,
              path: DASHBOARD_PATH.labelling.selfDiagnosisLabel,
              name: 'selfDiagnosisLabel'
            },
            {
              title: `${translate('managementOfLabels.managementOfLabels')}`,
              path: DASHBOARD_PATH.labelling.managementOfLabels,
              name: 'managementOfLabels'
            }
          ]
        }
      ]
    },
    {
      subheader: `${translate('adminSidebarConfig.event')}`,
      moduleName: 'event',
      items: [
        {
          title: `${translate('adminSidebarConfig.event')}`,
          path: DASHBOARD_PATH.event.root,
          icon: icons.event
        }
      ]
    },
    {
      subheader: `${translate('adminSidebarConfig.statisticsIndicators')}`,
      moduleName: 'statistics',
      items: [
        {
          title: `${translate('adminSidebarConfig.statisticsIndicators')}`,
          path: DASHBOARD_PATH.statistic.root,
          icon: icons.statistic
        }
      ]
    },
    {
      subheader: `${translate('adminSidebarConfig.settingsPreferences')}`,
      moduleName: 'settings',
      items: [
        {
          title: `${translate('adminSidebarConfig.settingsPreferences')}`,
          path: DASHBOARD_PATH.settingsAndPreferences.root,
          icon: icons.settings
        }
      ]
    }
  ];

  return AdminSidebarConfig;
}
