import { createSlice } from '@reduxjs/toolkit';

// ----------------------------------------------------------------------

type currentTableState = {
  isTableFilterOpened: boolean;
};

const initialState: currentTableState = {
  isTableFilterOpened: false
};

const currentTableSlice = createSlice({
  name: 'currentTable',
  initialState,
  reducers: {
    // OPEN TABLE FILTER FORM
    openTableFilterForm(state) {
      state.isTableFilterOpened = !state.isTableFilterOpened;
    }
  }
});

// Reducer
export default currentTableSlice.reducer;

// Actions
export const { openTableFilterForm } = currentTableSlice.actions;
