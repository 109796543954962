// material
import { alpha } from '@mui/material/styles';

export const CollapseStyle = ({ theme }) => {
  return {
    width: '85%',
    marginLeft: '20px',
    display: 'flex',
    justifyContent: 'center',
    borderBottomLeftRadius: '8px',
    borderBottomRightRadius: '8px',
    bgcolor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity)
  };
};

export const ArrowIconStyle = {
  width: 16,
  height: 16,
  ml: 1
};
