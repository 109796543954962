// @ts-nocheck
import useAuth from '../hooks/useAuth';
// components
import { MAvatar } from './@material-extend';
import { MAvatarProps } from './@material-extend/MAvatar';
import createAvatar from '../utils/createAvatar';
// redux
import { useSelector } from 'state/store';
// selectors
import { selectUserProfile } from 'state/selectors/user.selectors';

// ----------------------------------------------------------------------

export default function MyAvatar({ ...other }: MAvatarProps) {
  const { user } = useAuth();
  const profile = useSelector(selectUserProfile);

  return (
    <>
      <MAvatar variant="rounded" src={profile?.picture || ''} alt={user?.firstName} {...other}>
        {createAvatar(user?.firstName).name}
      </MAvatar>
    </>
  );
}
