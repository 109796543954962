import { useState } from 'react';
import { Icon } from '@iconify/react';
import { NavLink as RouterLink, matchPath, useLocation } from 'react-router-dom';
import arrowIosForwardFill from '@iconify/icons-eva/arrow-ios-forward-fill';
import arrowIosDownwardFill from '@iconify/icons-eva/arrow-ios-downward-fill';
// material
import { useTheme } from '@mui/material/styles';
import { Box, List, Collapse, ListItemText } from '@mui/material';
// styles
import {
  StyledAdminListItemButton,
  StyledAdminActiveSubItem,
  StyledAdminDefaultRoot,
  StyledAdminListItemIcon,
  StyledAdminNavItemActiveRoot
} from 'styles/NavSection.style';
import { CollapseStyle, ArrowIconStyle } from './NavItem.style';
// types
import { NavItemProps } from 'types/navSection';

// ----------------------------------------------------------------------

export function NavItem({ item, isShown }: { item: NavItemProps; isShown?: boolean | undefined }) {
  const theme = useTheme();
  const { pathname } = useLocation();
  const { title, path, icon, info, children } = item;
  const isActiveRoot = path ? !!matchPath({ path, end: false }, pathname) : false;

  const [open, setOpen] = useState(isActiveRoot);

  const handleOpen = () => {
    setOpen(!open);
  };

  if (children) {
    return (
      <>
        <StyledAdminListItemButton
          onClick={handleOpen}
          sx={{
            ...(isActiveRoot ? StyledAdminNavItemActiveRoot : StyledAdminDefaultRoot),
            borderBottomLeftRadius: '0px',
            borderBottomRightRadius: '0px'
          }}
        >
          <StyledAdminListItemIcon>{icon && icon}</StyledAdminListItemIcon>

          {isShown && (
            <>
              <ListItemText disableTypography primary={title} />
              {info && info}
              <Box
                component={Icon}
                icon={open ? arrowIosDownwardFill : arrowIosForwardFill}
                sx={{ ...ArrowIconStyle }}
              />
            </>
          )}
        </StyledAdminListItemButton>

        {isShown && (
          <Collapse
            in={open}
            timeout="auto"
            unmountOnExit
            sx={{
              ...CollapseStyle({ theme })
            }}
          >
            <List component="div" disablePadding>
              {children.map((item) => {
                const { title, path, isHidden } = item;
                const isActiveSub = path ? !!matchPath({ path, end: false }, pathname) : false;

                return (
                  <>
                    {isHidden ? null : (
                      <StyledAdminListItemButton
                        key={title}
                        component={RouterLink}
                        to={path}
                        sx={{
                          ...(isActiveSub && StyledAdminActiveSubItem)
                        }}
                      >
                        <ListItemText
                          disableTypography
                          primary={title}
                          sx={{ paddingLeft: '30px' }}
                        />
                      </StyledAdminListItemButton>
                    )}
                  </>
                );
              })}
            </List>
          </Collapse>
        )}
      </>
    );
  }

  return (
    <StyledAdminListItemButton
      component={RouterLink}
      to={path}
      sx={{
        ...(isActiveRoot ? StyledAdminNavItemActiveRoot : StyledAdminDefaultRoot)
      }}
    >
      <StyledAdminListItemIcon>{icon && icon}</StyledAdminListItemIcon>
      {isShown && (
        <>
          <ListItemText disableTypography primary={title} />
          {info && info}
        </>
      )}
    </StyledAdminListItemButton>
  );
}
