import { createSlice } from '@reduxjs/toolkit';
import { updateInvoiceStatus } from 'state/thunks/finance.thunk';
// types
import { FinanceState } from 'types/finance';

// ----------------------------------------------------------------------

const initialState: FinanceState = {
  isLoading: false,
  error: false,
  associationInvoices: [],
  associationInvoiceDetails: null,
  saeiInvoicesHistory: [],
  updateInvoiceStatus: null,
  voucher: null
};

export const financeSlice = createSlice({
  name: 'finance',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET ASSOCIATION INVOICES
    getAssociationInvoicesSuccess(state, action) {
      state.isLoading = false;
      state.associationInvoices = action.payload;
    },

    // GET ASSOCIATION INVOICE DETAILS
    getAssociationInvoiceDetailsSuccess(state, action) {
      state.isLoading = false;
      state.associationInvoiceDetails = action.payload;
    },

    // GET SAEI ASSOCIATION INVOICES HISTORY
    getSaeiInvoicesHistorySuccess(state, action) {
      state.isLoading = false;
      state.saeiInvoicesHistory = action.payload;
    },

    // UPLOAD VOUCHER
    uploadVoucherSuccess(state, action) {
      state.isLoading = false;
      state.voucher = action.payload;
    }
  },
  extraReducers: (builder) => {
    // UPDATE INVOICE STATUS IS PENDING
    builder.addCase(updateInvoiceStatus.pending, (state, action) => {
      state.isLoading = true;
    });

    // UPDATE INVOICE STATUS IS FULFILLED
    builder.addCase(updateInvoiceStatus.fulfilled, (state, action) => {
      state.isLoading = false;
      state.updateInvoiceStatus = action.payload;
    });

    // UPDATE INVOICE STATUS IS REJECTED
    builder.addCase(updateInvoiceStatus.rejected, (state, action) => {
      state.isLoading = false;
    });
  }
});

// Reducer
export default financeSlice.reducer;
