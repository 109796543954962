import { useEffect, useState } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { VERSION_ROOT } from '../../utils/config';
// material
import { alpha } from '@mui/material/styles';
import { Box, Stack, Drawer } from '@mui/material';
// hooks
import useCollapseDrawer from '../../hooks/useCollapseDrawer';
// components
import Label from 'components/Label';
import Scrollbar from 'components/Scrollbar';
import { AdminNavSection } from 'components/admin-nav';
import { MHidden } from 'components/@material-extend';
import useAdminSidebarConfig from './AdminSidebarConfig';
import { IconCollapse } from './IconCollapse';
// constants
import { ADMIN_DRAWER_WIDTH, COLLAPSE_WIDTH } from './Dashboard.constants';
// styles
import { StyledDashboardSidebarDiv } from './Dashboard.style';
// types
import { DashboardSidebarProps } from 'types/dashbord';
import useAuth from 'hooks/useAuth';

// ----------------------------------------------------------------------

export default function AdminDashboardSidebar({
  isOpenSidebar,
  onCloseSidebar
}: DashboardSidebarProps) {
  const { permissions } = useAuth();
  const { pathname } = useLocation();
  const [version, setVersion] = useState('');
  const SidebarConfig = useAdminSidebarConfig() as any;

  const { isCollapse, collapseClick, collapseHover, onToggleCollapse, onHoverEnter, onHoverLeave } =
    useCollapseDrawer();

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const fetchAppVersion = async () => {
    try {
      const response = await fetch(`${VERSION_ROOT}/version.json`);
      const appVersion = await response.json();
      setVersion(appVersion?.version);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchAppVersion();
  }, []);

  let mergedRouteAndPermission = SidebarConfig.map((route) => {
    permissions.forEach((permission) => {
      if (route.moduleName === permission.moduleName) {
        route = Object.assign(route, permission);
      }
    });
    return route;
  });

  let mergedSubmoduleAndPermission = mergedRouteAndPermission.map((route, i) => {
    route?.subModules.forEach((permission) => {
      route.items[0].children.map((item) => {
        if (item.name === permission.name) {
          item = Object.assign(item, permission);
        }

        return item;
      });
    });

    return route;
  });

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': {
          height: 1,
          display: 'flex',
          flexDirection: 'column'
        }
      }}
    >
      <Stack
        spacing={3}
        sx={{
          px: 2.5,
          pt: 3,
          pb: 2,
          ...(isCollapse && {
            alignItems: 'center'
          })
        }}
      >
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <MHidden width="lgDown">
            <IconCollapse onToggleCollapse={onToggleCollapse} collapseClick={collapseClick} />
          </MHidden>
          <Box
            component={RouterLink}
            to="/"
            sx={{
              display: 'inline-flex'
            }}
          >
            {!isCollapse && (
              <img
                alt="screen"
                src={'/static/brand/logo_full_ligth.png'}
                height="30px"
                width="140px"
              />
            )}
            {isCollapse && (
              <Box
                component="img"
                src="/static/brand/logo_min.svg"
                alt="Logout"
                sx={{ height: 25, width: 25, ml: 2 }}
              />
            )}
          </Box>
        </Stack>
      </Stack>

      <AdminNavSection navConfig={mergedSubmoduleAndPermission} isShown={!isCollapse} />

      <Box sx={{ flexGrow: 1 }} />
      {!isCollapse && (
        <>
          <Stack
            spacing={3}
            alignItems="center"
            sx={{ px: 5, pb: 5, mt: 10, width: 1, textAlign: 'center' }}
          >
            <Label color="primary">v{version}</Label>
          </Stack>
        </>
      )}
    </Scrollbar>
  );

  return (
    <StyledDashboardSidebarDiv
      sx={{
        width: {
          lg: isCollapse ? COLLAPSE_WIDTH : ADMIN_DRAWER_WIDTH
        },
        ...(collapseClick && {
          position: 'absolute'
        })
      }}
    >
      <MHidden width="lgUp">
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: ADMIN_DRAWER_WIDTH, bgcolor: 'background.dark' }
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>

      <MHidden width="lgDown">
        <Drawer
          open
          variant="persistent"
          onMouseEnter={onHoverEnter}
          onMouseLeave={onHoverLeave}
          PaperProps={{
            sx: {
              width: ADMIN_DRAWER_WIDTH,
              bgcolor: 'background.dark',
              borderRight: 0,
              ...(isCollapse && {
                width: COLLAPSE_WIDTH
              }),
              ...(collapseHover && {
                borderRight: 0,
                backdropFilter: 'blur(6px)',
                WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
                boxShadow: (theme) => theme.customShadows.z20,
                bgcolor: (theme) => alpha(theme.palette.background.dark, 0.88)
              })
            }
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>
    </StyledDashboardSidebarDiv>
  );
}
