import { createSlice } from '@reduxjs/toolkit';
// types
import { statisticsindicatorState } from 'types/statisticsindicator';

// ----------------------------------------------------------------------

const initialState: statisticsindicatorState = {
  isLoading: false,
  error: { statusCode: 0, message: '' },
  membershipApplicationsStatistic: null,
  memberSaeiStatistics: null,
  onlineCourseCertificationStatistic: null,
  certifyingAmiFormationsStatistic: null,
  labelingApplicationsStatistic: null,
  amiLabelStatistic: null,
  eventStatistic: {
    totals: 0,
    totalPublics: 0,
    totalPrivateds: 0
  },
  incubatedEntrepreneurStatistic: null,
  entrepreneurProjectsFundRaised: null,
  saeisProgramsStatistic: null,
  entrepreneurProjectTurnoverStatistic: null,
  entrepreneurProjectsMeetingsStatistic: {
    totals: 0,
    totalCloseds: 0,
    totalEnableds: 0
  },
  entrepreneurProjectsMeetingsHour: {
    totals: 0,
    totalCloseds: 0,
    totalEnableds: 0
  },
  validSaeisFilteredBySector: null,
  validSaeisFilteredByRegion: null
};

export const statisticsindicatorSlice = createSlice({
  name: 'statisticsindicator',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET MEMBERSHIP APPLICATIONS STATISTICS
    getMembershipApplicationsStatisticsSuccess(state, action) {
      state.isLoading = false;
      state.membershipApplicationsStatistic = action.payload;
    },

    // GET MEMBER SAEI STATISTICS
    getMemberSaeiStatisticsSuccess(state, action) {
      state.isLoading = false;
      state.memberSaeiStatistics = action.payload;
    },

    // GET ONLINE TRAINING CERTIFICATION STATISTICS
    getOnlineCourseCertificationStatisticSuccess(state, action) {
      state.isLoading = false;
      state.onlineCourseCertificationStatistic = action.payload;
    },

    // GET AMI FORMATION STATISTICS
    getCertifyingAmiFormationsStatisticSuccess(state, action) {
      state.isLoading = false;
      state.certifyingAmiFormationsStatistic = action.payload;
    },

    // GET LABELING APPLICATIONS STATISTICS
    getLabelingApplicationsStatisticSuccess(state, action) {
      state.isLoading = false;
      state.labelingApplicationsStatistic = action.payload;
    },

    // GET AMI LABEL STATISTICS
    getAmiLabelStatisticSuccess(state, action) {
      state.isLoading = false;
      state.amiLabelStatistic = action.payload;
    },

    getEventStatisticSuccess(state, action) {
      const { total, totalPublic, totalPrivated } = action.payload;
      state.isLoading = false;
      state.eventStatistic.totals = total;
      state.eventStatistic.totalPublics = totalPublic;
      state.eventStatistic.totalPrivateds = totalPrivated;
    },

    // GET INCUBATED ENTREPRENEUR STATISTICS
    getIncubatedEntrepreneurStatisticSuccess(state, action) {
      state.isLoading = false;
      state.incubatedEntrepreneurStatistic = action.payload;
    },

    getEntrepreneurProjectsFundRaisedSuccess(state, action) {
      state.isLoading = false;
      state.entrepreneurProjectsFundRaised = action.payload;
    },

    getSaeisProgramsStatisticSuccess(state, action) {
      state.isLoading = false;
      state.saeisProgramsStatistic = action.payload;
    },

    getEntrepreneurProjectTurnoverStatisticSuccess(state, action) {
      state.isLoading = false;
      state.entrepreneurProjectTurnoverStatistic = action.payload;
    },

    getEntrepreneurProjectsMeetingsStatisticSuccess(state, action) {
      const { total, totalClosed, totalEnabled } = action.payload;
      state.isLoading = false;
      state.entrepreneurProjectsMeetingsStatistic.totals = total;
      state.entrepreneurProjectsMeetingsStatistic.totalCloseds = totalClosed;
      state.entrepreneurProjectsMeetingsStatistic.totalEnableds = totalEnabled;
    },

    getEntrepreneurProjectsMeetingsHoursSuccess(state, action) {
      const { total, totalClosed, totalEnabled } = action.payload;
      state.isLoading = false;
      state.entrepreneurProjectsMeetingsHour.totals = total;
      state.entrepreneurProjectsMeetingsHour.totalCloseds = totalClosed;
      state.entrepreneurProjectsMeetingsHour.totalEnableds = totalEnabled;
    },

    getValidSaeisFilteredBySectorSuccess(state, action) {
      state.isLoading = false;
      state.validSaeisFilteredBySector = action.payload;
    },

    getValidSaeisFilteredByRegionSuccess(state, action) {
      state.isLoading = false;
      state.validSaeisFilteredByRegion = action.payload;
    }
  }
});

// Reducer
export default statisticsindicatorSlice.reducer;
