import { Icon } from '@iconify/react';
import menuFill from '@iconify/icons-eva/menu-fill';
// material
import { Box, Tooltip, CardActionArea } from '@mui/material';
// styles
import { CardActionStyle, BoxStyle } from './IconCollapse.style';
// types
import { IconCollapseProps } from 'types/iconCollapse';

// ----------------------------------------------------------------------

export function IconCollapse({ onToggleCollapse, collapseClick }: IconCollapseProps) {
  return (
    <Tooltip title="Mini Menu">
      <CardActionArea onClick={onToggleCollapse} sx={CardActionStyle}>
        <Box sx={BoxStyle}>
          <Icon icon={menuFill} height={28} width={28} />
        </Box>
      </CardActionArea>
    </Tooltip>
  );
}
