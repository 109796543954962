import { isArray } from 'lodash';
import toast from 'react-hot-toast';
import axios, { uploadFile } from 'utils/axios';
// redux
import { dispatch } from '../store';
import { userSlice } from '../slices/user';
import { createAsyncThunk } from '@reduxjs/toolkit';
// utils
import { FILE_TYPES, isAPngImage } from 'utils/fileExtension';
// types
import { updateUserPasswordData, userProfile } from 'types/user';

// ----------------------------------------------------------------------

export const userEmailValidation = createAsyncThunk(
  'entity/userEmailValidation',
  async (token: string, { rejectWithValue }) => {
    try {
      const response = await axios.put(`users/mail/verification?verified-user-token=${token}`);
      return response.data;
    } catch (error: any) {
      toast.error(`${isArray(error?.message) ? error?.message[0] : error?.message}`);
    }
  }
);

// ----------------------------------------------------------------------

export const sendForgotPasswordNotification = createAsyncThunk(
  'user/sendForgotPasswordNotification',
  async (userEmail: string, { rejectWithValue }) => {
    const response = await axios.put(`/users/${userEmail}/forgot/password`);
    return response.data;
  }
);

// ----------------------------------------------------------------------

export const resendUserAccess = createAsyncThunk(
  'user/resendUserAccess',
  async (userId: string, { rejectWithValue }) => {
    const response = await axios.post(`users/${userId}/access-resend`);
    return response.data;
  }
);

// ----------------------------------------------------------------------

export const updateUserProfile = createAsyncThunk(
  'entity/updateUserProfile',
  async (data: userProfile, { rejectWithValue }) => {
    try {
      const { userId, profileInformation } = data;
      const response = await axios.put(`users/${userId}/profile-information`, profileInformation);
      return response.data;
    } catch (error: any) {
      toast.error(`${isArray(error?.message) ? error?.message[0] : error?.message}`);
    }
  }
);

// ----------------------------------------------------------------------

export const updateUserPassword = createAsyncThunk(
  'entity/updateUserPassword',
  async (data: updateUserPasswordData, { rejectWithValue }) => {
    const { userId, passwords } = data;
    const response = await axios.put(`users/${userId}/password`, passwords);
    return response.data;
  }
);

// ----------------------------------------------------------------------

export function getUserProfileImage(key) {
  return async () => {
    dispatch(userSlice.actions.startLoading());
    try {
      const response = await uploadFile.get('s3-upload-file/stream', {
        params: { key },
        responseType: 'blob'
      });

      if (isAPngImage(key)) {
        const file = new Blob([response.data], {
          type: FILE_TYPES.PNG
        });
        const fileURL = URL.createObjectURL(file);
        dispatch(userSlice.actions.getProfileImageSuccess(fileURL));
      } else {
        const file = new Blob([response.data], { type: FILE_TYPES.JPEG });
        const fileURL = URL.createObjectURL(file);
        dispatch(userSlice.actions.getProfileImageSuccess(fileURL));
      }
    } catch (error) {
      dispatch(userSlice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getUserInformation(id) {
  return async () => {
    dispatch(userSlice.actions.startLoading());
    try {
      const response = await axios.get(`users/${id}`);
      dispatch(userSlice.actions.getUserInformationSuccess(response.data));
    } catch (error) {
      dispatch(userSlice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function uploadProfileImage(entityId: string, data: FormData) {
  return async () => {
    dispatch(userSlice.actions.startLoading());
    try {
      const response = await uploadFile.post(`s3-upload-file/single/${entityId}/public`, data);
      dispatch(userSlice.actions.uploadProfileImageSuccess(response.data));
    } catch (error) {
      dispatch(userSlice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------------
export function uploadProfileImages(entityId: string, data: FormData) {
  return async () => {
    dispatch(userSlice.actions.startLoading());
    try {
      const response = await uploadFile.post(`s3-upload-file/single/${entityId}/public`, data);
      dispatch(userSlice.actions.uploadProfileImageSuccess(response.data));
    } catch (error) {
      dispatch(userSlice.actions.hasError(error));
    }
  };
}
