// redux
import { dispatch } from '../store';
import { financeSlice } from '../slices/finance';
import { createAsyncThunk } from '@reduxjs/toolkit';
// utils
import axios, { uploadFile } from 'utils/axios';
import { FILE_TYPES } from 'utils/fileExtension';
// types
import {
  AssociationPaymentType,
  UpdateInvoiceStatusType,
  AssociationInvoiceFilteredType
} from 'types/finance';

// ----------------------------------------------------------------------

export const updateInvoiceStatus = createAsyncThunk(
  'invoice/updateInvoiceStatus',
  async (data: UpdateInvoiceStatusType, { rejectWithValue }) => {
    const { invoiceId, status } = data;
    const response = await axios.put(`invoices/${invoiceId}/change-status/${status}`);
    return response.data;
  }
);

// ----------------------------------------------------------------------

export const getInvoiceFile = createAsyncThunk(
  'invoice/getInvoiceFile',
  async (key: string, { rejectWithValue }) => {
    const response = await uploadFile.get(`s3-upload-file/stream`, {
      params: { key },
      responseType: 'blob'
    });
    const file = new Blob([response.data], { type: FILE_TYPES.PDF });
    const fileURL = URL.createObjectURL(file);
    window.open(fileURL);
  }
);

// ----------------------------------------------------------------------

export const resendInvoice = createAsyncThunk(
  'invoice/resendInvoice',
  async (invoiceId: number | string, { rejectWithValue }) => {
    const response = await axios.post(`/invoices/${invoiceId}/resend-invoice`);
    return response.data;
  }
);

// ----------------------------------------------------------------------

export const resendReceipt = createAsyncThunk(
  'invoice/resendReceipt',
  async (invoiceId: number | string, { rejectWithValue }) => {
    const response = await axios.post(`/invoices/${invoiceId}/resend-receipt`);
    return response.data;
  }
);

// ----------------------------------------------------------------------

export const makeAssociationPayment = createAsyncThunk(
  'invoice/makeAssociationPayment',
  async (data: AssociationPaymentType, { rejectWithValue }) => {
    const { invoiceId, invoiceData } = data;
    const response = await axios.put(`invoices/${invoiceId}/make-payment`, invoiceData);
    return response.data;
  }
);

// ----------------------------------------------------------------------

export function getAssociationInvoices() {
  return async () => {
    dispatch(financeSlice.actions.startLoading());
    try {
      const response = await axios.get(`invoices/saei`);

      const data = response.data.map((item) => ({
        id: item?.id,
        reference: item?.reference,
        entity: item?.entity?.name,
        date: item?.date,
        total_amount: item?.total_amount,
        subject: item?.subject,
        reminder_date: item?.reminder_date,
        status: item?.status
      }));

      dispatch(financeSlice.actions.getAssociationInvoicesSuccess(data));
    } catch (error) {
      dispatch(financeSlice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getAssociationInvoiceDetails(id: string) {
  return async () => {
    dispatch(financeSlice.actions.startLoading());
    try {
      const response = await axios.get(`invoices/${id}`);
      dispatch(financeSlice.actions.getAssociationInvoiceDetailsSuccess(response.data));
    } catch (error) {
      dispatch(financeSlice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getSaeiAssociationInvoicesHistory(id: string) {
  return async () => {
    dispatch(financeSlice.actions.startLoading());
    try {
      const response = await axios.get(`invoices/saei/${id}`);
      dispatch(financeSlice.actions.getSaeiInvoicesHistorySuccess(response.data));
    } catch (error) {
      dispatch(financeSlice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------

export function archiveAssociationInvoices(requestId) {
  return async () => {
    dispatch(financeSlice.actions.startLoading());
    try {
      await axios.post(`/invoices/archive-multiple`, requestId);
      await dispatch(getAssociationInvoices());
    } catch (error) {
      dispatch(financeSlice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getFilteredAssociationInvoices({
  entityId,
  params = { date: null, status: '', subjects: '', saeiIds: '' }
}: AssociationInvoiceFilteredType) {
  return async () => {
    dispatch(financeSlice.actions.startLoading());
    try {
      const response = await axios.get(`invoices/saei`, {
        params
      });

      const data = response.data.map((item) => ({
        id: item?.id,
        reference: item?.reference,
        entity: item?.entity?.name,
        date: item?.date,
        total_amount: item?.total_amount,
        subject: item?.subject,
        reminder_date: item?.reminder_date,
        status: item?.status
      }));

      dispatch(financeSlice.actions.getAssociationInvoicesSuccess(data));
    } catch (error) {
      dispatch(financeSlice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function uploadVoucher(entityId: string, data: FormData) {
  return async () => {
    dispatch(financeSlice.actions.startLoading());
    try {
      const response = await uploadFile.post(`s3-upload-file/single/${entityId}/private`, data);
      dispatch(financeSlice.actions.uploadVoucherSuccess(response.data));
    } catch (error) {
      dispatch(financeSlice.actions.hasError(error));
    }
  };
}
