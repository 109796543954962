import { Theme } from '@mui/material/styles';

// ----------------------------------------------------------------------

export default function ToggleButton(theme: Theme) {
  return {
    MuiToggleButton: {
      styleOverrides: {
        root: {
          height: 34,
          minWidth: 70,
          '&.Mui-selected': {
            color: theme.palette.common.white,
            backgroundColor: theme.palette.primary.main
          },
          '&.Mui-disabled': {}
        }
      }
    }
  };
}
