// redux
import { createSlice } from '@reduxjs/toolkit';
import { createCollaborator, updateMultipleProfileStatus } from 'state/thunks/team.thunk';
// types
import { TeamState } from 'types/team';

// ----------------------------------------------------------------------

const initialState: TeamState = {
  isLoading: false,
  error: { statusCode: 0, message: '' },
  collaborator: null,
  collaborators: []
};

export const teamSlice = createSlice({
  name: 'team',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET ALL COLLABORATORS
    getCollaboratorsSuccess(state, action) {
      state.isLoading = false;
      state.collaborators = action.payload;
    },

    // GET COLLABORATOR PROFILE
    getCollaboratorProfileSuccess(state, action) {
      state.isLoading = false;
      state.collaborator = action.payload;
    }
  },

  extraReducers: (builder) => {
    // CREATE COLLABORATOR
    builder.addCase(createCollaborator.fulfilled, (state, action) => {
      state.isLoading = false;
      state.collaborator = action.payload;
    });
    // UPDATE MULTIPLE PROFILE STATUS IS PENDING
    builder.addCase(updateMultipleProfileStatus.pending, (state, action) => {
      state.isLoading = true;
    });
    // UPDATE MULTIPLE PROFILE STATUS IS FULFILLED
    builder.addCase(updateMultipleProfileStatus.fulfilled, (state, action) => {
      state.isLoading = false;
    });
    // UPDATE MULTIPLE PROFILE STATUS IS REJECTED
    builder.addCase(updateMultipleProfileStatus.rejected, (state, action) => {
      state.isLoading = false;
    });
  }
});

// Reducer
export default teamSlice.reducer;
