import { isArray } from 'lodash';
import toast from 'react-hot-toast';
// redux
import { dispatch } from '../store';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { entitySlice } from '../slices/entity';
// utils
import axios, { uploadFile } from 'utils/axios';
// types
import { UpdateEntityCurrency, CreateEntity as createEntityType } from 'types/entity';
import { FILE_TYPES, isAPngImage } from 'utils/fileExtension';

// ----------------------------------------------------------------------

export const createEntity = createAsyncThunk(
  'entity/createEntity',
  async (data: createEntityType, { rejectWithValue }) => {
    try {
      const response = await axios.post('entities/saei', data);
      return response.data;
    } catch (error: any) {
      toast.error(`${isArray(error?.message) ? error?.message[0] : error?.message}`);
    }
  }
);

// ----------------------------------------------------------------------

export const updateEntityCurrency = createAsyncThunk(
  'entity/updateEntityCurrency',
  async (data: UpdateEntityCurrency, { rejectWithValue }) => {
    const { entityId, currencyCode } = data;
    const response = await axios.put(`currencies/${currencyCode}/entity/${entityId}`);
    return response.data;
  }
);

// ----------------------------------------------------------------------

export const updateEntity = createAsyncThunk(
  'entity/updateEntity',
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await axios.put(`entities/saei/${data?.id}`, data);
      return response.data;
    } catch (error: any) {
      toast.error(`${isArray(error?.errors) ? error?.errors[0] : error?.errors}`);
    }
  }
);

// ----------------------------------------------------------------------

export const updateEntityLabel = createAsyncThunk(
  'entity/updateEntityLabel',
  async (data: any, { rejectWithValue }) => {
    const { id, entityData } = data;
    try {
      const response = await axios.put(`entities/${id}`, entityData);
      return response.data;
    } catch (error: any) {
      toast.error(`${isArray(error?.errors) ? error?.errors[0] : error?.errors}`);
    }
  }
);

// ----------------------------------------------------------------------

export const uploadMembershipDocuments = createAsyncThunk(
  'entity/uploadMembershipDocuments',
  async (data: any, { rejectWithValue }) => {
    const { entityId, files } = data;
    const response = await axios.post(`entities/saei/membership-documents/${entityId}`, files);
    return response.data;
  }
);

// ----------------------------------------------------------------------

export const updateEntityLabelData = createAsyncThunk(
  'entity/updateEntityLabelData',
  async (data: any, { rejectWithValue }) => {
    const { entityId, labelData } = data;
    const response = await axios.put(`entities/${entityId}/label-data`, labelData);
    return response.data;
  }
);

// ----------------------------------------------------------------------

export const savePaymentReceipt = createAsyncThunk(
  'entity/uploadPaymentReceipt',
  async (data: any, { rejectWithValue }) => {
    const { entityId, file } = data;
    const response = await axios.post(`entities/saei/Payment-receipt/${entityId}`, file);
    return response.data;
  }
);

// ----------------------------------------------------------------------

export const disabledEntityAccount = createAsyncThunk(
  'entity/disabledEntityAccount',
  async (data: { entityId: string; status: string }, { rejectWithValue }) => {
    const { entityId, status } = data;
    const response = await axios.put(`/entities/saei/${entityId}/change-account-status/${status}`);
    return response.data;
  }
);

// ----------------------------------------------------------------------

export const uploadMembershipPaydunyaPaymentReceipt = createAsyncThunk(
  'entity/uploadMembershipPaydunyaPaymentReceipt',
  async (data: any, { rejectWithValue }) => {
    const { entityId, paydunyaReceipt } = data;

    const response = await axios.post(
      `entities/saei/payment-receipt/${entityId}/paydunya`,
      paydunyaReceipt
    );
    return response.data;
  }
);

// ----------------------------------------------------------------------

export const removeSaeiLabel = createAsyncThunk(
  'entity/removeSaeiLabel',
  async (entityId: string, { rejectWithValue }) => {
    const response = await axios.put(`entities/${entityId}/unlabeling`);
    return response.data;
  }
);

// ----------------------------------------------------------------------

export function uploadLogo(entityId: string, data: FormData) {
  return async () => {
    dispatch(entitySlice.actions.startLoading());
    try {
      const response = await uploadFile.post(`s3-upload-file/single/${entityId}/public`, data);
      dispatch(entitySlice.actions.uploadLogoSuccess(response.data));
    } catch (error) {
      dispatch(entitySlice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function uploadLegalExistence(entityId: string, data: FormData) {
  return async () => {
    dispatch(entitySlice.actions.startLoading());
    try {
      const response = await uploadFile.post(`s3-upload-file/single/${entityId}/private`, data);
      dispatch(entitySlice.actions.uploadLegalExistenceSuccess(response.data));
    } catch (error) {
      dispatch(entitySlice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function uploadDealSupport(entityId: string, data: FormData) {
  return async () => {
    dispatch(entitySlice.actions.hasSubmittedDealSupport());
    try {
      const response = await uploadFile.post(`s3-upload-file/multiple/${entityId}/private`, data);
      dispatch(entitySlice.actions.uploadDealSupportSuccess(response.data));
    } catch (error) {
      dispatch(entitySlice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function uploadPhysicalPremises(entityId: string, data: FormData) {
  return async () => {
    dispatch(entitySlice.actions.hasSubmittedPhysicalPremises());
    try {
      const response = await uploadFile.post(`s3-upload-file/multiple/${entityId}/private`, data);
      dispatch(entitySlice.actions.uploadPhysicalPremisesSuccess(response.data));
    } catch (error) {
      dispatch(entitySlice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function uploadCv(entityId: string, data: FormData) {
  return async () => {
    dispatch(entitySlice.actions.hasSubmittedCv());
    try {
      const response = await uploadFile.post(`s3-upload-file/multiple/${entityId}/private`, data);
      dispatch(entitySlice.actions.uploadCvSuccess(response.data));
    } catch (error) {
      dispatch(entitySlice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function uploadMembershipCharter(entityId: string, data: FormData) {
  return async () => {
    dispatch(entitySlice.actions.hasSubmittedMembershipCharter());
    try {
      const response = await uploadFile.post(`s3-upload-file/single/${entityId}/private`, data);
      dispatch(entitySlice.actions.uploadMembershipCharterSuccess(response.data));
    } catch (error) {
      dispatch(entitySlice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function uploadPaymentReceipt(entityId: string, data: FormData) {
  return async () => {
    dispatch(entitySlice.actions.hasSubmittedPaymentReceipt());
    try {
      const response = await uploadFile.post(`s3-upload-file/single/${entityId}/private`, data);
      dispatch(entitySlice.actions.uploadPaymentReceiptSuccess(response.data));
    } catch (error) {
      dispatch(entitySlice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function uploadMembershipBulletin(entityId: string, data: FormData) {
  return async () => {
    dispatch(entitySlice.actions.hasSubmittedMembershipBulletin());
    try {
      const response = await uploadFile.post(`s3-upload-file/single/${entityId}/private`, data);
      dispatch(entitySlice.actions.uploadMembershipBulletinSuccess(response.data));
    } catch (error) {
      dispatch(entitySlice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function uploadMembershipInvoice(entityId: string, data: FormData) {
  return async () => {
    dispatch(entitySlice.actions.hasSubmittedMembershipInvoice());
    try {
      const response = await uploadFile.post(`s3-upload-file/single/${entityId}/private`, data);
      dispatch(entitySlice.actions.uploadMembershipInvoiceSuccess(response.data));
    } catch (error) {
      dispatch(entitySlice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getSaeiLogo(key: string) {
  return async () => {
    dispatch(entitySlice.actions.startLoading());
    try {
      const response = await uploadFile.get('s3-upload-file/stream', {
        params: { key },
        responseType: 'blob'
      });

      if (isAPngImage(key)) {
        const file = new Blob([response.data], {
          type: FILE_TYPES.PNG
        });
        const fileURL = URL.createObjectURL(file);
        dispatch(entitySlice.actions.getSaeiLogoSuccess(fileURL));
      } else {
        const file = new Blob([response.data], { type: FILE_TYPES.JPEG });
        const fileURL = URL.createObjectURL(file);
        dispatch(entitySlice.actions.getSaeiLogoSuccess(fileURL));
      }
    } catch (error) {
      dispatch(entitySlice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getEntityDetails(id: string) {
  return async () => {
    dispatch(entitySlice.actions.startLoading());
    try {
      const response = await axios.get(`${'entities/saei'}/${id}`);
      dispatch(entitySlice.actions.getEntityDetailsSuccess(response.data));
    } catch (error) {
      dispatch(entitySlice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getEntityRoles(id: string) {
  return async () => {
    dispatch(entitySlice.actions.startLoading());
    try {
      const response = await axios.get(`${'roles'}`, {
        params: { 'entity-id': id }
      });
      dispatch(entitySlice.actions.getEntityRolesSuccess(response.data));
    } catch (error) {
      dispatch(entitySlice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------

export function getSaeisInSettlementAndValidated() {
  return async () => {
    dispatch(entitySlice.actions.startLoading());
    try {
      const response = await axios.get(`entities`);
      dispatch(entitySlice.actions.getSaeisInSettlementAndValidatedSuccess(response.data));
    } catch (error) {
      dispatch(entitySlice.actions.hasError(error));
    }
  };
}
