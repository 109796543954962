import { createSlice } from '@reduxjs/toolkit';
// types
import { EntrepreneurInvoices } from 'types/entrepreneurInvoices';

// ----------------------------------------------------------------------

const initialState: EntrepreneurInvoices = {
  isLoading: false,
  error: { statusCode: 0, message: '' },
  entrepreneurInvoicesList: [],
  entrepreneurInvoicesDetail: null
};

export const entrepreneurInvoicesSlice = createSlice({
  name: 'entrepreneurInvoices',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET ENTREPRENEURS INVOICES
    getEntrepreneurInvoiceSuccess(state, action) {
      state.isLoading = false;
      state.entrepreneurInvoicesList = action.payload;
    },

    // GET ENTREPRENEURS INVOICES
    getEntrepreneurInvoiceDetailSuccess(state, action) {
      state.isLoading = false;
      state.entrepreneurInvoicesDetail = action.payload;
    }
  }
});

// Reducer
export default entrepreneurInvoicesSlice.reducer;
