export const MAX_FILE_UPLOAD_SIZE = 2097152;
export const DEFAULT_DATE_FORMAT = 'dd/MM/yyyy';
export const TODAY = new Date();
export const XOF = 'XOF';
export const LIGHT = 'light';
export const FR = 'fr';
export const LANG_STORAGE = localStorage.getItem('i18nextLng');
export const colorOptions = [
  '#FCC300',
  '#00AB55',
  '#1890FF',
  '#94D82D',
  '#FF4842',
  '#04297A',
  '#7A0C2E'
];

export const SAEI = 'SAEI';
export enum INVITATION_STATUS {
  ACCEPTED = 'accepted',
  REFUSED = 'refused'
}
export const USER_TYPES = {
  ADMIN: 'Africinnov'
};
export const defaultCountryId = 1;
export const defaultGender = 'female';
export const WEBSITE_VALIDATION =
  /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/;
export const PASSWORD_VALIDATION =
  /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&"^:;<>,.{}()\[\]~_+-\/\\=|])[A-Za-z\d@$!%*#?&"^:;<>,.(){}\[\]~_+-\/\\=|]{8,}$/;
export const USER_ROLES = {
  SUPPORT_OFFICER: 'expert SAEI',
  CONTRACTOR: 'entrepreneur'
};
export const APP_COLOR_BY_USER_ROLES = {
  SUPPORT_OFFICER_PRIMARY: '#212C7F',
  SUPPORT_OFFICER_SECONDARY: '#2E43DB12',
  CONTRACTORS_PRIMARY: '#2E9EDB',
  CONTRACTORS_SECONDARY: '#2E9EDB19'
};
export const EXTERNAL = 'external';
export const ENTITY_TYPES = {
  AFRICINNOV: 'Africinnov',
  SAEI: 'SAEI'
};
export const AMI_LABELLING_FORM_ELIGIBLE_SECTION_TITLE = 'eligible';
export enum INPUT_TYPE {
  TEXT = 'text',
  TEXT_AREA = 'textarea',
  CHECKBOX = 'checkbox',
  RADIO = 'radio',
  SELECT = 'select',
  COUNTRIES = 'countries',
  DATE = 'date',
  FILE = 'file',
  TABLE = 'table'
}
export const INPUT_TYPE_LABEL = [
  { id: 'text', label: 'shortTextField' },
  { id: 'textarea', label: 'longTextField' },
  { id: 'select', label: 'dropdownList' },
  { id: 'radio', label: 'singleChoice' },
  { id: 'checkbox', label: 'multipleChoice' },
  { id: 'date', label: 'date' },
  { id: 'file', label: 'file' }
];
export const BASE_URL = window.location.origin;
export const enum LABELING {
  FEES = 500000,
  QUANTITY = 1
}
export const enum MEMBERSHIP_APPLICATION {
  FEES = 50000,
  QUANTITY = 1
}
export const ROOT = 'ROOT';
export const ADMIN = 'ADMIN';
