import { createSlice } from '@reduxjs/toolkit';

const initialState: any = {
  isLoading: false,
  error: false,
  invoiceSaei: [],
  invoiceSaeiDetails: null,
  saeiInvoicesHistory: []
};

export const invoiceSaeiSlice = createSlice({
  name: 'invoiceSaei',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    //HAS ERROR
    hasError(state, action) {
      state.error = false;
      state.error = action.payload;
    },

    // GET SAEI INVOICE
    getSaeiInvoicesSuccess(state, action) {
      state.isLoading = false;
      state.invoiceSaei = action.payload;
    },

    // GET SAEI INVOICE DETAILS
    getSaeiInvoiceDetailsSuccess(state, action) {
      state.isLoading = false;
      state.invoiceSaeiDetails = action.payload;
    },

    // GET SAEI INVOICE HISTORY
    getSaeiInvoicesHistorySuccess(state, action) {
      state.isLoading = false;
      state.saeiInvoicesHistory = action.payload;
    }
  }
});

// Reducer
export default invoiceSaeiSlice.reducer;
