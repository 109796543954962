import { createSlice } from '@reduxjs/toolkit';
// types
import { DashboardState } from 'types/dashboard';

// ----------------------------------------------------------------------

const initialState: DashboardState = {
  isLoading: false,
  error: { statusCode: 0, message: '' },
  saeiAdminDashboard: null,
  adminAiDashboard: null,
  adminEntrepreneurDashboard: null,
  adminExpertSaeiDashboard: null
};

export const dashboard = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET SAEI ADMIN DASHBOARD
    getAllSaeiAdminDashboardSuccess(state, action) {
      state.isLoading = false;
      state.saeiAdminDashboard = action.payload;
    },

    // Get ADMIN AI DASHBOARD
    getAllAfrikinnovDashboardSuccess(state, action) {
      state.isLoading = false;
      state.adminAiDashboard = action.payload;
    },

    // Get ADMIN ENTREPRENEUR DASHBOARD
    getAllEntrepreneurDashboardSuccess(state, action) {
      state.isLoading = false;
      state.adminEntrepreneurDashboard = action.payload;
    },

    // Get ADMIN EXPERT SAEI DASHBOARD
    getAllExpertSaeiDashboardSuccess(state, action) {
      state.isLoading = false;
      state.adminExpertSaeiDashboard = action.payload;
    }
  }
});

// Reducer
export default dashboard.reducer;
