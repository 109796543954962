export const CardActionStyle = {
  width: 30,
  left: 10,
  height: 30,
  display: 'flex',
  borderRadius: '5px',
  justifyContent: 'center',
  cursor: 'pointer',
  alignItems: 'center',
  color: 'primary.main'
};

export const BoxStyle = {
  width: 40,
  height: 40,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  transition: (theme) => theme.transitions.create('all')
};
