import { isArray } from 'lodash';
import toast from 'react-hot-toast';
// redux
import { dispatch } from '../store';
import { teamSlice } from 'state/slices/team';
import { createAsyncThunk } from '@reduxjs/toolkit';
// utils
import axios from 'utils/axios';
// types
import { NewCollaborator, UpdateProfileStatusType } from 'types/team';

// ----------------------------------------------------------------------

export const createCollaborator = createAsyncThunk(
  'team/createCollaborator',
  async (data: NewCollaborator, { rejectWithValue }) => {
    const { entityId, collaboratorData } = data;

    try {
      const response = await axios.post(`entities/${entityId}/users/create`, collaboratorData);
      return response.data;
    } catch (error: any) {
      toast.error(`${isArray(error?.message) ? error?.message[0] : error?.message}`);
    }
  }
);

// ----------------------------------------------------------------------

export const updateProfileStatus = createAsyncThunk(
  'team/UpdateProfileStatus',
  async (data: UpdateProfileStatusType, { rejectWithValue }) => {
    const { profileId, status } = data;
    const response = await axios.put(`profiles/${profileId}/change-status/${status}`);
    return response.data;
  }
);

// ----------------------------------------------------------------------

type sendProfileAccessNotificationType = {
  profileId: string;
  roleId: string;
};
export const sendProfileAccessNotification = createAsyncThunk(
  'team/sendProfileAccessNotification',
  async (data: sendProfileAccessNotificationType, { rejectWithValue }) => {
    const { profileId, roleId } = data;
    const response = await axios.post(`profiles/${profileId}/roles/${roleId}`);
    return response.data;
  }
);

// ----------------------------------------------------------------------
type UpdateMultipleProfileData = {
  status: string;
  requestId: any;
};

export const updateMultipleProfileStatus = createAsyncThunk(
  'team/updateMultipleProfileStatus',
  async (data: UpdateMultipleProfileData, { rejectWithValue }) => {
    const { status, requestId } = data;
    const response = await axios.put(`profiles/change-status/${status}`, requestId);
    return response.data;
  }
);

// ----------------------------------------------------------------------

export function getCollaborators(entityId: string) {
  return async () => {
    dispatch(teamSlice.actions.startLoading());
    try {
      const response = await axios.get(`entities/${entityId}/users/list`);

      const data = response.data.map((item) => ({
        ...item,
        firstName: item?.user?.firstName,
        lastName: item?.user?.lastName,
        email: item?.user?.email,
        creationDate: item?.user?.date,
        job: item?.user?.job,
        role: item?.role?.name,
        status: item?.status
      }));

      dispatch(teamSlice.actions.getCollaboratorsSuccess(data));
    } catch (error) {
      dispatch(teamSlice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getAllEntityCollaborators(entityId: string) {
  return async () => {
    dispatch(teamSlice.actions.startLoading());
    try {
      const response = await axios.get(`entities/${entityId}/users/list?role=all`);

      const data = response.data.map((item) => ({
        ...item,
        firstName: item?.user?.firstName,
        lastName: item?.user?.lastName,
        email: item?.user?.email,
        creationDate: item?.user?.date,
        job: item?.user?.job,
        role: item?.role?.name,
        status: item?.status
      }));

      dispatch(teamSlice.actions.getCollaboratorsSuccess(data));
    } catch (error) {
      dispatch(teamSlice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getCollaboratorProfile(profileId: string) {
  return async () => {
    dispatch(teamSlice.actions.startLoading());
    try {
      const response = await axios.get(`profiles/${profileId}`);
      dispatch(teamSlice.actions.getCollaboratorProfileSuccess(response.data));
    } catch (error) {
      dispatch(teamSlice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getCollaboratorsFiltered({ entityId, params = { role: '', status: '' } }) {
  return async () => {
    dispatch(teamSlice.actions.startLoading());
    try {
      const response = await axios.get(`entities/${entityId}/users/list`, {
        params
      });

      const data = response.data.map((item) => ({
        ...item,
        firstName: item?.user?.firstName,
        lastName: item?.user?.lastName,
        email: item?.user?.email,
        creationDate: item?.user?.date,
        job: item?.user?.job,
        role: item?.role?.name,
        status: item?.status
      }));

      dispatch(teamSlice.actions.getCollaboratorsSuccess(data));
    } catch (error) {
      dispatch(teamSlice.actions.hasError(error));
    }
  };
}
