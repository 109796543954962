// redux
import { createSlice } from '@reduxjs/toolkit';
import {
  createEntity,
  updateEntity,
  uploadMembershipDocuments,
  savePaymentReceipt
} from '../thunks/entity.thunk';
// types
import { EntityState } from 'types/entity';

// ----------------------------------------------------------------------

const initialState: EntityState = {
  isLoading: false,
  error: { statusCode: 0, message: '' },
  roles: [],
  saeis: [],
  entityData: null,
  fileKey: '',
  membershipDocuments: null,
  receiptPaymentUploadResponse: null,
  entityDetails: null,
  saeiLogo: null,
  goToStep: false,
  submittingFile: {
    isCVSubmitted: false,
    isDealSupportSubmitted: false,
    isPaymentReceiptSubmitted: false,
    isPhysicalPremisesSubmitted: false,
    isMembershipCharterSubmitted: false,
    isMembershipBulletinSubmitted: false,
    isMembershipInvoiceSubmitted: false,
    logo: null,
    legalExistence: null,
    dealSupport: null,
    physicalPremises: null,
    cv: null,
    membershipBulletin: null,
    membershipInvoice: null,
    membershipCharter: null,
    paymentReceipt: null
  }
};

export const entitySlice = createSlice({
  name: 'entity',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // HAS SUBMITTED RESUME
    hasSubmittedCv(state) {
      state.submittingFile.isCVSubmitted = !state.submittingFile.isCVSubmitted;
    },

    // HAS SUBMITTED RESUME
    hasSubmittedMembershipCharter(state) {
      state.submittingFile.isMembershipCharterSubmitted =
        !state.submittingFile.isMembershipCharterSubmitted;
    },

    // HAS SUBMITTED PAYMENT RECEIPT
    hasSubmittedPaymentReceipt(state) {
      state.submittingFile.isPaymentReceiptSubmitted =
        !state.submittingFile.isPaymentReceiptSubmitted;
    },

    // HAS SUBMITTED RESUME
    hasSubmittedMembershipBulletin(state) {
      state.submittingFile.isMembershipBulletinSubmitted =
        !state.submittingFile.isMembershipBulletinSubmitted;
    },

    // HAS SUBMITTED INVOICE
    hasSubmittedMembershipInvoice(state) {
      state.submittingFile.isMembershipInvoiceSubmitted = true;
    },

    // HAS SUBMITTED DEAL SUPPORT
    hasSubmittedDealSupport(state) {
      state.submittingFile.isDealSupportSubmitted = !state.submittingFile.isDealSupportSubmitted;
    },

    // HAS SUBMITTED LEGAL EXISTENCE
    hasSubmittedPhysicalPremises(state) {
      state.submittingFile.isPhysicalPremisesSubmitted =
        !state.submittingFile.isPhysicalPremisesSubmitted;
    },

    // UPLOAD LOGO
    uploadLogoSuccess(state, action) {
      state.isLoading = false;
      state.submittingFile.logo = action.payload;
    },

    // GET ENTITY ROLES
    getEntityRolesSuccess(state, action) {
      state.isLoading = false;
      state.roles = action.payload;
    },

    // GET  SAEI IN SETTLEMENT AND VALIDATED
    getSaeisInSettlementAndValidatedSuccess(state, action) {
      state.isLoading = false;
      state.saeis = action.payload;
    },
    // GET FILE KEY
    getFileKeySuccess(state, action) {
      state.isLoading = false;
      state.fileKey = action.payload;
    },

    // UPLOAD LEGAL EXISTENCE
    uploadLegalExistenceSuccess(state, action) {
      state.isLoading = false;
      state.submittingFile.legalExistence = action.payload;
    },

    // UPLOAD DEAL SUPPORT
    uploadDealSupportSuccess(state, action) {
      state.submittingFile.isDealSupportSubmitted = false;
      state.submittingFile.dealSupport = action.payload;
    },

    // UPLOAD PHYSICAL PREMISES
    uploadPhysicalPremisesSuccess(state, action) {
      state.submittingFile.isPhysicalPremisesSubmitted = false;
      state.submittingFile.physicalPremises = action.payload;
    },

    // UPLOAD CV
    uploadCvSuccess(state, action) {
      state.submittingFile.isCVSubmitted = false;
      state.submittingFile.cv = action.payload;
    },

    // UPLOAD MEMBERSHIP CHARTER
    uploadMembershipCharterSuccess(state, action) {
      state.submittingFile.isMembershipCharterSubmitted = false;
      state.submittingFile.membershipCharter = action.payload;
    },

    // UPLOAD PAYMENT RECEIPT
    uploadPaymentReceiptSuccess(state, action) {
      state.submittingFile.isPaymentReceiptSubmitted = false;
      state.submittingFile.paymentReceipt = action.payload;
    },

    // UPLOAD MEMBERSHIP BULLETIN
    uploadMembershipBulletinSuccess(state, action) {
      state.submittingFile.isMembershipBulletinSubmitted = false;
      state.submittingFile.membershipBulletin = action.payload;
    },

    // UPLOAD MEMBERSHIP INVOICE
    uploadMembershipInvoiceSuccess(state, action) {
      state.submittingFile.isMembershipInvoiceSubmitted = false;
      state.submittingFile.membershipInvoice = action.payload;
    },

    // GET ENTITY DETAILS
    getEntityDetailsSuccess(state, action) {
      state.isLoading = false;
      state.entityDetails = action.payload;
    },

    // GET SAEI LOGO
    getSaeiLogoSuccess(state, action) {
      state.isLoading = false;
      state.saeiLogo = action.payload;
    },

    // GO TO THE FORM SUBMISSION STEP
    gotToSubmissionStep(state) {
      state.goToStep = !state.goToStep;
    },

    // RESET STATE
    resetEntityState: () => initialState
  },

  extraReducers: (builder) => {
    //CREATE ENTITY
    builder.addCase(createEntity.fulfilled, (state, action) => {
      state.isLoading = false;
      state.entityData = action.payload;
    });

    //UPDATE ENTITY
    builder.addCase(updateEntity.fulfilled, (state, action) => {
      state.isLoading = false;
      state.entityData = action.payload;
    });

    // UPLOAD MEMBERSHIP DOCUMENTS
    builder.addCase(uploadMembershipDocuments.fulfilled, (state, action) => {
      state.isLoading = false;
      state.membershipDocuments = action.payload;
    });

    // UPLOAD PAYMENT RECEIPT
    builder.addCase(savePaymentReceipt.fulfilled, (state, action) => {
      state.isLoading = false;
      state.receiptPaymentUploadResponse = action.payload;
    });
  }
});

// Reducer
export default entitySlice.reducer;

// Actions
export const {
  hasSubmittedCv,
  hasSubmittedDealSupport,
  hasSubmittedPhysicalPremises,
  hasSubmittedPaymentReceipt,
  gotToSubmissionStep,
  resetEntityState,
  getFileKeySuccess
} = entitySlice.actions;
