export enum STATUS {
  ANA = 'ANA',
  CA = 'CA',
  SIG = 'SIG',
  REG = 'REG',
  VAL = 'VAL',
  ICP = 'ICP',
  REJ = 'REJ',
  AJR = 'AJR',
  ANN = 'ANN',
  ACH = 'ACH',
  CRT = 'CRT',
  DRAFT = 'draft',
  ALUMNI = 'alumni',
  CLOSED = 'closed',
  REJ_CA = 'REJ_CA',
  ICP_CA = 'ICP_CA',
  ICP_ANA = 'ICP_ANA',
  REJ_ANA = 'REJ_ANA',
  ICP_SIG = 'ICP_SIG',
  REJ_SIG = 'REJ_SIG',
  ICP_REG = 'ICP_REG',
  REJ_REG = 'REJ_REG',
  AJR_SIG = 'AJR_SIG',
  AJR_REG = 'AJR_REG',
  AJR_ICP = 'AJR_ICP',
  SIG_VAL = 'SIG_VAL',
  REG_VAL = 'REG_VAL',
  ENABLED = 'enabled',
  DISABLED = 'disabled',
  ARCHIVED = 'archived',
  PUBLISHED = 'published',
  VALIDATED = 'validated',
  REFUSED = 'refused',
  ANALYZING = 'analyzing',
  MEETING = 'meeting',
  INCOMPLETE = 'incomplete',
  FIRST_ANALYSIS = 'first-analysis',
  SECOND_ANALYSIS = 'second-analysis',
  PRE_ASSESSMENT = 'pre-assessment',
  PAYMENT = 'payment',
  FIELD_TRIP = 'field-trip',
  COMMITTEE = 'committee',
  FIRST_ANALYSIS_VALIDATED = 'first-analysis-validated',
  FIRST_ANALYSIS_INCOMPLETE = 'first-analysis-incomplete',
  SECOND_ANALYSIS_VALIDATED = 'second-analysis-validated',
  SECOND_ANALYSIS_INCOMPLETE = 'second-analysis-incomplete',
  PRE_ASSESSMENT_VALIDATED = 'pre-assessment-validated',
  PRE_ASSESSMENT_REJECTED = 'pre-assessment-rejected',
  PAYMENT_VALIDATED = 'payment-validated',
  PAYMENT_REJECTED = 'payment-rejected',
  PAYMENT_RELAUNCHED = 'payment-relaunched',
  FIELD_TRIP_VALIDATED = 'field-trip-validated',
  FIELD_TRIP_REJECTED = 'field-trip-rejected',
  COMMITTEE_VALIDATED = 'committee-validated',
  COMMITTED_REJECTED = 'committee-rejected',
  PENDING = 'pending',
  CANCELLED = 'cancelled',
  COMPLETED = 'completed',
  FAILED = 'failed',
  SUCCEED = 'succeed',
  DEFAULT = 'default',
  LABELED = 'labeled',
  RENEWALS_INCOMPLETE = 'renewal-incomplete',
  RENEWALS_COMPLETE = 'renewal-complete',
  RENEWALS_VALIDATED = 'renewal-validated',
  RENEWAL_REJECTED = 'renewal-rejected',
  RENEWAL_SUSPENDED = 'renewal-suspended',
  SUSPENDED = 'suspended',
  SUBMITTED = 'submitted'
}
