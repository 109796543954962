import { map } from 'lodash';
import { createSlice, current } from '@reduxjs/toolkit';
// types
import { RoleAndPermissionState } from 'types/roleAndPermission';

// ----------------------------------------------------------------------

const initialState: RoleAndPermissionState = {
  isLoading: false,
  error: { statusCode: 0, message: '' },
  allModule: {
    isReadOnly: false,
    canEdit: false,
    isHidden: false
  },
  roleDetails: null,
  permission: [
    {
      moduleName: 'administration',
      isReadOnly: false,
      canEdit: false,
      isHidden: false,
      subModules: [
        { name: 'profile', isReadOnly: false, canEdit: false, isHidden: false },
        { name: 'roleAndPermission', isReadOnly: false, canEdit: false, isHidden: false },
        { name: 'team', isReadOnly: false, canEdit: false, isHidden: false },
        { name: 'meeting', isReadOnly: false, canEdit: false, isHidden: false },
        { name: 'africinnovAssociation', isReadOnly: false, canEdit: false, isHidden: false }
      ]
    },
    {
      moduleName: 'finance',
      isReadOnly: false,
      canEdit: false,
      isHidden: false,
      subModules: [
        { name: 'africinnovInvoiceAndPayment', isReadOnly: false, canEdit: false, isHidden: false },
        { name: 'invoicesPaymentsContractor', isReadOnly: false, canEdit: false, isHidden: false }
      ]
    },
    {
      moduleName: 'coachingProgram',
      isReadOnly: false,
      canEdit: false,
      isHidden: false,
      subModules: [
        { name: 'programs', isReadOnly: false, canEdit: false, isHidden: false },
        { name: 'callForApplication', isReadOnly: false, canEdit: false, isHidden: false },
        { name: 'entrepreneur', isReadOnly: false, canEdit: false, isHidden: false },
        { name: 'entrepreneurProject', isReadOnly: false, canEdit: false, isHidden: false },
        { name: 'supportManager', isReadOnly: false, canEdit: false, isHidden: false },
        { name: 'didacticTool', isReadOnly: false, canEdit: false, isHidden: false }
      ]
    },
    {
      moduleName: 'formationAndResource',
      isReadOnly: false,
      canEdit: false,
      isHidden: false,
      subModules: [
        { name: 'onlineCourse', isReadOnly: false, canEdit: false, isHidden: false },
        { name: 'resourceCenter', isReadOnly: false, canEdit: false, isHidden: false },
        { name: 'certificationPath', isReadOnly: false, canEdit: false, isHidden: false }
      ]
    },
    {
      moduleName: 'administrationAi',
      isReadOnly: false,
      canEdit: false,
      isHidden: false,
      subModules: [
        { name: 'roleAndPermission', isReadOnly: false, canEdit: false, isHidden: false },
        { name: 'team', isReadOnly: false, canEdit: false, isHidden: false },
        { name: 'africinnovAssociation', isReadOnly: false, canEdit: false, isHidden: false }
      ]
    },
    {
      moduleName: 'financeAi',
      isReadOnly: false,
      canEdit: false,
      isHidden: false,
      subModules: [
        { name: 'africinnovInvoiceAndPayment', isReadOnly: false, canEdit: false, isHidden: false },
        {
          name: 'africinnovInvoiceAndPaymentTraining',
          isReadOnly: false,
          canEdit: false,
          isHidden: false
        }
      ]
    },
    {
      moduleName: 'saeiAi',
      isReadOnly: false,
      canEdit: false,
      isHidden: false,
      subModules: [
        { name: 'membershipApplication', isReadOnly: false, canEdit: false, isHidden: false },
        { name: 'saeiManagement', isReadOnly: false, canEdit: false, isHidden: false }
      ]
    },
    {
      moduleName: 'formationAndResourceAI',
      isReadOnly: false,
      canEdit: false,
      isHidden: false,
      subModules: [
        { name: 'resourceCenter', isReadOnly: false, canEdit: false, isHidden: false },
        { name: 'onlineCourse', isReadOnly: false, canEdit: false, isHidden: false },
        { name: 'certifyingCourse', isReadOnly: false, canEdit: false, isHidden: false },
        { name: 'certificationPath', isReadOnly: false, canEdit: false, isHidden: false }
      ]
    },
    {
      moduleName: 'labelingAi',
      isReadOnly: false,
      canEdit: false,
      isHidden: false,
      subModules: [
        { name: 'amiLabel', isReadOnly: false, canEdit: false, isHidden: false },
        { name: 'labelingApplication', isReadOnly: false, canEdit: false, isHidden: false },
        { name: 'selfDiagnosisLabel', isReadOnly: false, canEdit: false, isHidden: false },
        { name: 'managementOfLabels', isReadOnly: false, canEdit: false, isHidden: false }
      ]
    },
    {
      moduleName: 'labeling',
      isReadOnly: false,
      canEdit: false,
      isHidden: false,
      subModules: [
        { name: 'labelingApplication', isReadOnly: false, canEdit: false, isHidden: false },
        { name: 'renewalLabel', isReadOnly: false, canEdit: false, isHidden: false }
      ]
    },
    {
      moduleName: 'dashboard',
      isReadOnly: false,
      canEdit: false,
      isHidden: false,
      subModules: []
    },
    {
      moduleName: 'event',
      isReadOnly: false,
      canEdit: false,
      isHidden: false,
      subModules: []
    },
    {
      moduleName: 'post',
      isReadOnly: false,
      canEdit: false,
      isHidden: false,
      subModules: []
    },
    {
      moduleName: 'statistics',
      isReadOnly: false,
      canEdit: false,
      isHidden: false,
      subModules: []
    },
    {
      moduleName: 'settings',
      isReadOnly: false,
      canEdit: false,
      isHidden: false,
      subModules: []
    }
  ]
};

export const roleAndPermissionSlice = createSlice({
  name: 'roleAndPermission',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    allModuleIsReadOnly(state) {
      let data: any = [];

      if (state.allModule.isReadOnly) {
        state.allModule.isReadOnly = false;
        state.allModule.canEdit = false;
        state.allModule.isHidden = false;

        for (let index = 0; index < current(state.permission).length; index++) {
          const element = current(state.permission)[index];
          let subModules = element.subModules;
          let updateSubModules: any = [];

          for (let index = 0; index < subModules.length; index++) {
            const element = subModules[index];
            updateSubModules.push({
              ...element,
              isReadOnly: false,
              canEdit: false,
              isHidden: false
            });
          }
          data.push({
            ...element,
            isReadOnly: false,
            canEdit: false,
            isHidden: false,
            subModules: updateSubModules
          });
        }
      } else {
        state.allModule.isReadOnly = true;
        state.allModule.canEdit = false;
        state.allModule.isHidden = false;

        for (let index = 0; index < current(state.permission).length; index++) {
          const element = current(state.permission)[index];
          let subModules = element.subModules;
          let updateSubModules: any = [];

          for (let index = 0; index < subModules.length; index++) {
            const element = subModules[index];
            updateSubModules.push({
              ...element,
              isReadOnly: true,
              canEdit: false,
              isHidden: false
            });
          }
          data.push({
            ...element,
            isReadOnly: true,
            canEdit: false,
            isHidden: false,
            subModules: updateSubModules
          });
        }
      }

      state.permission = data;
    },

    allModuleCanBeEdit(state) {
      let data: any = [];

      if (state.allModule.canEdit) {
        state.allModule.canEdit = false;
        state.allModule.isHidden = false;
        state.allModule.isReadOnly = false;

        for (let index = 0; index < current(state.permission).length; index++) {
          const element = current(state.permission)[index];
          let subModules = element.subModules;
          let updateSubModules: any = [];

          for (let index = 0; index < subModules.length; index++) {
            const element = subModules[index];
            updateSubModules.push({
              ...element,
              isReadOnly: false,
              canEdit: false,
              isHidden: false
            });
          }
          data.push({
            ...element,
            isReadOnly: false,
            canEdit: false,
            isHidden: false,
            subModules: updateSubModules
          });
        }
      } else {
        state.allModule.isReadOnly = false;
        state.allModule.canEdit = true;
        state.allModule.isHidden = false;

        for (let index = 0; index < current(state.permission).length; index++) {
          const element = current(state.permission)[index];
          let subModules = element.subModules;
          let updateSubModules: any = [];

          for (let index = 0; index < subModules.length; index++) {
            const element = subModules[index];
            updateSubModules.push({
              ...element,
              isReadOnly: false,
              canEdit: true,
              isHidden: false
            });
          }

          data.push({
            ...element,
            isReadOnly: false,
            canEdit: true,
            isHidden: false,
            subModules: updateSubModules
          });
        }
      }
      state.permission = data;
    },

    allModuleIsHidden(state) {
      let data: any = [];

      if (state.allModule.isHidden) {
        state.allModule.isHidden = false;
        state.allModule.canEdit = false;
        state.allModule.isReadOnly = false;

        for (let index = 0; index < current(state.permission).length; index++) {
          const element = current(state.permission)[index];
          let subModules = element.subModules;
          let updateSubModules: any = [];

          for (let index = 0; index < subModules.length; index++) {
            const element = subModules[index];
            updateSubModules.push({
              ...element,
              isReadOnly: false,
              canEdit: false,
              isHidden: false
            });
          }
          data.push({
            ...element,
            isReadOnly: false,
            canEdit: false,
            isHidden: false,
            subModules: updateSubModules
          });
        }
      } else {
        state.allModule.isReadOnly = false;
        state.allModule.canEdit = false;
        state.allModule.isHidden = true;

        for (let index = 0; index < current(state.permission).length; index++) {
          const element = current(state.permission)[index];
          let subModules = element.subModules;
          let updateSubModules: any = [];

          for (let index = 0; index < subModules.length; index++) {
            const element = subModules[index];
            updateSubModules.push({
              ...element,
              isReadOnly: false,
              canEdit: false,
              isHidden: true
            });
          }

          data.push({
            ...element,
            isReadOnly: false,
            canEdit: false,
            isHidden: true,
            subModules: updateSubModules
          });
        }
      }
      state.permission = data;
    },

    allSubModuleIsReadOnly(state, action) {
      const moduleName = action.payload;
      state.allModule.isReadOnly = false;
      state.allModule.canEdit = false;
      state.allModule.isHidden = false;

      let updatePermission = map(current(state.permission), (module) => {
        if (module.moduleName === moduleName && module.isReadOnly === true) {
          let subModules = module.subModules;
          let updateSubModules: any = [];

          for (let index = 0; index < subModules.length; index++) {
            const element = subModules[index];
            updateSubModules.push({
              ...element,
              isReadOnly: false,
              canEdit: false,
              isHidden: false
            });
          }

          return {
            ...module,
            isReadOnly: false,
            canEdit: false,
            isHidden: false,
            subModules: updateSubModules
          };
        } else if (module.moduleName === moduleName && module.isReadOnly === false) {
          let subModules = module.subModules;

          let updateSubModules: any = [];

          for (let index = 0; index < subModules.length; index++) {
            const element = subModules[index];
            updateSubModules.push({
              ...element,
              isReadOnly: true,
              canEdit: false,
              isHidden: false
            });
          }
          return {
            ...module,
            isReadOnly: true,
            canEdit: false,
            isHidden: false,
            subModules: updateSubModules
          };
        }

        return module;
      });

      state.permission = updatePermission;
    },

    allSubModuleCanBeEdit(state, action) {
      const moduleName = action.payload;
      state.allModule.isReadOnly = false;
      state.allModule.canEdit = false;
      state.allModule.isHidden = false;
      let updatePermission = map(current(state.permission), (module) => {
        if (module.moduleName === moduleName && module.canEdit === true) {
          let subModules = module.subModules;
          let updateSubModules: any = [];

          for (let index = 0; index < subModules.length; index++) {
            const element = subModules[index];
            updateSubModules.push({
              ...element,
              isReadOnly: false,
              canEdit: false,
              isHidden: false
            });
          }
          return {
            ...module,
            canEdit: false,
            isReadOnly: false,
            isHidden: false,
            subModules: updateSubModules
          };
        } else if (module.moduleName === moduleName && module.canEdit === false) {
          let subModules = module.subModules;
          let updateSubModules: any = [];

          for (let index = 0; index < subModules.length; index++) {
            const element = subModules[index];
            updateSubModules.push({
              ...element,
              isReadOnly: false,
              canEdit: true,
              isHidden: false
            });
          }
          return {
            ...module,
            canEdit: true,
            isReadOnly: false,
            isHidden: false,
            subModules: updateSubModules
          };
        }

        return module;
      });

      state.permission = updatePermission;
    },

    allSubModuleIsHidden(state, action) {
      const moduleName = action.payload;
      state.allModule.isReadOnly = false;
      state.allModule.canEdit = false;
      state.allModule.isHidden = false;
      let updatePermission = map(current(state.permission), (module) => {
        if (module.moduleName === moduleName && module.isHidden === true) {
          let subModules = module.subModules;
          let updateSubModules: any = [];

          for (let index = 0; index < subModules.length; index++) {
            const element = subModules[index];
            updateSubModules.push({
              ...element,
              isReadOnly: false,
              canEdit: false,
              isHidden: false
            });
          }
          return {
            ...module,
            isHidden: false,
            canEdit: false,
            isReadOnly: false,
            subModules: updateSubModules
          };
        } else if (module.moduleName === moduleName && module.isHidden === false) {
          let subModules = module.subModules;
          let updateSubModules: any = [];

          for (let index = 0; index < subModules.length; index++) {
            const element = subModules[index];
            updateSubModules.push({
              ...element,
              isReadOnly: false,
              canEdit: false,
              isHidden: true
            });
          }
          return {
            ...module,
            isHidden: true,
            canEdit: false,
            isReadOnly: false,
            subModules: updateSubModules
          };
        }

        return module;
      });

      state.permission = updatePermission;
    },

    subModuleIsReadOnly(state, action) {
      const subModuleName = action.payload;
      state.allModule.isReadOnly = false;
      state.allModule.canEdit = false;
      state.allModule.isHidden = false;
      let updatePermission: any = [];

      for (let index = 0; index < current(state.permission).length; index++) {
        let modules = current(state.permission)[index];
        let subModules = modules.subModules;

        let updateSubModules = map(subModules, (item) => {
          if (item.name === subModuleName && item.isReadOnly === true) {
            return {
              ...item,
              isHidden: false,
              canEdit: false,
              isReadOnly: false
            };
          } else if (item.name === subModuleName && item.isReadOnly === false) {
            return {
              ...item,
              isHidden: false,
              canEdit: false,
              isReadOnly: true
            };
          }
          return item;
        });

        updatePermission.push({
          ...modules,
          isReadOnly: false,
          canEdit: false,
          isHidden: false,
          subModules: updateSubModules
        });
      }
      state.permission = updatePermission;
    },

    subModuleCanBeEdit(state, action) {
      const subModuleName = action.payload;
      state.allModule.isReadOnly = false;
      state.allModule.canEdit = false;
      state.allModule.isHidden = false;
      let updatePermission: any = [];

      for (let index = 0; index < current(state.permission).length; index++) {
        let modules = current(state.permission)[index];
        let subModules = modules.subModules;

        let updateSubModules = map(subModules, (item) => {
          if (item.name === subModuleName && item.canEdit === true) {
            return {
              ...item,
              isHidden: false,
              canEdit: false,
              isReadOnly: false
            };
          } else if (item.name === subModuleName && item.canEdit === false) {
            return {
              ...item,
              isHidden: false,
              canEdit: true,
              isReadOnly: false
            };
          }
          return item;
        });

        updatePermission.push({
          ...modules,
          isReadOnly: false,
          canEdit: false,
          isHidden: false,
          subModules: updateSubModules
        });
      }

      state.permission = updatePermission;
    },

    subModuleIsHidden(state, action) {
      const subModuleName = action.payload;
      state.allModule.isReadOnly = false;
      state.allModule.canEdit = false;
      state.allModule.isHidden = false;
      let updatePermission: any = [];

      for (let index = 0; index < current(state.permission).length; index++) {
        let modules = current(state.permission)[index];
        let subModules = modules.subModules;

        let updateSubModules = map(subModules, (item) => {
          if (item.name === subModuleName && item.isHidden === true) {
            return {
              ...item,
              isHidden: false,
              canEdit: false,
              isReadOnly: false
            };
          } else if (item.name === subModuleName && item.isHidden === false) {
            return {
              ...item,
              isHidden: true,
              canEdit: false,
              isReadOnly: false
            };
          }
          return item;
        });

        updatePermission.push({
          ...modules,
          isReadOnly: false,
          canEdit: false,
          isHidden: false,
          subModules: updateSubModules
        });
      }

      state.permission = updatePermission;
    },

    // GET ROLE DETAILS SUCCESS
    getRoleDetailsSuccess(state, action) {
      state.isLoading = false;
      state.roleDetails = action.payload;
    }
  }
});

// Reducer
export default roleAndPermissionSlice.reducer;

// Actions
export const {
  allModuleIsReadOnly,
  allModuleCanBeEdit,
  allModuleIsHidden,
  allSubModuleIsReadOnly,
  allSubModuleCanBeEdit,
  allSubModuleIsHidden,
  subModuleIsReadOnly,
  subModuleCanBeEdit,
  subModuleIsHidden
} = roleAndPermissionSlice.actions;
