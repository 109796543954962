// material
import { Box, List } from '@mui/material';
// components
import { NavItem } from './NavItem';
// type
import { NavItemProps, NavSectionProps } from 'types/navSection';

// ----------------------------------------------------------------------

export default function AdminNavSection({ navConfig, isShown = true, ...other }: NavSectionProps) {
  return (
    <Box {...other}>
      {navConfig.map((list) => {
        const { subheader, items, isHidden } = list;
        return (
          <>
            {isHidden ? null : (
              <List key={subheader} disablePadding>
                {items.map((item: NavItemProps) => (
                  <NavItem key={item.title} item={item} isShown={isShown} />
                ))}
              </List>
            )}
          </>
        );
      })}
    </Box>
  );
}
