// material
import { alpha, styled } from '@mui/material/styles';
import { AppBar, Toolbar, Paper } from '@mui/material';
// constants
import {
  DRAWER_WIDTH,
  APP_BAR_MOBILE_HEIGHT,
  APP_BAR_DESKTOP_HEIGHT,
  SAEI_APP_BAR_DESKTOP_HEIGHT
} from './Dashboard.constants';

// ----------------------------------------------------------------------

export const StyledDashboardSection = styled(Paper)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',

  [theme.breakpoints.up('md')]: {
    width: '100%',
    minHeight: '80vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: theme.spacing(2),
    padding: theme.spacing(3),
    backgroundColor: theme.palette.background.paper,
    border: `1px solid ${
      theme.palette.mode === 'light' ? '#E2E2E2' : theme.palette.background.neutral
    }`
  }
}));

export const StyledDashboardMainSection = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE_HEIGHT + 18,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP_HEIGHT,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1)
  }
}));

export const StyledSaeiDashboardAppBar = styled(AppBar)(({ theme }) => ({
  boxShadow: 'none',
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  backgroundColor: alpha(theme.palette.background.default, 0.72),
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${DRAWER_WIDTH + 1}px)`
  }
}));

export const StyledSaeiDashboardToolbar = styled(Toolbar)(({ theme }) => ({
  minHeight: APP_BAR_MOBILE_HEIGHT,
  [theme.breakpoints.up('lg')]: {
    minHeight: SAEI_APP_BAR_DESKTOP_HEIGHT,
    padding: theme.spacing(0, 5)
  }
}));

export const StyledDashboardSidebarDiv = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    transition: theme.transitions.create('width', {
      duration: theme.transitions.duration.complex
    })
  }
}));

export const StyledAdminDashbordAppBar = styled(AppBar)(({ theme }) => ({
  boxShadow: 'none',
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  backgroundColor: alpha(theme.palette.background.default, 0.72),
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${DRAWER_WIDTH + 1}px)`
  }
}));

export const StyledAdminDashboardToolbar = styled(Toolbar)(({ theme }) => ({
  minHeight: APP_BAR_MOBILE_HEIGHT,
  [theme.breakpoints.up('lg')]: {
    minHeight: SAEI_APP_BAR_DESKTOP_HEIGHT,
    padding: theme.spacing(0, 5)
  }
}));
