import { Icon } from '@iconify/react';
import moonFill from '@iconify/icons-eva/moon-fill';
import sunFill from '@iconify/icons-eva/sun-fill';
// material
import {
  Box,
  Grid,
  Radio,
  Paper,
  RadioGroup,
  CardActionArea,
  FormControlLabel
} from '@mui/material';
// hooks
import useSettings from '../../hooks/useSettings';

// ----------------------------------------------------------------------

export default function SettingMode() {
  const { themeMode, onChangeMode } = useSettings();

  return (
    <RadioGroup name="themeMode" value={themeMode} onChange={onChangeMode}>
      <Grid container spacing={2.5} dir="ltr">
        {themeMode === 'light' ? (
          <Grid item xs={6}>
            <Paper
              sx={{
                width: 25,
                zIndex: 0
              }}
            >
              <CardActionArea sx={{ color: 'primary.main' }}>
                <Box>
                  <Icon icon={moonFill} width={24} height={24} />
                </Box>

                <FormControlLabel
                  label=""
                  value="dark"
                  control={<Radio sx={{ display: 'none' }} />}
                  sx={{
                    top: 0,
                    margin: 0,
                    width: '100%',
                    height: '100%',
                    position: 'absolute'
                  }}
                />
              </CardActionArea>
            </Paper>
          </Grid>
        ) : (
          <Grid item xs={6}>
            <Paper
              sx={{
                width: 25,
                zIndex: 0
              }}
            >
              <CardActionArea sx={{ color: 'primary.main' }}>
                <Box>
                  <Icon icon={sunFill} width={24} height={24} />
                </Box>

                <FormControlLabel
                  label=""
                  value="light"
                  control={<Radio sx={{ display: 'none' }} />}
                  sx={{
                    top: 0,
                    margin: 0,
                    width: '100%',
                    height: '100%',
                    position: 'absolute'
                  }}
                />
              </CardActionArea>
            </Paper>
          </Grid>
        )}
      </Grid>
    </RadioGroup>
  );
}
