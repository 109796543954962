import { isArray } from 'lodash';
import toast from 'react-hot-toast';
// redux
import { dispatch } from '../store';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { membershipRequestSlice } from '../slices/membershipRequest';
// utils
import axios, { uploadFile } from 'utils/axios';
import { FILE_TYPES, isAPngImage, isAPdfDocument, isAWordDocument } from 'utils/fileExtension';
import { GetFileType, SaeiStatusUpdateData } from 'types/membershipRequest';

// ----------------------------------------------------------------------

export const updateSaeiStatus = createAsyncThunk(
  'membershipRequests/updateSaeiStatus',
  async (data: SaeiStatusUpdateData, { rejectWithValue }) => {
    const { entityId, statusCode, comment } = data;
    const setData = { comment };
    const response = await axios.put(
      `entities/saei/update/status/${entityId}/${statusCode}`,
      setData
    );
    return response.data;
  }
);

// ----------------------------------------------------------------------

export const getPrivateFile = createAsyncThunk(
  'membershipRequests/getPrivateFile',
  async ({ key, isToBeDownloaded = false }: GetFileType, { rejectWithValue }) => {
    dispatch(membershipRequestSlice.actions.startLoading());
    try {
      dispatch(membershipRequestSlice.actions.getFileNameSuccess(key));

      const response = await uploadFile.get(`s3-upload-file/stream`, {
        params: { key },
        responseType: 'blob'
      });

      if (isAPdfDocument(key)) {
        const file = new Blob([response.data], { type: FILE_TYPES.PDF });
        const fileURL = URL.createObjectURL(file);
        isToBeDownloaded && window.open(fileURL);
        dispatch(membershipRequestSlice.actions.getFileSuccess(fileURL));
        dispatch(membershipRequestSlice.actions.openFileViewModal());
      } else if (isAWordDocument(key)) {
        const file = new Blob([response.data], {
          type: FILE_TYPES.DOCX
        });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
        dispatch(membershipRequestSlice.actions.getFileSuccess(fileURL));
      } else if (isAPngImage(key)) {
        const file = new Blob([response.data], {
          type: FILE_TYPES.PNG
        });
        const fileURL = URL.createObjectURL(file);
        isToBeDownloaded && window.open(fileURL);
        dispatch(membershipRequestSlice.actions.getFileSuccess(fileURL));
        dispatch(membershipRequestSlice.actions.openFileViewModal());
      } else {
        const file = new Blob([response.data], { type: FILE_TYPES.JPEG });
        const fileURL = URL.createObjectURL(file);
        isToBeDownloaded && window.open(fileURL);
        dispatch(membershipRequestSlice.actions.getFileSuccess(fileURL));
        dispatch(membershipRequestSlice.actions.openFileViewModal());
      }

      dispatch(membershipRequestSlice.actions.hasSelectedFile(null));
    } catch (error: any) {
      toast.error(`${isArray(error?.message) ? error?.message[0] : error?.message}`);
    }
  }
);

// ----------------------------------------------------------------------

export function getSaeiLogo(key: string) {
  return async () => {
    dispatch(membershipRequestSlice.actions.startLoading());
    try {
      const response = await uploadFile.get('s3-upload-file/stream', {
        params: { key },
        responseType: 'blob'
      });

      if (isAPngImage(key)) {
        const file = new Blob([response.data], {
          type: FILE_TYPES.PNG
        });
        const fileURL = URL.createObjectURL(file);
        dispatch(membershipRequestSlice.actions.getSaeiLogoSuccess(fileURL));
      } else {
        const file = new Blob([response.data], { type: FILE_TYPES.JPEG });
        const fileURL = URL.createObjectURL(file);
        dispatch(membershipRequestSlice.actions.getSaeiLogoSuccess(fileURL));
      }
    } catch (error) {
      dispatch(membershipRequestSlice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getMembershipRequestDetails(id: string) {
  return async () => {
    dispatch(membershipRequestSlice.actions.startLoading());
    try {
      const response = await axios.get(`${'entities/saei'}/${id}`);
      dispatch(membershipRequestSlice.actions.getMembershipRequestDetailsSuccess(response.data));
    } catch (error) {
      dispatch(membershipRequestSlice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getMembershipRequests() {
  return async () => {
    dispatch(membershipRequestSlice.actions.startLoading());
    try {
      const response = await axios.get('entities/saei', {
        params: { type: 'SAEI' }
      });
      dispatch(membershipRequestSlice.actions.getMembershipRequestsSuccess(response.data));
    } catch (error) {
      dispatch(membershipRequestSlice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function archiveMembershipRequests(requestIds) {
  return async () => {
    dispatch(membershipRequestSlice.actions.startLoading());
    try {
      const response = await axios.post(
        `${'/entities/saei/archive-multiple-entities'}`,
        requestIds
      );
      dispatch(membershipRequestSlice.actions.getMembershipRequestDetailsSuccess(response.data));
      dispatch(getMembershipRequests());
    } catch (error) {
      dispatch(membershipRequestSlice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------

export function getRejectedOrIncompleteStatusComment(entityId) {
  return async () => {
    dispatch(membershipRequestSlice.actions.startLoading());
    try {
      const response = await axios.get(`/entities/saei/entity-status-comment/${entityId}`);
      dispatch(membershipRequestSlice.actions.getStatusCommentSuccess(response.data));
    } catch (error) {
      dispatch(membershipRequestSlice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getMembershipRequestsStats() {
  return async () => {
    dispatch(membershipRequestSlice.actions.startLoading());
    try {
      const response = await axios.get('entities/status/stats');
      dispatch(membershipRequestSlice.actions.getMembershipRequestsStatsSuccess(response.data));
    } catch (error) {
      dispatch(membershipRequestSlice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getMembershipRequestsFiltered({
  params = { type: 'SAEI', country: '', sector: '', status: '', structureType: '' }
}) {
  return async () => {
    dispatch(membershipRequestSlice.actions.startLoading());
    try {
      const response = await axios.get('entities/saei', {
        params: params
      });
      dispatch(membershipRequestSlice.actions.getMembershipRequestsSuccess(response.data));
    } catch (error) {
      dispatch(membershipRequestSlice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getFile({ key, isToBeDownloaded = false }: GetFileType) {
  return async () => {
    dispatch(membershipRequestSlice.actions.startLoading());
    try {
      dispatch(membershipRequestSlice.actions.getFileNameSuccess(key));

      const response = await uploadFile.get(`s3-upload-file/stream`, {
        params: { key },
        responseType: 'blob'
      });

      if (isAPdfDocument(key)) {
        const file = new Blob([response.data], { type: FILE_TYPES.PDF });
        const fileURL = URL.createObjectURL(file);
        isToBeDownloaded && window.open(fileURL);
        dispatch(membershipRequestSlice.actions.getFileSuccess(fileURL));
        dispatch(membershipRequestSlice.actions.openFileViewModal());
      } else if (isAWordDocument(key)) {
        const file = new Blob([response.data], {
          type: FILE_TYPES.DOCX
        });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
        dispatch(membershipRequestSlice.actions.getFileSuccess(fileURL));
      } else if (isAPngImage(key)) {
        const file = new Blob([response.data], {
          type: FILE_TYPES.PNG
        });
        const fileURL = URL.createObjectURL(file);
        isToBeDownloaded && window.open(fileURL);
        dispatch(membershipRequestSlice.actions.getFileSuccess(fileURL));
        dispatch(membershipRequestSlice.actions.openFileViewModal());
      } else {
        const file = new Blob([response.data], { type: FILE_TYPES.JPEG });
        const fileURL = URL.createObjectURL(file);
        isToBeDownloaded && window.open(fileURL);
        dispatch(membershipRequestSlice.actions.getFileSuccess(fileURL));
        dispatch(membershipRequestSlice.actions.openFileViewModal());
      }

      dispatch(membershipRequestSlice.actions.hasSelectedFile(null));
    } catch (error) {
      dispatch(membershipRequestSlice.actions.hasError(error));
    }
  };
}

export function getFiles({ key, isToBeDownloaded = false }: GetFileType) {
  return async () => {
    dispatch(membershipRequestSlice.actions.startLoading());
    try {
      const response = await uploadFile.get(`s3-upload-file/stream`, {
        params: { key },
        responseType: 'blob'
      });

      if (isAPdfDocument(key)) {
        const file = new Blob([response.data], { type: FILE_TYPES.PDF });
        const fileURL = URL.createObjectURL(file);
        isToBeDownloaded && window.open(fileURL);
        dispatch(membershipRequestSlice.actions.getFileSuccess(fileURL));
        dispatch(membershipRequestSlice.actions.openFileViewModal());
      }

      dispatch(membershipRequestSlice.actions.hasSelectedFile(null));
    } catch (error) {
      dispatch(membershipRequestSlice.actions.hasError(error));
    }
  };
}
