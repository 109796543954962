import { createSlice } from '@reduxjs/toolkit';
// utils
import { saeiStatisticsState } from 'types/saeiStatistics';

// ----------------------------------------------------------------------

const initialState: saeiStatisticsState = {
  isLoading: false,
  error: false,
  programStatistics: null,
  callForApplicationStatistics: null,
  projectStatistics: null,
  projectBeneficiariesStatistics: null,
  callForApplicationResponsesStatistics: null,
  entrepreneurStatistics: null,
  projectsHealthStatistics: null,
  projectsBySectorsStatistics: null,
  entrepreneursAverageAgeStatistics: null,
  projectSupportingHoursStatistics: null,
  projectTurnoverStatistics: null,
  projectsFundRaisedStatistics: null,
  supportManagersStatistics: null,
  eventsStatistics: null,
  followedCoursesStatistics: null,
  didacticToolsStatistics: null,
  downloadSaeiStatistics: null,
  statisticsFilterData: {
    dateFilter: null
  }
};

export const saeiStatistics = createSlice({
  name: 'saeiStatistics',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET PROGRAMS STATISTICS
    getProgramStatisticsSuccess(state, action) {
      state.isLoading = false;
      state.programStatistics = action.payload;
    },

    // GET CALL FOR APPLICATIONS STATISTICS
    getCallForApplicationStatisticsSuccess(state, action) {
      state.isLoading = false;
      state.callForApplicationStatistics = action.payload;
    },

    // GET PROJECT STATISTICS
    getProjectStatisticsSuccess(state, action) {
      state.isLoading = false;
      state.projectStatistics = action.payload;
    },

    // GET PROJECT BENEFICIARIES STATISTICS
    getProjectBeneficiariesStatisticsSuccess(state, action) {
      state.isLoading = false;
      state.projectBeneficiariesStatistics = action.payload;
    },

    // GET CALL FOR APPLICATION RESPONSES STATISTICS
    getCallForApplicationResponsesStatisticsSuccess(state, action) {
      state.isLoading = false;
      state.callForApplicationResponsesStatistics = action.payload;
    },

    // GET ENTREPRENEUR STATISTICS
    getEntrepreneurStatisticsSuccess(state, action) {
      state.isLoading = false;
      state.entrepreneurStatistics = action.payload;
    },

    // GET PROJECTS HEALTH STATISTICS
    getProjectsHealthStatisticsSuccess(state, action) {
      state.isLoading = false;
      state.projectsHealthStatistics = action.payload;
    },

    // GET PROJECTS BY SECTORS STATISTICS
    getProjectsBySectorsStatisticsSuccess(state, action) {
      state.isLoading = false;
      state.projectsBySectorsStatistics = action.payload;
    },

    // GET ENTREPRENEURS AVERAGE AGE STATISTICS
    getEntrepreneursAverageAgeStatisticsSuccess(state, action) {
      state.isLoading = false;
      state.entrepreneursAverageAgeStatistics = action.payload;
    },

    // GET PROJECT SUPPORTING HOURS STATISTICS
    getProjectSupportingHoursStatisticsSuccess(state, action) {
      state.isLoading = false;
      state.projectSupportingHoursStatistics = action.payload;
    },

    // GET PROJECT TURNOVER STATISTICS
    getProjectTurnoverStatisticsSuccess(state, action) {
      state.isLoading = false;
      state.projectTurnoverStatistics = action.payload;
    },

    // GET PROJECTS FUND RAISED STATISTICS
    getProjectFundsRaisedStatisticsSuccess(state, action) {
      state.isLoading = false;
      state.projectsFundRaisedStatistics = action.payload;
    },

    // GET SUPPORT MANAGERS STATISTICS
    getSupportManagersStatisticsSuccess(state, action) {
      state.isLoading = false;
      state.supportManagersStatistics = action.payload;
    },

    // GET EVENTS STATISTICS
    getEventsStatisticsSuccess(state, action) {
      state.isLoading = false;
      state.eventsStatistics = action.payload;
    },

    // GET FOLLOWED COURSES STATISTICS
    getFollowedCoursesStatisticsSuccess(state, action) {
      state.isLoading = false;
      state.followedCoursesStatistics = action.payload;
    },

    // GET DIDACTICS TOOLS STATISTICS
    getDidacticToolsStatisticsSuccess(state, action) {
      state.isLoading = false;
      state.didacticToolsStatistics = action.payload;
    },

    // DOWNLOAD STATISTICS
    getDownloadSaeiStatisticsSuccess(state, action) {
      state.isLoading = false;
      state.downloadSaeiStatistics = action.payload;
    },

    filterStatisticsByDate(state, action) {
      state.statisticsFilterData.dateFilter = action.payload;
    }
  }
});

// Reducer
export default saeiStatistics.reducer;

// Actions
export const { filterStatisticsByDate } = saeiStatistics.actions;
