// @ts-nocheck
import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
// material
import { useTheme } from '@mui/material/styles';
// hooks
import useCollapseDrawer from 'hooks/useCollapseDrawer';
import useAuth from 'hooks/useAuth';
//
import SaeiDashboardNavbar from './SaeiDashboardNavbar';
import SaeiDashboardSidebar from './SaeiDashboardSidebar';
import { MHidden } from 'components/@material-extend';
import AdminDashboardSidebar from './AdminDashboardSidebar';
import AdminDashboardNavbar from './AdminDashboardNavbar';
// style
import { StyledDashboardMainSection } from 'layouts/dashboard/Dashboard.style';
// constants
import { USER_TYPES } from './Dashboard.constants';
import { STATUS } from 'utils/status';
import { Box } from '@mui/material';
// redux
import { useDispatch, useSelector } from 'state/store';
import { getUserInformation } from 'state/thunks/user.thunk';
// selectors
import { selectBgColorPercent } from 'state/selectors/layout.selectors';
import { APP_COLOR_BY_USER_ROLES, USER_ROLES } from 'common/constants';
import { isExternalSupportOfficer } from 'utils/userRole';

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { entity, user, role, profileType } = useAuth();
  const [open, setOpen] = useState(false);
  const { collapseClick } = useCollapseDrawer();
  const bgColorPercent = useSelector(selectBgColorPercent);

  useEffect(() => {
    dispatch(getUserInformation(user?.id));
  }, [dispatch, user?.id]);

  const getLayoutBackgroundColor = () => {
    if (role?.name === USER_ROLES.CONTRACTOR) {
      return `linear-gradient( to bottom, ${APP_COLOR_BY_USER_ROLES.CONTRACTORS_SECONDARY} 0%, ${APP_COLOR_BY_USER_ROLES.CONTRACTORS_SECONDARY} ${bgColorPercent}, ${theme.palette.background.dashboardGrey} ${bgColorPercent}, ${theme.palette.background.dashboardGrey} 100%)`;
    } else if (
      isExternalSupportOfficer({
        userRoleName: role?.name,
        supportOfficer: USER_ROLES.SUPPORT_OFFICER,
        profileType
      })
    ) {
      return `linear-gradient( to bottom, ${APP_COLOR_BY_USER_ROLES.SUPPORT_OFFICER_SECONDARY} 0%, ${APP_COLOR_BY_USER_ROLES.SUPPORT_OFFICER_SECONDARY} ${bgColorPercent}, ${theme.palette.background.dashboardGrey} ${bgColorPercent}, ${theme.palette.background.dashboardGrey} 100%)`;
    } else if (entity?.type === USER_TYPES.ADMIN) {
      return `linear-gradient( to bottom, #FCC3001C 0%, #FCC3001C ${bgColorPercent}, ${theme.palette.background.dashboardGrey} ${bgColorPercent}, ${theme.palette.background.dashboardGrey} 100%)`;
    } else if (entity?.status?.translation_token === STATUS.VAL && entity?.adhesionDate) {
      return `linear-gradient( to bottom, ${theme.palette.background.saeiBlue} 0%, ${theme.palette.background.saeiBlue} ${bgColorPercent}, ${theme.palette.background.dashboardGrey} ${bgColorPercent}, ${theme.palette.background.dashboardGrey} 100%)`;
    }

    return theme.palette.background.requestDashboard;
  };

  return (
    <Box
      display="flex"
      minHeight="100%"
      overflow="hidden"
      sx={{
        background: getLayoutBackgroundColor()
      }}
    >
      <MHidden width="lgUp">
        {entity?.type === USER_TYPES.ADMIN ? (
          <AdminDashboardNavbar onOpenSidebar={() => setOpen(true)} />
        ) : (
          <SaeiDashboardNavbar onOpenSidebar={() => setOpen(true)} />
        )}
      </MHidden>

      {entity?.type === USER_TYPES.ADMIN ? (
        <AdminDashboardSidebar isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />
      ) : (
        <SaeiDashboardSidebar isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />
      )}
      <StyledDashboardMainSection
        sx={{
          transition: theme.transitions.create('margin', {
            duration: theme.transitions.duration.complex
          }),
          ...(collapseClick && {
            ml: '102px'
          })
        }}
      >
        <Outlet />
      </StyledDashboardMainSection>
    </Box>
  );
}
