import { createSlice } from '@reduxjs/toolkit';
import { OnlineTrainingState } from 'types/onlineTraining';
import { STATUS } from 'utils/status';

const initialState: OnlineTrainingState = {
  isLoading: false,
  errors: false,
  courseList: [],
  onlineTrainingDetail: null,
  courseModuleList: [],
  courseModuleDetails: null,
  courseSubModuleDetails: null,
  subModuleList: [],
  submittingFile: {
    intervenorProfile: null
  },
  saeiCourseList: [],
  saeiCourseDetails: null,
  saeiQuizStatus: {
    status: STATUS.DEFAULT,
    questions: []
  }
};

export const onlineTrainingSlice = createSlice({
  name: 'onlineTraining',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.errors = true;
      state.errors = action.payload;
    },

    // GET ONLINE TRAINING SUCCESS
    getOnlineTrainingsSuccess(state, action) {
      state.isLoading = false;
      state.courseList = action.payload;
    },

    // GET ONLINE TRAINING DETAIL SUCCESS
    getOnlineTrainingDetailSuccess(state, action) {
      state.isLoading = false;
      state.onlineTrainingDetail = action.payload;
    },

    // GET SUBMODULE LIST SUCCESS
    getSubModuleCourseListSuccess(state, action) {
      state.isLoading = false;
      state.subModuleList = action.payload;
    },

    // GET MODULE DETAIL SUCCESS
    getCourseModuleDetailsSuccess(state, action) {
      state.isLoading = false;
      state.courseModuleDetails = action.payload;
    },

    // GET SUBMODULE DETAIL SUCCESS
    getCourseSubModuleDetailsSuccess(state, action) {
      state.isLoading = false;
      state.courseSubModuleDetails = action.payload;
    },

    // GET MODULE LIST
    getModuleListSuccess(state, action) {
      state.isLoading = false;
      state.courseModuleList = action.payload;
    },

    //
    getUploadProfileIntervenorSuccess(state, action) {
      state.isLoading = false;
      state.submittingFile.intervenorProfile = action.payload;
    },
    // GET ONLINE COURSES COTE SAEI SUCCESS
    getSaeiCoursesSuccess(state, action) {
      state.isLoading = false;
      state.saeiCourseList = action.payload;
    },
    // GET ONLINE COURSES DETAIL SAEI SUCCESS
    getSaeiCoursesDetailsSuccess(state, action) {
      state.isLoading = false;
      state.saeiCourseDetails = action.payload;
    },

    // GET SAEI STATUS QUIZ
    getSaeiCoursesStatusQuiz(state, action) {
      state.isLoading = false;
      state.saeiQuizStatus = action.payload;
    }
  }
});

// Reducer
export default onlineTrainingSlice.reducer;

// actions
export const { getSaeiCoursesStatusQuiz } = onlineTrainingSlice.actions;
