import { styled } from '@mui/material/styles';

// ----------------------------------------------------------------------

export const StyledLoadingScreenRootDiv = styled('div')(({ theme }) => ({
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.palette.background.default
}));
