// material
import { Box, BoxProps } from '@mui/material';
//hooks
import useSettings from '../hooks/useSettings';

// ----------------------------------------------------------------------

export default function Logo({ sx }: BoxProps) {
  const { themeMode } = useSettings();

  return (
    <Box>
      {themeMode === 'light' ? (
        <img alt="screen" src={'/static/brand/logo_full.png'} height="30px" width="140px" />
      ) : (
        <img alt="screen" src={'/static/brand/logo_full_ligth.png'} height="30px" width="140px" />
      )}
    </Box>
  );
}
