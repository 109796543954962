import { createSlice } from '@reduxjs/toolkit';
import { CertificationPathSlice } from 'types/certificationPath';

const initialState: CertificationPathSlice = {
  isLoading: false,
  errors: false,
  certificationPath: [],
  detailsCertificationPath: null,
  learnersCertificationPath: [],
  detailSectionsCertificationPath: null,
  detailLearnerOfCertificationPath: null,
  detailOfSection: false,
  detailOfLearner: false,
  isViewModalCreateCertificate: false,
  impactMeasurementCertificationPath: [],
  deliverablesSection: [],
  saeiDeliverablesSection: null
};

export const certificationPathSlice = createSlice({
  name: 'certificationPath',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.errors = true;
      state.errors = action.payload;
    },

    // GET CERTIFICATION PATH
    getCertificationPathListSuccess(state, action) {
      state.isLoading = false;
      state.certificationPath = action.payload;
    },

    // DETAIL CERTIFICATION PATH
    getDetailsCertificationPathSuccess(state, action) {
      state.isLoading = false;
      state.detailsCertificationPath = action.payload;
    },

    // SECTION CERTIFICATION PATH
    getLearnersCertificationPathSuccess(state, action) {
      state.isLoading = false;
      state.learnersCertificationPath = action.payload;
    },

    // DETAIL SECTION CERTIFICATION PATH
    getOneSectionsCertificationPathSuccess(state, action) {
      state.isLoading = false;
      state.detailSectionsCertificationPath = action.payload;
    },

    // DETAIL
    getDeliverablesOfTheSectionsCertificationPathSuccess(state, action) {
      state.isLoading = false;
      state.deliverablesSection = action.payload;
    },

    //
    getSaeiDeliverablesOfTheSectionsSuccess(state, action) {
      state.isLoading = false;
      state.saeiDeliverablesSection = action.payload;
    },

    // TOGGLE DETAIL SECTION
    toggleDetailOfSection(state, action) {
      state.detailOfSection = action.payload;
    },

    // TOGGLE DETAIL LEARNER
    toggleDetailOfLearner(state, action) {
      state.detailOfLearner = action.payload;
    },

    // DETAIL LEARNER
    getDetailOfLearnerOfCertificationPathSuccess(state, action) {
      state.isLoading = false;
      state.detailLearnerOfCertificationPath = action.payload;
    },

    //
    getImpactMeasurementCertificationPathSuccess(state, action) {
      state.isLoading = false;
      state.impactMeasurementCertificationPath = action.payload;
    },

    // TOGGLE VIEW CREATE MODAL CERTIFICATE
    toggleViewModalCreateCertificat(state, action) {
      state.isViewModalCreateCertificate = action.payload;
    }
  }
});

// Reducer
export default certificationPathSlice.reducer;

// actions
export const { toggleDetailOfSection, toggleDetailOfLearner, toggleViewModalCreateCertificat } =
  certificationPathSlice.actions;
