// material
import { useTheme } from '@mui/material/styles';
//styles
import { StyledDefaultLabel } from './Label.style';
//types
import { LabelProps } from 'types/label';

// ----------------------------------------------------------------------

export default function Label({ color = 'default', variant = 'ghost', children, sx }: LabelProps) {
  const theme = useTheme();

  return (
    <StyledDefaultLabel ownerState={{ color, variant }} sx={sx} theme={theme}>
      {children}
    </StyledDefaultLabel>
  );
}
