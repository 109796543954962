import { createSlice } from '@reduxjs/toolkit';
// types
import { SelfDiagnosisState } from 'types/selfDiagnosis';

// ----------------------------------------------------------------------

const initialState: SelfDiagnosisState = {
  isLoading: false,
  error: false,
  autoDiagnosticForm: null,
  autoDiagnosticResponses: [],
  autoDiagnosticResponse: null
};

export const selfDiagnosisSlice = createSlice({
  name: 'selfDiagnosis',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET AUTO DIAGNOSTIC FORM
    getAutoDiagnosticFormSuccess(state, action) {
      state.isLoading = false;
      state.autoDiagnosticForm = action.payload;
    },

    // GET AUTO DIAGNOSTIC RESPONSES
    autoDiagnosticResponsesSuccess(state, action) {
      state.isLoading = false;
      state.autoDiagnosticResponses = action.payload;
    },

    // GET AUTO DIAGNOSTIC RESPONSE
    autoDiagnosticResponseSuccess(state, action) {
      state.isLoading = false;
      state.autoDiagnosticResponse = action.payload;
    }
  }
});

// Reducer
export default selfDiagnosisSlice.reducer;
