import { Props } from 'simplebar-react';
// material
import { Box, BoxProps } from '@mui/material';
// styles
import { StyledScrollbarRootDiv, StyledScrollbarSimpleBar } from './Scrollbar.style';

// ----------------------------------------------------------------------

export default function Scrollbar({ children, sx, ...other }: BoxProps & Props) {
  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );

  if (isMobile) {
    return (
      <Box sx={{ overflowX: 'auto', ...sx }} {...other}>
        {children}
      </Box>
    );
  }

  return (
    <StyledScrollbarRootDiv>
      <StyledScrollbarSimpleBar timeout={500} clickOnTrack={false} sx={sx} {...other}>
        {children}
      </StyledScrollbarSimpleBar>
    </StyledScrollbarRootDiv>
  );
}
