import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'state/store';

export const selectUser = (state: RootState) => state.user;

export const selectUserProfile = createSelector(selectUser, (user) => {
  return user?.profile;
});

export const selectUserProfileImage = createSelector(selectUser, (user) => {
  return user?.images;
});
