import { useState } from 'react';
import { Icon } from '@iconify/react';
import { NavLink as RouterLink, matchPath, useLocation } from 'react-router-dom';
import arrowIosForwardFill from '@iconify/icons-eva/arrow-ios-forward-fill';
import arrowIosDownwardFill from '@iconify/icons-eva/arrow-ios-downward-fill';
// material
import { useTheme } from '@mui/material/styles';
import { Box, List, Collapse, ListItemText } from '@mui/material';
// styles
import {
  StyledNavSectionListItemButton,
  StyledNavSectionListItemIcon
} from 'styles/NavSection.style';
import { DefaultRootStyle, ActiveSubStyle, CollapseStyle, activeRootStyle } from './NavItem.style';
// types
import { NavItemProps } from 'types/navSection';
import { USER_ROLES } from 'common/constants';
// hooks
import useAuth from 'hooks/useAuth';

// ----------------------------------------------------------------------
export function NavItem({ item, isShown }: { item: NavItemProps; isShown?: boolean | undefined }) {
  const theme = useTheme();
  const { role, profileType } = useAuth();
  const { pathname } = useLocation();
  const { title, path, icon, info, children } = item;
  const isActiveRoot = path ? !!matchPath({ path, end: false }, pathname) : false;

  const [open, setOpen] = useState(isActiveRoot);

  const handleOpen = () => {
    setOpen(!open);
  };

  if (children) {
    return (
      <>
        <StyledNavSectionListItemButton
          onClick={handleOpen}
          sx={{
            ...(isActiveRoot
              ? activeRootStyle({
                  theme,
                  userRoleName: role?.name,
                  supportOfficer: USER_ROLES.SUPPORT_OFFICER,
                  profileType
                })
              : DefaultRootStyle),
            borderBottomLeftRadius: '0px',
            borderBottomRightRadius: '0px'
          }}
        >
          <StyledNavSectionListItemIcon>{icon && icon}</StyledNavSectionListItemIcon>

          {isShown && (
            <>
              <ListItemText disableTypography primary={title} />
              {info && info}
              <Box
                component={Icon}
                icon={open ? arrowIosDownwardFill : arrowIosForwardFill}
                sx={{ width: 16, height: 16, ml: 1 }}
              />
            </>
          )}
        </StyledNavSectionListItemButton>

        {isShown && (
          <Collapse
            in={open}
            timeout="auto"
            unmountOnExit
            sx={{
              ...CollapseStyle({
                theme,
                userRoleName: role?.name,
                supportOfficer: USER_ROLES.SUPPORT_OFFICER,
                profileType
              })
            }}
          >
            <List component="div" disablePadding>
              {children.map((item) => {
                const { title, path, isHidden } = item;
                const isActiveSub = path ? !!matchPath({ path, end: false }, pathname) : false;

                return (
                  <>
                    {isHidden ? null : (
                      <StyledNavSectionListItemButton
                        key={title}
                        component={RouterLink}
                        to={path}
                        sx={{
                          ...(isActiveSub &&
                            ActiveSubStyle({
                              userRoleName: role?.name,
                              supportOfficer: USER_ROLES.SUPPORT_OFFICER,
                              profileType
                            }))
                        }}
                      >
                        <ListItemText
                          disableTypography
                          primary={title}
                          sx={{ paddingLeft: '30px' }}
                        />
                      </StyledNavSectionListItemButton>
                    )}
                  </>
                );
              })}
            </List>
          </Collapse>
        )}
      </>
    );
  }

  return (
    <StyledNavSectionListItemButton
      component={RouterLink}
      to={path}
      sx={{
        ...(isActiveRoot
          ? activeRootStyle({
              theme,
              userRoleName: role?.name,
              supportOfficer: USER_ROLES.SUPPORT_OFFICER,
              profileType
            })
          : DefaultRootStyle)
      }}
    >
      <StyledNavSectionListItemIcon>{icon && icon}</StyledNavSectionListItemIcon>
      {isShown && (
        <>
          <ListItemText disableTypography primary={title} />
          {info && info}
        </>
      )}
    </StyledNavSectionListItemButton>
  );
}
