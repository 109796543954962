import { Suspense } from 'react';
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';
// routes
import Router from './routes';
// theme
import ThemeConfig from './theme';
import GlobalStyles from './theme/globalStyles';
// hooks
import useAuth from './hooks/useAuth';
// components
import ThemeLocalization from './components/ThemeLocalization';
import LoadingScreen, { ProgressBarStyle } from './components/LoadingScreen';
import ReactHotToastProvider from './components/ReactHotToastProvider';

// ----------------------------------------------------------------------

export default function App() {
  const { isInitialized } = useAuth();

  const propertyId = process.env.REACT_APP_TAWK_PROPERTY_ID as string;
  const widgetId = process.env.REACT_APP_TAWK_WIDGET_ID as string;

  return (
    <ThemeConfig>
      <Suspense
        fallback={
          <LoadingScreen
            sx={{
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: 'fixed'
            }}
          />
        }
      >
        <ThemeLocalization>
          <GlobalStyles />
          <ProgressBarStyle />
          <TawkMessengerReact propertyId={propertyId} widgetId={widgetId} />
          {isInitialized ? <Router /> : <LoadingScreen />}
          <ReactHotToastProvider />
        </ThemeLocalization>
      </Suspense>
    </ThemeConfig>
  );
}
