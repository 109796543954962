import { createSlice } from '@reduxjs/toolkit';
// types
import { Post } from 'types/posts';

// ----------------------------------------------------------------------

const initialState: Post = {
  isLoading: false,
  error: false,
  posts: [],
  post: null
};

export const postSlice = createSlice({
  name: 'post',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET NEWS
    getPostsSuccess(state, action) {
      state.isLoading = false;
      state.posts = action.payload;
    },

    // GET NEWS DETAILS
    getPostDetailsSuccess(state, action) {
      state.isLoading = false;
      state.post = action.payload;
    },

    // RESET POST DETAIL
    resetPostDetail(state) {
      state.post = null;
    }
  }
});

// Reducer
export default postSlice.reducer;

// Action
export const { resetPostDetail } = postSlice.actions;
