// @ts-nocheck
import toast from 'react-hot-toast';
import { useRef, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
// material
import { alpha } from '@mui/material/styles';
import { Button, Box, Divider, Typography } from '@mui/material';
// hooks
import useAuth from 'hooks/useAuth';
import useLocales from 'hooks/useLocales';
// redux
import { useDispatch } from 'state/store';
import { resetUserState } from 'state/slices/user';
import { resetEntityState } from 'state/slices/entity';
// components
import MyAvatar from 'components/MyAvatar';
import MenuPopover from 'components/MenuPopover';
import { MIconButton } from 'components/@material-extend';

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const { translate } = useLocales();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const anchorRef = useRef(null);
  const { user, logout } = useAuth();
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const signOut = useCallback(() => {
    if (window.gapi) {
      const auth2 = window.gapi.auth2.getAuthInstance();
      if (auth2 != null) {
        auth2.then(
          () => {
            auth2.signOut().then(() => {
              auth2.disconnect();
            });
          },
          (err) => {}
        );
      }
    }
  }, []);

  const handleLogout = async () => {
    try {
      signOut();
      await logout?.();
      dispatch(resetEntityState());
      dispatch(resetUserState());
      navigate('/');
      handleClose();
    } catch (error) {
      console.error(error);
      toast.error('Unable to logout');
    }
  };

  return (
    <>
      <MIconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          padding: 0,
          width: 44,
          height: 44,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72)
            }
          })
        }}
      >
        <MyAvatar />
      </MIconButton>

      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{ width: 220 }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle1" noWrap>
            {user?.firstName} {user?.lastName}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {user?.email}
          </Typography>
        </Box>

        <Divider sx={{ my: 1 }} />

        <Box sx={{ p: 2, pt: 1.5 }}>
          <Button fullWidth color="inherit" variant="outlined" onClick={handleLogout}>
            {translate('header.logout')}
          </Button>
        </Box>
      </MenuPopover>
    </>
  );
}
