import { createSlice } from '@reduxjs/toolkit';
import { Program } from 'types/program';

const initialState: Program = {
  isLoading: false,
  errors: false,
  programs: [],
  program: null,
  programImage: null
};

export const programSlice = createSlice({
  name: 'programs',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.errors = action.payload;
    },

    // GET PROGRAMS
    getProgramsSuccess(state, action) {
      state.isLoading = false;
      state.programs = action.payload;
    },

    //   GET PROGRAMS DETAIL
    getProgramDetailSuccess(state, action) {
      state.isLoading = false;
      state.program = action.payload;
    },
    // UPLOAD VOUCHER
    uploadProgramImageSuccess(state, action) {
      state.isLoading = false;
      state.programImage = action.payload;
    }
  }
});

// reducer
export default programSlice.reducer;
