import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from 'state/store';
// utils
import { meetingState } from 'types/meeting';

// ----------------------------------------------------------------------

const initialState: meetingState = {
  isLoading: false,
  error: false,
  events: [],
  event: null,
  meetings: [],
  userMeetings: [],
  filteredMeetings: [],
  isOpenModal: false,
  selectedEventId: null,
  selectedRange: null,
  meetingDetails: null,
  meetingReport: null,
  eventImage: null
};

export const meetingSlice = createSlice({
  name: 'meetings',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET MEETINGS
    getMeetingsSuccess(state, action) {
      state.isLoading = false;
      state.meetings = action.payload;
    },

    // GET USER MEETINGS
    getUserMeetingsSuccess(state, action) {
      state.isLoading = false;
      state.userMeetings = action.payload;
    },

    // GET EVENTS
    getEventsSuccess(state, action) {
      state.isLoading = false;
      state.events = action.payload;
    },

    // GET EVENT
    getEventSuccess(state, action) {
      state.isLoading = false;
      state.event = action.payload;
    },

    // GET MEETINGS
    getFilteredMeetingsSuccess(state, action) {
      state.isLoading = false;
      state.filteredMeetings = action.payload;
    },

    // GET SAEI INVOICE DETAILS
    getMeetingDetailsSuccess(state, action) {
      state.isLoading = false;
      state.meetingDetails = action.payload;
    },

    // GET MEETING REPORT DETAILS
    getMeetingReportDetailsSuccess(state, action) {
      state.isLoading = false;
      state.meetingReport = action.payload;
    },

    // UPLOAD EVENT IMAGE SUCCESS
    uploadEventImageSuccess(state, action) {
      state.isLoading = false;
      state.eventImage = action.payload;
    },

    // SELECT EVENT
    selectEvent(state, action) {
      const eventId = action.payload;
      state.isOpenModal = true;
      state.selectedEventId = eventId;
    },

    // SELECT RANGE
    selectRange(state, action) {
      const { start, end, schedule } = action.payload;
      state.isOpenModal = true;
      state.selectedRange = { start, end, schedule };
    },

    // OPEN MODAL
    openModal(state) {
      state.isOpenModal = true;
    },

    // CLOSE MODAL
    closeModal(state) {
      state.isOpenModal = false;
      state.selectedEventId = null;
      state.selectedRange = null;
    },

    // RESET EVENT STATE
    resetEventState(state) {
      state.event = null;
    }
  }
});

// Reducer
export default meetingSlice.reducer;

// Actions
export const { openModal, closeModal, selectEvent, resetEventState } = meetingSlice.actions;

// ----------------------------------------------------------------------

export function selectRange(start: Date, end: Date) {
  return async () => {
    dispatch(
      meetingSlice.actions.selectRange({
        start: start.getTime(),
        end: end.getTime()
      })
    );
  };
}
