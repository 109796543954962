import { ReactNode, createContext } from 'react';
// hooks
import useLocalStorage from 'hooks/useLocalStorage';
// theme
import palette from '../theme/palette';
// @type
import { ThemeMode, SettingsContextProps, ThemeColor } from 'types/settings';
//
import { USER_ROLES } from 'common/constants';

// ----------------------------------------------------------------------

const PRIMARY_COLOR = [
  // DEFAULT
  {
    name: 'default',
    ...palette.light.primary
  },
  // PURPLE
  {
    name: 'purple',
    lighter: '#D3D9F8',
    light: '#7886D8',
    main: '#212C7F',
    dark: '#10175B',
    darker: '#060A3C',
    contrastText: '#fff'
  }
];

function setThemeColor(themeColor: ThemeColor) {
  let color;
  const DEFAULT = PRIMARY_COLOR[0];
  const PURPLE = PRIMARY_COLOR[1];

  switch (themeColor) {
    case 'purple':
      color = PURPLE;
      break;
    default:
      color = DEFAULT;
  }
  return color;
}

const userData = JSON.parse(localStorage.getItem('user') || '{}');
const userRoleName = userData[0]?.role.name;

const initialState: SettingsContextProps = {
  themeMode: 'light',
  themeColor: userRoleName === USER_ROLES.SUPPORT_OFFICER ? 'purple' : 'default',
  onChangeMode: () => {},
  setColor: PRIMARY_COLOR[0],
  colorOption: []
};

const SettingsContext = createContext(initialState);

type SettingsProviderProps = {
  children: ReactNode;
};

function SettingsProvider({ children }: SettingsProviderProps) {
  // const { role } = useAuth();

  const [settings, setSettings] = useLocalStorage('settings', {
    themeMode: initialState.themeMode,
    themeColor: initialState.themeColor
  });

  const onChangeMode = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSettings({
      ...settings,
      themeMode: (event.target as HTMLInputElement).value as ThemeMode
    });
  };

  const onChangeColor = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSettings({
      ...settings,
      themeColor: (event.target as HTMLInputElement).value as ThemeColor
    });
  };

  return (
    <SettingsContext.Provider
      value={{
        ...settings,
        // Mode
        onChangeMode,
        // Color
        onChangeColor,
        setColor: setThemeColor(settings.themeColor),
        colorOption: PRIMARY_COLOR.map((color) => ({
          name: color.name,
          value: color.main
        }))
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
}

export { SettingsProvider, SettingsContext };
