import NProgress from 'nprogress';
import { useEffect, useMemo } from 'react';
import { motion } from 'framer-motion';
// material
import { useTheme } from '@mui/material/styles';
import { GlobalStyles } from '@mui/material';
//
// style
import { StyledLoadingScreenRootDiv } from './LoadingScreen.style';

// ----------------------------------------------------------------------
const LoadingDot = {
  display: 'block',
  width: '1.5rem',
  height: '1.5rem',
  backgroundColor: '#FAC800',
  borderRadius: '50%'
};

const LoadingContainer = {
  width: '6rem',
  height: '5rem',
  display: 'flex',
  justifyContent: 'space-around'
};

const ContainerVariants = {
  initial: {
    transition: {
      staggerChildren: 0.2
    }
  },
  animate: {
    transition: {
      staggerChildren: 0.2
    }
  }
};

const DotVariants = {
  initial: {
    y: '0%'
  },
  animate: {
    y: '100%'
  }
};

const DotTransition = {
  duration: 0.5,
  yoyo: Infinity,
  ease: 'easeInOut'
};
// ----------------------------------------------------------------------

export function ProgressBarStyle() {
  const theme = useTheme();

  return (
    <GlobalStyles
      styles={{
        '#nprogress': {
          pointerEvents: 'none',
          '& .bar': {
            top: 0,
            left: 0,
            height: 2,
            width: '100%',
            position: 'fixed',
            zIndex: theme.zIndex.snackbar,
            backgroundColor: theme.palette.primary.main,
            boxShadow: `0 0 2px ${theme.palette.primary.main}`
          },
          '& .peg': {
            right: 0,
            opacity: 1,
            width: 100,
            height: '100%',
            display: 'block',
            position: 'absolute',
            transform: 'rotate(3deg) translate(0px, -4px)',
            boxShadow: `0 0 10px ${theme.palette.primary.main}, 0 0 5px ${theme.palette.primary.main}`
          }
        }
      }}
    />
  );
}

function ProgressBar() {
  NProgress.configure({
    showSpinner: false
  });

  useMemo(() => {
    NProgress.start();
  }, []);

  useEffect(() => {
    NProgress.done();
  }, []);

  return null;
}

export default function LoadingScreen({ ...other }) {
  return (
    <>
      <ProgressBar />

      <StyledLoadingScreenRootDiv {...other}>
        <motion.div
          style={LoadingContainer}
          variants={ContainerVariants}
          initial="initial"
          animate="animate"
        >
          <motion.span style={LoadingDot} variants={DotVariants} transition={DotTransition} />
          <motion.span style={LoadingDot} variants={DotVariants} transition={DotTransition} />
          <motion.span style={LoadingDot} variants={DotVariants} transition={DotTransition} />
        </motion.div>
      </StyledLoadingScreenRootDiv>
    </>
  );
}
