import { createSlice } from '@reduxjs/toolkit';
// types
import { ContractorState } from 'types/contractor';

// ----------------------------------------------------------------------

const initialState: ContractorState = {
  isLoading: false,
  error: false,
  contractors: [],
  contractor: null
};

export const contractorSlice = createSlice({
  name: 'contractors',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET CONTRACTORS SUCCESS
    getContractorsSuccess(state, action) {
      state.isLoading = false;
      state.contractors = action.payload;
    },

    // GET CONTRACTOR SUCCESS
    getContractorSuccess(state, action) {
      state.isLoading = false;
      state.contractor = action.payload;
    }
  }
});

// Reducer
export default contractorSlice.reducer;
