import { ReactNode } from 'react';
// material
import { styled } from '@mui/material/styles';
import { alpha } from '@mui/material/styles';

import { ListItemIcon, ListItemButton, ListItemButtonProps } from '@mui/material';

// ----------------------------------------------------------------------

interface ListItemStyleProps extends ListItemButtonProps {
  component?: ReactNode;
  to?: string;
}

export const StyledNavSectionListItemButton = styled(ListItemButton)<ListItemStyleProps>(
  ({ theme }) => ({
    ...theme.typography.body2,
    height: 48,
    position: 'relative',
    textTransform: 'capitalize',
    marginTop: theme.spacing(0.5),
    marginButtom: theme.spacing(0.5),
    color: theme.palette.text.secondary,
    '&:before': {
      top: 0,
      right: 0,
      width: 3,
      bottom: 0,
      content: "''",
      display: 'none',
      position: 'absolute',
      borderTopLeftRadius: 4,
      borderBottomLeftRadius: 4,
      backgroundColor: theme.palette.primary.main
    }
  })
);

export const StyledNavSectionListItemIcon = styled(ListItemIcon)({
  width: 22,
  height: 22,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
});

export const StyledAdminListItemButton = styled(ListItemButton)<ListItemStyleProps>(
  ({ theme }) => ({
    ...theme.typography.body2,
    height: 48,
    position: 'relative',
    textTransform: 'capitalize',
    marginTop: theme.spacing(0.5),
    marginButtom: theme.spacing(0.5),
    color: '#fff',
    '&:before': {
      top: 0,
      right: 0,
      width: 3,
      bottom: 0,
      content: "''",
      display: 'none',
      position: 'absolute',
      borderTopLeftRadius: 4,
      borderBottomLeftRadius: 4,
      backgroundColor: theme.palette.primary.main
    }
  })
);

export const StyledAdminListItemIcon = styled(ListItemIcon)({
  width: 22,
  height: 22,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
});

export const StyledAdminDefaultRoot = {
  width: '85%',
  marginLeft: '20px',
  borderRadius: '8px',
  display: 'flex',
  justifyContent: 'center'
};

export const StyledAdminActiveSubItem = {
  color: 'primary.main',
  fontWeight: 'fontWeightMedium',
  alignItems: 'center'
};

export const StyledAdminNavItemActiveRoot = {
  color: 'primary.main',
  fontWeight: 'fontWeightMedium',
  width: '85%',
  marginLeft: '20px',
  borderRadius: '8px',
  display: 'flex',
  justifyContent: 'center',
  bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
  '&:before': {
    display: 'block',
    left: 0,
    transform: `rotate(180deg)`,
    bgcolor: 'primary.main',
    my: 1
  }
};
