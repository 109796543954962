// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  register: path(ROOTS_AUTH, '/register'),
  AccountType: path(ROOTS_AUTH, '/account-type'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  registrationConfirm: path(ROOTS_AUTH, '/register/confirmation')
};

export const PATH_PAGE = {
  page404: '/404',
  eventRegistrationConfirmation: '/event-registration/confirmation',
  eventSatisfactionConfirmation: '/event-satisfaction/confirmation',
  callForApplicationFormConfirmation: '/call-for-applications/confirmation',
  callForApplicationForm: '/registration-for-a-call-for-applications',
  amiLabelApplicationForm: '/ami-label-application-form',
  amiLabelApplicationSuccess: '/ami-label-application-success',
  amiLabelApplicationError: '/ami-label-application-error',
  amiTrainingApplicationForm: '/ami-training-application-form',
  amiTrainingApplicationRegistration: '/ami-training-application-success',
  autoDiagnosticLabelApplicationForm: '/auto-diagnostic-label-application',
  registrationConfirmAutoDiagnostic: '/auto-diagnostic-label-application/confirmation'
};

export const DASHBOARD_PATH = {
  root: ROOTS_DASHBOARD,
  general: {
    dashboard: path(ROOTS_DASHBOARD, '/index'),
    registrationCompleted: path(ROOTS_DASHBOARD, '/registration-completed'),
    userProfile: path(ROOTS_DASHBOARD, '/user-profile'),
    editUserProfile: path(ROOTS_DASHBOARD, '/user-profile/edit'),
    renewalsLabelForm: path(ROOTS_DASHBOARD, '/renewals-label-form')
  },
  saei: {
    root: path(ROOTS_DASHBOARD, '/saei'),
    requestsList: path(ROOTS_DASHBOARD, '/saei/requests/list'),
    membershipRequestDetails: path(ROOTS_DASHBOARD, '/saei/:id/request-details'),
    saiesMembers: path(ROOTS_DASHBOARD, '/saei/association-members'),
    registerSaei: path(ROOTS_DASHBOARD, '/saei/association-members/register-saei'),
    manageMeetings: path(ROOTS_DASHBOARD, '/saei/association-members/manage-meetings'),
    scheduleMeeting: path(ROOTS_DASHBOARD, '/saei/association-members/schedule-meeting'),
    meetingsReports: path(ROOTS_DASHBOARD, '/saei/association-members/meetings-reports'),
    saveReport: path(ROOTS_DASHBOARD, '/saei/association-members/save-report')
  },
  administration: {
    root: path(ROOTS_DASHBOARD, '/administration'),
    profile: path(ROOTS_DASHBOARD, '/administration/profile'),
    makeSaeiInvoicePayment: (invoiceId) =>
      path(
        ROOTS_DASHBOARD,
        `/administration/profile/invoice-and-payment/${invoiceId}/make-payment`
      ),
    detailSaeiInvoicePayment: (invoiceId, invoiceStatus) =>
      path(
        ROOTS_DASHBOARD,
        `/administration/profile/invoice-and-payment/${invoiceId}/details/${invoiceStatus}`
      ),
    editSaeiProfile: path(ROOTS_DASHBOARD, '/administration/profile/edit'),
    team: path(ROOTS_DASHBOARD, '/administration/team'),
    collaborator: path(ROOTS_DASHBOARD, '/administration/collaborator'),
    newCollaborator: path(ROOTS_DASHBOARD, '/administration/collaborator/new'),
    collaboratorDetails: path(ROOTS_DASHBOARD, '/administration/collaborator/:id/details'),
    roleAndPermission: path(ROOTS_DASHBOARD, '/administration/role-and-permission'),
    createRole: path(ROOTS_DASHBOARD, '/administration/create-role'),
    meetingManagement: path(ROOTS_DASHBOARD, '/administration/meeting-management'),
    associationAi: path(ROOTS_DASHBOARD, '/administration/association-ai')
  },
  finance: {
    root: path(ROOTS_DASHBOARD, '/finance'),
    invoiceAndPaymentAssociation: path(ROOTS_DASHBOARD, '/finance/invoice-and-payment-association'),
    invoiceAndPaymentSaei: path(ROOTS_DASHBOARD, '/finance/invoice-and-payment-saei'),
    invoiceAndPaymentEntrepreneur: path(
      ROOTS_DASHBOARD,
      '/finance/invoice-and-payment-entrepreneur'
    ),
    createInvoiceAndPaymentEntrepreneur: path(
      ROOTS_DASHBOARD,
      '/finance/invoice-and-payment-entrepreneur/create'
    ),
    updateInvoiceAndPaymentEntrepreneur: (invoiceId) =>
      path(ROOTS_DASHBOARD, `/finance/invoice-and-payment-entrepreneur/${invoiceId}/edite`),
    detailInvoicePaymentSaeiEntrepreneur: (invoiceId, invoiceStatus) =>
      path(
        ROOTS_DASHBOARD,
        `/finance/invoice-and-payment-entrepreneur/${invoiceId}/details/${invoiceStatus}`
      ),
    registerInvoicePaymentSaeiEntrepreneur: (invoiceId) =>
      path(
        ROOTS_DASHBOARD,
        `/finance/invoice-and-payment-entrepreneur/${invoiceId}/register-payment`
      ),
    invoiceAndPaymentsTraining: path(ROOTS_DASHBOARD, '/finance/invoice-and-payment-training'),
    invoiceAndPaymentsTrainingPaymentRequest: path(
      ROOTS_DASHBOARD,
      '/finance/invoice-and-payment-training/payment-request'
    ),
    registerInvoicePaymentTraining: (invoiceId) =>
      path(ROOTS_DASHBOARD, `/finance/invoice-and-payment-training/${invoiceId}/register-payment`),
    detailInvoicePaymentTraining: (invoiceId, invoiceStatus) =>
      path(
        ROOTS_DASHBOARD,
        `/finance/invoice-and-payment-training/${invoiceId}/details/${invoiceStatus}`
      )
  },
  post: {
    postList: path(ROOTS_DASHBOARD, '/post/list'),
    CreateSaeiPost: path(ROOTS_DASHBOARD, '/post/list/create-post')
  },
  event: {
    root: path(ROOTS_DASHBOARD, '/event'),
    createEvent: path(ROOTS_DASHBOARD, '/event/new')
  },
  statistic: {
    root: path(ROOTS_DASHBOARD, '/statistic')
  },
  accompanyingPrograms: {
    root: path(ROOTS_DASHBOARD, '/program'),
    programList: path(ROOTS_DASHBOARD, '/program/list'),
    createProgram: path(ROOTS_DASHBOARD, '/program/create-program'),
    callForApplications: path(ROOTS_DASHBOARD, '/program/call-for-applications'),
    ourContractors: path(ROOTS_DASHBOARD, '/program/our-contractors'),
    createContractor: path(ROOTS_DASHBOARD, '/program/our-contractors/new'),
    teachingToolsList: path(ROOTS_DASHBOARD, '/program/teaching-tools-and-resources'),
    projects: path(ROOTS_DASHBOARD, '/program/projects'),
    invoiceAndPayment: path(ROOTS_DASHBOARD, '/program/invoice-and-payment'),
    detailInvoicePaymentProgramEntrepreneur: (invoiceId, invoiceStatus) =>
      path(ROOTS_DASHBOARD, `/program/invoice-and-payment/${invoiceId}/details/${invoiceStatus}`),
    supportOfficersList: path(ROOTS_DASHBOARD, '/program/support-officers'),
    createSupportOfficers: path(ROOTS_DASHBOARD, '/program/support-officers/new')
  },
  trainingAndResources: {
    root: path(ROOTS_DASHBOARD, '/training'),
    resourceCenterList: path(ROOTS_DASHBOARD, '/training/resource'),
    onlineTrainingList: path(ROOTS_DASHBOARD, '/training/online-training'),
    addOnlineTraining: path(ROOTS_DASHBOARD, '/training/online-training/news'),
    editOnlineTraining: (onlineTrainingId) =>
      path(ROOTS_DASHBOARD, `/training/online-training/${onlineTrainingId}/edit`),
    detailsOnlineTraining: (onlineTrainingId) =>
      path(ROOTS_DASHBOARD, `/training/online-training/${onlineTrainingId}/details`),
    amiTraining: path(ROOTS_DASHBOARD, '/training/ami-training'),
    createAmiTraining: path(ROOTS_DASHBOARD, '/training/ami-training/new'),
    editAmiTraining: (amiTrainingId) =>
      path(ROOTS_DASHBOARD, `/training/ami-training/${amiTrainingId}/edit`),
    detailAmiTraining: (amiTrainingId) =>
      path(ROOTS_DASHBOARD, `/training/ami-training/${amiTrainingId}/details`),
    detailAmiTrainingRegistrant: (amiTrainingId, registrantId, status) =>
      path(
        ROOTS_DASHBOARD,
        `/training/ami-training/${amiTrainingId}/details/registrant/${registrantId}/${status}`
      ),
    addOnlineTrainingModule: (id) =>
      path(ROOTS_DASHBOARD, `/training/online-training/${id}/module`),
    onlineTrainingModule: path(ROOTS_DASHBOARD, '/training/online-training/module'),
    onlineTrainingSubModule: (id, moduleId) =>
      path(ROOTS_DASHBOARD, `/training/online-training/${id}/module/${moduleId}/sub-module`),
    editOnlineTrainingSubModule: (id, moduleId, subModuleId, stepId = 0) =>
      path(
        ROOTS_DASHBOARD,
        `/training/online-training/${id}/module/${moduleId}/sub-module/${subModuleId}/edit/${stepId}`
      ),
    editOnlineTrainingModule: (id, moduleId) =>
      path(ROOTS_DASHBOARD, `/training/online-training/${id}/module/${moduleId}/edit`),
    onlineTrainingListSaei: path(ROOTS_DASHBOARD, '/training/online-course'),
    detailsOnlineTrainingSaei: (id) =>
      path(ROOTS_DASHBOARD, `/training/online-course/${id}/details`),
    onlineTrainingSubModuleDetails: (id, moduleId, subModuleId) =>
      path(
        ROOTS_DASHBOARD,
        `/training/online-course/${id}/details/${moduleId}/sub-module/${subModuleId}`
      ),
    onlineTrainingSubModuleQuizz: (id, moduleId, subModuleId) =>
      path(
        ROOTS_DASHBOARD,
        `/training/online-course/${id}/details/${moduleId}/sub-module/${subModuleId}/quizz`
      ),
    certificationPathAi: path(ROOTS_DASHBOARD, '/training/certification-path'),
    addCertificationPathAi: path(ROOTS_DASHBOARD, '/training/certification-path/create'),
    detailsCertificationPath: (id) =>
      path(ROOTS_DASHBOARD, `/training/certification-path/${id}/detail`),
    editCertificationPath: (id) => path(ROOTS_DASHBOARD, `/training/certification-path/${id}/edit`),
    createSectionsByCertificationPath: (idCertification) =>
      path(ROOTS_DASHBOARD, `/training/certification-path/${idCertification}/create-section`),
    updateSectionsByCertificationPath: (idCertification, sectionId) =>
      path(
        ROOTS_DASHBOARD,
        `/training/certification-path/${idCertification}/section/${sectionId}/edit`
      ),
    certificationPathSaei: path(ROOTS_DASHBOARD, '/training/certification-path-saei'),
    detailsCertificationPathSaei: (id) =>
      path(ROOTS_DASHBOARD, `/training/certification-path-saei/${id}/detail`)
  },
  labelling: {
    root: path(ROOTS_DASHBOARD, '/labelling'),
    amiLabel: path(ROOTS_DASHBOARD, '/labelling/ami-label'),
    createAmiLabel: path(ROOTS_DASHBOARD, '/labelling/ami-label/new'),
    labelingRequests: path(ROOTS_DASHBOARD, '/labelling/requests'),
    labelingFormSuccessSubmission: path(ROOTS_DASHBOARD, '/labelling/requests/confirmation'),
    selfDiagnosisLabel: path(ROOTS_DASHBOARD, '/labelling/self-diagnosis-label'),
    EditSelfDiagnosisLabel: path(ROOTS_DASHBOARD, '/labelling/edit-self-diagnosis-label'),
    managementOfLabels: path(ROOTS_DASHBOARD, '/labelling/management-of-labels'),
    managementOfLabelRenewals: path(ROOTS_DASHBOARD, '/labelling/management-of-labels-renewals')
  },
  labelingSaei: {
    root: path(ROOTS_DASHBOARD, '/labelling-saei'),
    requestForLabeling: path(ROOTS_DASHBOARD, '/labelling-saei/request-for-labeling'),
    renewalLabel: path(ROOTS_DASHBOARD, '/labelling-saei/renewal-label')
  },
  statistics: {
    root: path(ROOTS_DASHBOARD, '/statistics')
  },
  settingsAndPreferences: {
    root: path(ROOTS_DASHBOARD, '/settings-and-preferences')
  }
};
