// material
import { styled } from '@mui/material/styles';
import { InputLabel } from '@mui/material';

// ----------------------------------------------------------------------

export const StyledInputLabel = styled(InputLabel)(({ theme }) => ({
  ...theme.typography.body1,
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(1)
}));
