// redux
import { createSlice } from '@reduxjs/toolkit';
import { updateSaeiStatus } from '../thunks/membershipRequests.thunk';
// types
import { MembershipRequestState } from 'types/membershipRequest';

// ----------------------------------------------------------------------

const initialState: MembershipRequestState = {
  isLoading: false,
  error: { statusCode: 0, message: '' },
  isTableFilterOpened: false,
  isRejected: false,
  isModalOpened: false,
  isIncomplete: false,
  isFileViewModalOpened: false,
  fileUrl: '',
  membershipRequests: [],
  membershipRequestStatistics: [],
  membershipRequestDetails: null,
  updateStatus: null,
  activeStep: 0,
  fileName: '',
  selectedFile: null,
  saeiLogo: null,
  statusComment: null
};

export const membershipRequestSlice = createSlice({
  name: 'membershipRequest',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // HAS SELECTED FILE
    hasSelectedFile(state, action) {
      state.selectedFile = action.payload;
    },

    // HAS REJECTED
    hasRejected(state) {
      state.isRejected = !state.isRejected;
      state.isIncomplete = false;
    },

    // HAS INCOMPLETE
    hasIncomplete(state) {
      state.isIncomplete = !state.isIncomplete;
      state.isRejected = false;
    },

    // OPEN MODAL
    openModal(state) {
      state.isModalOpened = true;
      // state.isIncomplete = false;
      // state.isRejected = false;
    },

    // OPEN MODAL FOR VALIDATION
    openModalForValidation(state) {
      state.isModalOpened = true;
      state.isIncomplete = false;
      state.isRejected = false;
    },

    // OPEN FILE VIEW MODAL
    openFileViewModal(state) {
      state.isFileViewModalOpened = true;
    },

    // CLOSE FILE VIEW MODAL
    closeFileViewModal(state) {
      state.isFileViewModalOpened = false;
    },

    // CLOSE MODAL
    closeModal(state) {
      state.isModalOpened = false;
    },

    // OPEN TABLE FILTER FORM
    openTableFilterForm(state) {
      state.isLoading = false;
      state.isTableFilterOpened = !state.isTableFilterOpened;
    },

    // GET ALL MEMBERSHIP REQUEST
    getMembershipRequestsSuccess(state, action) {
      state.isLoading = false;
      state.membershipRequests = action.payload;
    },

    // GET ALL MEMBERSHIP REQUEST STATS
    getMembershipRequestsStatsSuccess(state, action) {
      state.isLoading = false;
      state.membershipRequestStatistics = action.payload;
    },

    // GET MEMBERSHIP REQUEST DETAILS
    getMembershipRequestDetailsSuccess(state, action) {
      state.isLoading = false;
      state.membershipRequestDetails = action.payload;
    },

    // GET UPLOAD FILE
    getFileSuccess(state, action) {
      state.isLoading = false;
      state.fileUrl = action.payload;
    },

    // GET UPLOAD FILE
    hasChangedTabs(state, action) {
      state.isModalOpened = false;
      state.isIncomplete = false;
      state.isRejected = false;
      state.activeStep = action.payload;
    },

    // GET FILE  EXTENSION
    getFileNameSuccess(state, action) {
      state.fileName = action.payload;
    },

    // GET SAEI LOGO
    getSaeiLogoSuccess(state, action) {
      state.isLoading = false;
      state.saeiLogo = action.payload;
    },

    // GET STATUS COMMENT
    getStatusCommentSuccess(state, action) {
      state.isLoading = false;
      state.statusComment = action.payload;
    }
  },
  extraReducers: (builder) => {
    // UPDATE SAEI STATUS IS PENDING
    builder.addCase(updateSaeiStatus.pending, (state, action) => {
      state.isLoading = true;
    });

    // UPDATE SAEI STATUS
    builder.addCase(updateSaeiStatus.fulfilled, (state, action) => {
      state.isLoading = false;
      state.updateStatus = action.payload;
    });

    // UPDATE SAEI STATUS
    builder.addCase(updateSaeiStatus.rejected, (state, action) => {
      state.isLoading = false;
    });
  }
});

// Reducer
export default membershipRequestSlice.reducer;

// Actions
export const {
  openTableFilterForm,
  hasRejected,
  hasIncomplete,
  openModal,
  openModalForValidation,
  closeModal,
  hasChangedTabs,
  openFileViewModal,
  closeFileViewModal,
  getFileNameSuccess,
  hasSelectedFile,
  getSaeiLogoSuccess,
  getFileSuccess
} = membershipRequestSlice.actions;
