import { createSlice } from '@reduxjs/toolkit';
import { SupportOfficersState } from 'types/supportOfficers';

const initialState: SupportOfficersState = {
  isLoading: false,
  errors: false,
  supportOfficers: [],
  supportOfficer: null,
  internalSupportOfficer: [],
  entityProjects: []
};

export const supportOfficersSlice = createSlice({
  name: 'supportOfficers',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.errors = action.payload;
    },

    // GET SUPPORT OFFICERS
    getSupportOfficersSuccess(state, action) {
      state.isLoading = false;
      state.supportOfficers = action.payload;
    },

    // GET DETAILS OF AN SUPPORT OFFICERS
    getSupportOfficerDetails(state, action) {
      state.isLoading = false;
      state.supportOfficer = action.payload;
    },

    //GET INTERNAL SUPPORT OFFICER
    getInternalSupportOfficerSuccess(state, action) {
      state.isLoading = false;
      state.internalSupportOfficer = action.payload;
    },
    // RESET SUPPORT OFFICER DETAIL
    resetSupportOfficerDetail(state) {
      state.supportOfficer = null;
    },

    // GET ENTITY PROJECT
    getEntityProjectsSuccess(state, action) {
      state.isLoading = false;
      state.entityProjects = action.payload;
    }
  }
});

// reducer
export default supportOfficersSlice.reducer;

// action
export const { resetSupportOfficerDetail } = supportOfficersSlice.actions;
