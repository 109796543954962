import { createSlice } from '@reduxjs/toolkit';
import { ToolsAndResourcesState } from 'types/toolsAndRessource';

const initialState: ToolsAndResourcesState = {
  isLoading: false,
  error: false,
  folders: [],
  files: [],
  folder: null,
  uploadFilePercent: null,
  voucher: null
};

export const toolsSlice = createSlice({
  name: 'folders',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    //   HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET FOLDERS SUCCESS
    getFoldersSuccess(state, action) {
      state.isLoading = false;
      state.folders = action.payload;
    },

    // GET FILESUPLOAD
    getFileUploadSuccess(state, action) {
      state.isLoading = false;
      state.files = action.payload;
    },

    // GET FOLDER SUCCESS
    getFolderSuccess(state, action) {
      state.isLoading = false;
      state.folder = action.payload;
    },

    // UPLOAD FILE PERCENT
    uploadFilePercent(state, action) {
      state.isLoading = false;
      state.uploadFilePercent = action.payload;
    },

    // UPLOAD VOUCHER
    uploadVoucherSuccess(state, action) {
      state.isLoading = false;
      state.voucher = action.payload;
    }
  }
});

// Reducer
export default toolsSlice.reducer;
